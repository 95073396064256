import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import moment from 'moment';
@Injectable({
  providedIn: 'root',
})
export class RestService {
  protected serviceURL = environment.SERVICE_API_URL;
  protected _tokenUrl = environment.TOKEN_API_URL;
  // protected serviceURL = 'https://api.formaturas.app/';
  // protected _tokenUrl = 'https://api.formaturas.app/Portal/token?key=b2204a907fa9f08b5092e860211912d9'

  constructor(protected _http: HttpClient) {
    localStorage.setItem('api_url', this.serviceURL);
  }

  getServiceUrl() {
    return this.serviceURL;
  }

  get(url: string, args = {}) {
    return this._http.get(url, args).toPromise().then((response) => response).catch(this.handleError);
  }

  getApi(url: string, args = {}, outer: boolean = false, v2Api: boolean = false) {
    if (v2Api) {
      this.serviceURL = environment.SERVICE_API_URL_V2;
    }

    let urlFinal = this.serviceURL + url;
    return this.getToken().then(async (accessToken) => {
      if (args['params'] == undefined) {
        args['params'] = {};
      }
      args['headers'] = this.getAuthorizationHeader(true);
      args['params']['token'] = accessToken;
      if (outer) urlFinal = url;
      const req: any = await this._http.get(urlFinal, args).toPromise().then((response) => response).catch(this.handleError);

      if (req.summary && !req.summary.process && req.summary.errors && req.summary.errors.length > 0) {
        const codesToLogout = [1002];
        req.summary.errors.forEach((error) => {
          if (codesToLogout.indexOf(error.code) > -1) {
            localStorage.clear();
            window.location.reload();
          }
        });
      } else {
        return req;
      }
    });
  }

  getApiAuth(hashedEmail) {
    return this.getToken().then((accessToken) => {
      return this._http.get(`${this.serviceURL}/UsersSocialLogin/sociallogin/${hashedEmail}?token=${accessToken}`).toPromise().then((response) => response).catch(this.handleError);
    });
  }

  async postApi(url: string, body: any, args = {}, v2Api: boolean = false) {
    if (v2Api) {
      this.serviceURL = environment.SERVICE_API_URL_V2;
    }

    const accessToken = await this.getToken()
    if (args['params'] == undefined) {
      args['params'] = {};
    }
    args['headers'] = this.getAuthorizationHeader(true);
    args['params']['token'] = accessToken;

    return this._http.post(this.serviceURL + url, body, args).toPromise().then((response) => response).catch(e => this.handleError(e, v2Api));
  }

  patchApi(url: string, body: any, args = {}, v2Api: boolean = false) {
    if (v2Api) {
      this.serviceURL = environment.SERVICE_API_URL_V2;
    }
    return this.getToken().then((accessToken) => {
      if (args['params'] == undefined) {
        args['params'] = {};
      }
      args['headers'] = this.getAuthorizationHeader(true);
      args['params']['token'] = accessToken;

      return this._http.patch(this.serviceURL + url, body, args).toPromise().then((response) => response).catch(this.handleError);
    });
  }

  putApi(url: string, body: any, args = {}, v2Api: boolean = false) {
    if (v2Api) {
      this.serviceURL = environment.SERVICE_API_URL_V2;
    }
    return this.getToken().then((accessToken) => {
      if (args['params'] == undefined) {
        args['params'] = {};
      }
      args['headers'] = this.getAuthorizationHeader(true);
      args['params']['token'] = accessToken;
      return this._http.put(this.serviceURL + url, body, args).toPromise().then((response) => response).catch(this.handleError);
    });
  }

  deleteApi(url: string, args = {}, v2Api: boolean = false) {
    if (v2Api) {
      this.serviceURL = environment.SERVICE_API_URL_V2;
    }
    return this.getToken().then((accessToken) => {
      if (args['params'] == undefined) {
        args['params'] = {};
      }
      args['headers'] = this.getAuthorizationHeader(true);
      args['params']['token'] = accessToken;
      return this._http.delete(this.serviceURL + url, args).toPromise().then((response) => response).catch(this.handleError);
    });
  }

  getToken() {
    let storedToken: any = localStorage.getItem('token');
    if (storedToken) {
      storedToken = JSON.parse(storedToken);

      const stToken = new Date(storedToken.expires);
      const expires = moment(stToken);
      const now = moment();

      if (now.isBefore(expires)) return Promise.resolve(storedToken.token);

      return this.getNewToken();
    } else {
      return this.getNewToken();
    }
  }

  async getNewToken() {
    localStorage.removeItem('token');

    return this._http.get(this._tokenUrl).toPromise().then((response: any) => {
      localStorage.setItem('token', JSON.stringify(response.data));
      return response.data.token;
    })
      .catch(this.handleError);
  }

  getAuthorizationHeader(needAuthorization: boolean = false) {
    if (needAuthorization) {
      let tokenUser = localStorage.getItem('tokenUser');
      if (tokenUser) {
        let headers = new HttpHeaders({ Authorization: 'Bearer ' + tokenUser });
        return headers;
      }
    }
    let headers = new HttpHeaders();
    return headers;
  }

  protected handleError(err: HttpErrorResponse, apiV2: boolean = false) {
    if(apiV2){}

    let errorMessage = '';
    if (err.error instanceof Error) {
      errorMessage = `Ocorreu um erro: ${err.error.message}`;
    } else {
      errorMessage = `Servidor retornou o erro: ${err.status}, mensagem: ${err.message}`;
    }
    return Promise.reject({
      errorMessage: errorMessage,
      errorStatus: err.status,
    });
  }

  protected toUpperCase(obj) {
    let no_change = ['country', 'reference', 'state'];
    for (var i in obj) {
      if (no_change.indexOf(i) == -1) {
        let val = obj[i];
        if (val != undefined || val != 'undefined') {
          if (typeof val == 'string') obj[i] = val.toUpperCase();
        }
      }
    }
    return obj;
  }
}
