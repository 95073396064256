import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RestService } from './rest.service';

@Injectable()
export class CompaniesUsersService extends RestService {

    private _url = 'CompaniesUsers';
    
	constructor(protected _http: HttpClient) {
		super(_http);
    } 
    
    insert(data: any) : any {
        return this.postApi(this._url, data);
    }
    
    deleteCompaniesUsers(user_id: number) : any {
        let data    = {user_id: user_id};
        return this.postApi(this._url + '/clean', data);
    }
    
    insertMany(info: any) : any {
        let data = {} 
        data['isArray'] = true
        data['items'] = info;
        return this.postApi(this._url, JSON.stringify(data));
    }

    getCompaniesUsers(filter_data: Array<any>, contains: string = '', order_string: string = '+CompaniesUsers.id', page: number = 0, fields: string = '', limit: number = 9999999, groupData: any = []): any {
        let qs = '';
        let paging  = '';
        let group: string = '';


        filter_data.map(item => {
            if(item.operator == null || item.operator == ''){
                qs += item.field + '|' + item.value + ';'
            }else{
                qs += item.field + '|' + item.operator + '|' + item.value + ';'
            }
        })

        if(groupData && groupData.length > 0){
            groupData.map(item => {        
                 group += item.field + ';'        
            })
          }
        
        let offset  = (page * limit);
        paging      = offset + ";" + limit;
        return this.getApi(this._url, {params: {query: qs, paging: paging, order: order_string, contains: contains, fields: fields, group: group}});
    }


}