<app-loading [loading]="loading"></app-loading>

<div class="login-container">
  <img [src]="globals.brand" [alt]="globals.title" class="top-right-bg">
  <img [src]="globals.brand" [alt]="globals.title" class="bottom-left-bg">
  <div class="login-box">
    <h2 class="login-title">Bem vindo!</h2>
    <p class="login-subtitle">{{'LOGIN.WELCOME-DESC' | translate}}</p>
      <div class="col-12 justify-content-center">
        <div class="row google-login" (click)="signInWithGoogle()">
          <img class="p-0 m-0 ml-3 ml-md-4 mr-4" src="assets/images/google-icon.png" alt="Google">
          <span class="ml-4 d-flex text-center">{{'GENERAL.USE-GOOGLE-ACCOUNT' | translate}}</span>
        </div>
      </div>
    <div class="separator">
      <hr>
      <span class="separator-text">ou</span>
      <hr>
    </div>
    <div class="login-form">
      <div class="row">
        <div class="col-12">
          <div class="form-group mt-4">
            <label for="">E-mail</label>
            <input type="text" (keyup.enter)="signIn()"
            [(ngModel)]="login.email" id="login" name="login" required autofocus>
          </div>
          <div class="form-group mt-4">
            <label for="">{{ 'LOGIN.PASSWORD' | translate }}</label>
            <input c type="password" [disabled]="loading" (keyup.enter)="signIn()"
            [(ngModel)]="login.password" id="password" name="password" #password required>
          </div>
        </div>
      </div>
        <div>
          <div>
            <input class="login-button" type="submit" [disabled]="loading"
            (click)="signIn()" value="{{ 'LOGIN.LOGIN-BUTTON' | translate }}">
          </div>
        </div>
      <div class="col-12 m-0">
        <a class="forgot-password" routerLink="/forgot">{{ 'LOGIN.FORGOT_PASSWORD' | translate }}</a>
      </div>
      <div class="mr-1">
        <p class="tagsa-font">TAG S.A.</p>
      </div>
      <div class="row terms-privacy">
        <a class="col-6 text-right m-0 ml-md-3 pr-0 pr-md-1" href="https://formo.formaturas.app/termos-de-uso" target="_blank">{{'GENERAL.TERMS' | translate}}</a>
        <a class="col-4 ml-5 ml-md-0 mr-md-4" href="https://formo.formaturas.app/politica-de-privacidade" target="_blank">{{'GENERAL.PRIVACY' | translate}}</a>
      </div>
    </div>
  </div>
</div>