<div class="d-flex justify-content-center">
  <ng-container
    *ngFor="let seller of sellers | slice : 0 : previewNumber; let i = index"
  >
    <avatar
      *ngIf="seller.isInLead"
      (click)="removeSeller(i)"
      class="mt-1"
      [class.c-pointer]="!onlyPreview"
      [class.mr-n3]="listType == 'reduced'"
      name="{{ seller.Users.name }}"
      size="size-{{ avatarSize }}"
      color="{{ seller.Users.avatar_color }}"
    ></avatar>
  </ng-container>
  <img
    class="ml-4 c-pointer"
    (click)="showSellers()"
    (clickOutside)="clickedOutsideHandler()"
    src="/assets/images/adicionar vendedores.svg"
    *ngIf="!onlyPreview"
  />
</div>

<div
  [class.d-none]="!openRemove"
  [class.d-block]="openRemove"
  *ngIf="!onlyPreview"
>
  <div class="card-remove-sellers">
    <div class="card text-center box-shadow-card">
      <div class="card-body rounded">
        <div class="row">
          <div class="col-12" *ngIf="openRemove">
            <p class="text-left">
              {{ "GENERAL.REMOVE" | translate }}
              {{ sellerToRemove.Users.name }} deste projeto?
            </p>
          </div>
          <div class="col-12 d-flex justify-content-end">
            <a role="button" class="btn addCmBtnFn" (click)="openRemove = false"
              >Cancelar</a
            >
            <button
              class="btn btn-sm white ml-2 pt-2 mr-1 filterBtn"
              (click)="execRemoveSeller()"
            >
              Remover
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  [class.d-none]="!addSellers"
  [class.d-block]="addSellers"
  class=""
  *ngIf="!onlyPreview"
>
  <div class="card box-shadow-card position-add-seller">
    <div class="card-body rounded">
      <ng-container *ngFor="let sel of sellers; let i = index">
        <div class="d-flex justify-content-between align-items-baseline">
          <div
            class="d-flex align-items-center seller-item c-pointer"
            (click)="addOrRemoveSeller(i)"
          >
            <img
              *ngIf="sel.isInLead"
              src="/assets/images/icons/btn-ativo.svg"
            />
            <avatar
              class="mx-2"
              [class.seller-item-inactive]="!sel.isInLead"
              name="{{ sel.Users.name }}"
              size="2x"
              color="{{ sel.Users.avatar_color }}"
            ></avatar>
            <p class="mb-0">
              {{ sel.Users.name }}
              <ng-container *ngIf="sel.leader">
                <br />
                <small class="defaultSeller">Vendedor principal</small>
              </ng-container>
            </p>
          </div>
          <input
            type="radio"
            name="leader"
            [checked]="sel.leader"
            *ngIf="sel.isInLead"
            (click)="markAsDefaultSeller(sel.users_id)"
          />
        </div>
      </ng-container>
    </div>
  </div>
</div>
