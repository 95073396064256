import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Globals } from 'src/globals';
import { UserService } from 'src/app/services/user.service';
import { UsersTypesService } from 'src/app/services/users_types.service';
import { User } from 'src/app/model/user.model';
import { WorkgroupsService } from 'src/app/services/workgroup.service';

@Component({
  selector: "search-user",
  templateUrl: "./search-user.component.html",
  styleUrls: ['./search-user.component.scss'],
  providers: [Globals]
})
export class SearchUserComponent implements OnInit {
  @Input() teams: boolean = false;
  @Input() multiple: boolean = false;
  @Output() outUser: any = new EventEmitter();
  @Output() outHide: any = new EventEmitter();
  
  users: any = [];
  teamsList: any = [];
  selected: any = [];

  constructor(
    public globals: Globals,
    private usersTypesService: UsersTypesService,
    private workgroupsService: WorkgroupsService
  ) {}

  ngOnInit() {
    this.load();
    if ( this.teams == true ) {
      this.loadTeams();
    }
  }

  load() {
    let filter  = [
      {field: 'UsersTypes.type', value: 'seller'}
    ]
    this.usersTypesService.getUsersTypes(filter, 'Users', '+Users.name', 0, '', 999999).then(rs => {
      this.users  = rs.items;
      this.resetSelection();
    });
  }

  resetSelection() {
    for ( let i = 0; this.users[i]; i++ ) {
      this.selected[this.users[i].data.users_id]  = false;
    }
  }

  loadTeams(){
    this.workgroupsService.getWorkgroups([], 'WorkgroupsUsers').then(rs => {
      this.teamsList  = rs.items;
    })
  }

  hide(){
    if ( this.multiple == true ) {
      this.outHide.emit();
    } 
  }

  selectUser(user) {
    if ( this.multiple == true ) {
      this.selected[user.users_id]  = !this.selected[user.users_id];
      this.outUser.emit(this.selected);
    } else
      this.outUser.emit(user);
  }

  selectTeam(team) {
    if ( this.multiple == true ) {
      this.resetSelection();
      for ( let i = 0; team.WorkgroupsUsers[i]; i++ ) {
        this.selected[team.WorkgroupsUsers[i].users_id]  = true;
      }
    }
    this.outUser.emit(this.selected);
  }
}