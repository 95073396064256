import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { RestService } from './rest.service';

@Injectable({
  providedIn: 'root'
})
export class AccountPlanService extends RestService {
  private _url = 'AccountsPlans';
  
  constructor(protected _http: HttpClient) {
    super(_http);
  }

  insertAccountPlan(bank: any): any {
    let data = JSON.stringify(bank);
    return this.postApi(this._url, data);
  }

  updateAccountPlan(bank: any): any {
    let data = JSON.stringify(bank);
    return this.putApi(this._url + '/' + bank.id, data, {}, false);
  }

  deleteAccountPlan(id: number): any {
    return this.deleteApi(this._url + '/' + id, {}, false);
  }

  updateField(id: number, field: any, value: any): any {
    let data = JSON.stringify({ id: id, field: field, value: value });
    return this.patchApi(this._url + '/' + id, data, {}, false);
  }

  getAccountsPlans(filter_data: any, contain_string: string = '', order_string: string = '+AccountsPlans.name', page: number = 0, fields: string = '', limit: number = 20): any {
    let qs: string = '';
    let paging: string = '';

    if (filter_data.id && filter_data.id != null) {
      qs += 'AccountsPlans.id|' + filter_data.id + ';';
    }
    if (filter_data.name && filter_data.name != '') {
      qs += 'AccountsPlans.name|like|' + filter_data.name + ';';
    }

    if(Array.isArray(filter_data)){
      filter_data.map(item => {
        if(item.operator == null || item.operator == ''){
          qs += item.field + '|' + item.value + ';'
        }else{
          qs += item.field + '|' + item.operator + '|' + item.value + ';'
        }
      })
    }

    let offset = (page * limit);
    paging = offset + ";" + limit;
    return this.getApi(this._url, { params: { query: qs, paging: paging, order: order_string, contains: contain_string, fields: fields } }, false, false);
  }
  
  getAccountPlan(id){
    return this.getApi(this._url + '/' + id, {}, false, false);
  }

}