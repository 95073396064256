import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RestService } from './rest.service';

@Injectable()
export class CompaniesService extends RestService {

    private _url = 'Companies';
    
	constructor(protected _http: HttpClient) {
		super(_http);
    } 
    
    getCompanies(filter_data: Array<any>, contains: string = '', order_string: string = '+Companies.id', page: number = 0, fields: string = '', limit: number = 200): any {
        let qs = '';
        let paging  = '';

        filter_data.map(item => {
            if(item.operator == null || item.operator == ''){
                qs += item.field + '|' + item.value + ';'
            }else{
                qs += item.field + '|' + item.operator + '|' + item.value + ';'
            }
        })
        
        let offset  = (page * limit);
        paging      = offset + ";" + limit;
        return this.getApi(this._url, {params: {query: qs, paging: paging, order: order_string, contains: contains, fields: fields}});
    }

    updateField(id: number, field: any, value: any): any {
    let data = JSON.stringify({ id: id, field: field, value: value });
    return this.patchApi(this._url + '/' + id, data);
    }

}