import { Component, Input } from '@angular/core';
import { Globals } from 'src/globals';

@Component({
  selector: 'icon-funnel',
  template: `
    <svg width="44" height="50" viewBox="0 0 44 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M30.7004 15.5372C30.4907 15.311 30.1865 15.167 29.8719 15.1567C29.5573 15.1465 29.2532 15.2493 29.0225 15.4447L28.1941 16.1336V1.1928C28.1941 0.534705 27.6488 0 26.9776 0C26.3065 0 25.7612 0.534705 25.7612 1.1928V16.1336L24.9852 15.4447C24.5028 15.0231 23.7373 15.0642 23.2968 15.5372C22.8669 16.0102 22.9088 16.7609 23.3912 17.1927C23.3912 17.1927 25.8031 19.2801 26.4218 19.8148C26.5687 19.9382 26.7679 19.9999 26.9567 19.9999C27.1559 19.9999 27.3552 19.9382 27.502 19.8148C28.1312 19.2904 30.627 17.1825 30.6375 17.1722C31.0989 16.73 31.1198 15.9999 30.7004 15.5372Z" [attr.fill]="color"/>
        <path d="M20.6989 12.6908C20.4889 12.4723 20.1844 12.3333 19.8695 12.3234C19.5545 12.3135 19.25 12.4128 19.0191 12.6014L18.1897 13.2667V4.15177C18.1897 3.51631 17.6437 3 16.9718 3C16.2999 3 15.7539 3.51631 15.7539 4.15177V13.2667L14.9875 12.6014C14.5046 12.1943 13.7381 12.234 13.2972 12.6908C12.8667 13.1475 12.9087 13.8723 13.3917 14.2894C13.3917 14.2894 15.8064 16.305 16.4259 16.8213C16.5728 16.9404 16.7723 17 16.9613 17C17.1608 17 17.3603 16.9404 17.5072 16.8213C18.1372 16.3149 20.6359 14.2794 20.6464 14.2695C21.0978 13.8426 21.1188 13.1376 20.6989 12.6908Z" [attr.fill]="color"/>
        <path d="M34.6162 16.2058C34.6162 16.7707 35.0202 17.2761 35.596 17.3851C39.899 18.2572 41.4949 19.3374 41.5556 19.7239C41.5556 19.8429 41.4242 20.0312 41.3434 20.1104C39.7778 21.4781 32.7576 23.4007 22.0606 23.4007C10.5253 23.4007 4.06061 21.3889 2.70707 20.1303L2.51515 19.942C2.50505 19.9222 2.48485 19.8825 2.47475 19.9023C2.56566 19.5555 3.94949 18.6338 7.39394 17.8212C7.93939 17.6923 8.33333 17.2067 8.33333 16.6517C8.33333 15.8688 7.58586 15.294 6.81818 15.4724C3.24242 16.3148 0 17.6923 0 19.9222C0 20.4672 0.181818 21.042 0.494949 21.4682L15.7071 39.9608C15.7172 39.9906 15.7273 40.0203 15.7374 40.0599V48.7414C15.7374 49.0684 15.8889 49.3756 16.1717 49.663C16.3838 49.8712 16.7071 50 17.0101 50H17.1717L27.2929 48.0873C27.8687 47.9486 28.2525 47.4828 28.2525 46.9377L28.2828 39.9608L43.4343 21.4285C43.8485 20.8339 44 20.3582 44 19.7339C44 17.2662 40.0707 15.8391 36.0909 15.0264C35.3333 14.8679 34.6162 15.4427 34.6162 16.2058ZM26.101 38.6626L26.0303 38.7716C25.9192 38.9896 25.798 39.2473 25.798 39.5446V45.7683L18.2121 47.1954V39.4554C18.2121 39.1779 18.101 38.9995 18.0202 38.8608L5.9899 24.0052C10 25.0854 15.8788 25.7296 22.0606 25.7296C28.2222 25.7296 34.1111 25.0953 38.1212 24.0151L26.101 38.6626Z" fill="#E5E5EF"/>
    </svg>
  `,
  providers: [ Globals ]
})
export class IconFunnelComponent {

  @Input() color: string;

  constructor(public globals: Globals) {
    this.color = this.color || globals.colors.lightBlue;
  }

}
