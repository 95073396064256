<div class="d-flex">
    <div class="card-mobile width-card-order position-relative" ngbDropdown>
        <p class="m-0 ml-2 d-none d-md-inline-block font-filter">
            {{'GENERAL.BY_PAGE' | translate}}</p>
        <div class="count">
            <p class="d-md-none">100</p>
            <i ngbDropdownToggle class="btn btnArrowDown d-none d-md-table bg-dark mt-0"></i>
            <img class="d-md-none" src="assets/images/icons/ico-page.svg" alt="">
        </div>
        <div class="pai pb-0" ngbDropdownMenu>
            <div class="filho-3 border-0">
                <ng-container *ngFor="let p of pagesOptions; let i = index">
                    <a ngbDropdownItem class="c-pointer text-decoration-none custom-ngb" (click)="changeByPage(p)" [class.font-select]="pageDefaultOption == p">{{p}}</a>
                    <hr *ngIf="i != ( pagesOptions.length - 1 )">
                </ng-container>
            </div>
        </div>
    </div>
    <div class="card-mobile width-card-order position-relative ml-3" ngbDropdown>
        <p class="m-0 ml-2 d-none d-md-inline-block font-filter">
            {{'GENERAL.ORDER.NAME' | translate}}</p>
        <div>
            <i ngbDropdownToggle [class.invertArrow]="orderIsOpen" class="btn btnArrowDown d-none d-md-table bg-dark mt-0"></i>
            <i ngbDropdownToggle class="icon-ico-order d-md-none"></i>
        </div>
        <div class="pai pb-0" ngbDropdownMenu>
            <div class="filho-1 border-0">
                <ng-container *ngFor="let s of sort_options; let i = index">
                    <a class="c-pointer text-decoration-none" [class.font-off-select]="orderSelected && orderSelected.field && orderSelected.field != s" (click)="openCard(s)">{{'FIELD-ITEMS.' + s | translate}}</a>
                    <hr *ngIf="i != ( sort_options.length - 1 )">
                </ng-container>
            </div>
            <ng-container *ngFor="let s of sort_options; let i = index">
                <div [class.d-none]="!cardOpen">
                    <div class="filho-2 align-items-center d-md-flex flex-md-column justify-content-md-between">
                        <div class="pt-3">
                            <p class="font-order nowrap">{{'GENERAL.ORDER.CRITERIA' | translate}}</p>
                            <p class="mb-1 font-off mb-3">{{'GENERAL.ORDER.NAME2' | translate}}</p>
                            <div class="">
                                <a ngbDropdownItem (click)="setOrder(itemSelected, 'asc');" [class.font-off]="orderSelected && orderSelected.sort && orderSelected.sort == 'desc'" class="c-pointer custom-ngb">{{'GENERAL.ORDER.ASC' | translate}}</a>
                                <hr class="my-2">
                                <a ngbDropdownItem (click)="setOrder(itemSelected, 'desc');" [class.font-off]="orderSelected && orderSelected.sort && orderSelected.sort == 'asc'" class="c-pointer custom-ngb">{{'GENERAL.ORDER.DESC' | translate}}</a>
                            </div>
                        </div>
                        <div class="d-flex justify-content-center">
                            <button class="btn m-md-0 addCmBtnFn white" (click)="cardOpen = !cardOpen">{{'GENERAL.CLOSE' | translate}}</button>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <!-- FILTER -->
    <div *ngIf="filters" class="card-mobile width-card-filter position-relative ml-3" ngbDropdown>
        <p class="m-0 ml-2 font-filter d-none d-md-inline-block">
            {{'GENERAL.FILTER' | translate}}</p>
        <div class="">
            <i ngbDropdownToggle class="btn btnArrowDown bg-dark mt-0 d-none d-md-block"></i>
            <i ngbDropdownToggle class="fa fa-sliders d-md-none"></i>
        </div>
        <div class="pai-filter" ngbDropdownMenu>
            <div class="filter-filho-1 overflow-auto">
                <div class="style-input">
                    <input type="text" class="form-control width-search" [(ngModel)]="search" (keyup.enter)="setSearch();" />
                    <i class="fa fa-search"></i>
                </div>
                <ng-container *ngFor="let opt of filters">
                    <ng-container *ngIf="opt.type != 'select' || ( opt.type == 'select' && opt.options.length > 0 )">
                        <a class="c-pointer text-decoration-none" (click)="selectFilter(opt)" [class.font-select]="!opt.isSelected">{{ opt.name | translate}}</a>
                        <hr>
                    </ng-container>
                </ng-container>
            </div>
            <div [class.d-none]="!cardFilterOpen" *ngIf="selectedFilter">
                <div class="filter-filho-2 d-flex flex-column justify-between">
                    <div class="row">
                        <div class="col-12" *ngIf="selectedFilter.type == 'date'">
                            <p class="font-order">{{'GENERAL.SELECT-PERIOD' | translate}}</p>
                            <p class="mb-1 font-off">{{'GENERAL.PERIOD' | translate}}</p>
                            <div class="card">
                                <div class="card-body p-2 d-flex justify-between">
                                    <p class="m-0 ml-2 font-off-select" *ngIf="!selectedFilter.period">{{'GENERAL.SELECT' | translate}}</p>
                                    <p class="m-0 ml-2" *ngIf="selectedFilter.period == 'TODAY'">{{'FILTER-ITEMS.TODAY'| translate}}</p>
                                    <p class="m-0 ml-2" *ngIf="selectedFilter.period == 'YESTERDAY'">{{'FILTER-ITEMS.YESTERDAY' | translate}}</p>
                                    <p class="m-0 ml-2" *ngIf="selectedFilter.period == 'LAST-WEEK'">{{'FILTER-ITEMS.LAST-WEEK' | translate}}</p>
                                    <p class="m-0 ml-2" *ngIf="selectedFilter.period == 'THIS-WEEK'">{{'FILTER-ITEMS.THIS-WEEK' | translate}}</p>
                                    <p class="m-0 ml-2" *ngIf="selectedFilter.period == 'LAST-MONTH'">{{'FILTER-ITEMS.LAST-MONTH' | translate}}</p>
                                    <p class="m-0 ml-2" *ngIf="selectedFilter.period == 'THIS-MONTH'">{{'FILTER-ITEMS.THIS-MONTH' | translate}}</p>
                                    <p class="m-0 ml-2" *ngIf="selectedFilter.period == 'LAST-YEAR'">{{'FILTER-ITEMS.LAST-YEAR' | translate}}</p>
                                    <p class="m-0 ml-2" *ngIf="selectedFilter.period == 'THIS-YEAR'">{{'FILTER-ITEMS.THIS-YEAR' | translate}}</p>
                                    <p class="m-0 ml-2" *ngIf="selectedFilter.period == 'CUSTOM'">{{'FILTER-ITEMS.CUSTOM' | translate}}</p>
                                    <i (click)="selectFilterDate = !selectFilterDate" [class.invertArrow]="selectFilterDate" class="btn btnArrowDown bg-dark mt-0"></i>
                                </div>
                                <div [class.d-none]="!selectFilterDate">
                                    <div class="card select-open">
                                        <div class="card-body radius-bottom border-top-0 py-2">
                                            <a class="c-pointer" [class.font-off]="selectedFilter.period != 'TODAY'" (click)="setPeriod(selectedFilter, 'TODAY');">{{'FILTER-ITEMS.TODAY' | translate}}</a>
                                            <hr class="my-2">
                                            <a class="c-pointer" [class.font-off]="selectedFilter.period != 'YESTERDAY'" (click)="setPeriod(selectedFilter, 'YESTERDAY');">{{'FILTER-ITEMS.YESTERDAY' | translate}}</a>
                                            <hr class="my-2">
                                            <a class="c-pointer" [class.font-off]="selectedFilter.period != 'LAST-WEEK'" (click)="setPeriod(selectedFilter, 'LAST-WEEK');">{{'FILTER-ITEMS.LAST-WEEK' | translate}}</a>
                                            <hr class="my-2">
                                            <a class="c-pointer" [class.font-off]="selectedFilter.period != 'THIS-WEEK'" (click)="setPeriod(selectedFilter, 'THIS-WEEK');">{{'FILTER-ITEMS.THIS-WEEK' | translate}}</a>
                                            <hr class="my-2">
                                            <a class="c-pointer" [class.font-off]="selectedFilter.period != 'LAST-MONTH'" (click)="setPeriod(selectedFilter, 'LAST-MONTH');">{{'FILTER-ITEMS.LAST-MONTH' | translate}}</a>
                                            <hr class="my-2">
                                            <a class="c-pointer" [class.font-off]="selectedFilter.period != 'THIS-MONTH'" (click)="setPeriod(selectedFilter, 'THIS-MONTH');">{{'FILTER-ITEMS.THIS-MONTH' | translate}}</a>
                                            <hr class="my-2">
                                            <a class="c-pointer" [class.font-off]="selectedFilter.period != 'LAST-YEAR'" (click)="setPeriod(selectedFilter, 'LAST-YEAR');">{{'FILTER-ITEMS.LAST-YEAR' | translate}}</a>
                                            <hr class="my-2">
                                            <a class="c-pointer" [class.font-off]="selectedFilter.period != 'THIS-YEAR'" (click)="setPeriod(selectedFilter, 'THIS-YEAR');">{{'FILTER-ITEMS.THIS-YEAR' | translate}}</a>
                                            <hr class="my-2">
                                            <a class="c-pointer" [class.font-off]="selectedFilter.period != 'CUSTOM'" (click)="setPeriod(selectedFilter, 'CUSTOM');">{{'FILTER-ITEMS.CUSTOM' | translate}}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <p class="font-order mt-3 mb-2">{{'FILTER-ITEMS.CUSTOM' | translate}}</p>
                                </div>
                                <div class="col-12 col-md-6">
                                    <div class="form-group">
                                        <label for="">{{'FILTER-ITEMS.START-DATE' | translate}}</label>
                                        <input type="text" class="form-control" [disabled]="selectedFilter.period != 'CUSTOM' || !selectedFilter.period" [(ngModel)]="selectedFilter.date_from" [textMask]="{mask: maskDate, placeholderChar: ' '}" name="" placeholder="{{'FILTER-ITEMS.START-DATE' | translate}}" />
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <div class="form-group">
                                        <label for="">{{'FILTER-ITEMS.END-DATE' | translate}}</label>
                                        <input type="text" class="form-control" [disabled]="selectedFilter.period != 'CUSTOM' || !selectedFilter.period" [(ngModel)]="selectedFilter.date_to" [textMask]="{mask: maskDate, placeholderChar: ' '}" name="" placeholder="{{'FILTER-ITEMS.END-DATE' | translate}}" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12" *ngIf="selectedFilter.type == 'select' && selectedFilter.options.length > 0">
                            <p class="font-order">{{'FIELD-ITEMS.' + selectedFilter.id | translate}}</p>
                            <div class="height-check">
                                <ng-container *ngFor="let item of selectedFilter.options">
                                    <input type="checkbox" [(ngModel)]="item.data.selected" class="c-pointer">&nbsp;{{item.data.name}}
                                    <hr class="my-2">
                                </ng-container>
                            </div>
                        </div>
                        <div class="col-12" *ngIf="selectedFilter.type == 'money'">
                            <p class="font-order">{{'GENERAL.MONEY' | translate}}</p>
                            <div class="form-group">
                                <label for="">{{'GENERAL.START-VALUE' | translate}}</label>
                                <input currencyMask type="money" class="form-control" [(ngModel)]="selectedFilter.value_from" />
                            </div>
                            <div class="form-group">
                                <label for="">{{'GENERAL.END-VALUE' | translate}}</label>
                                <input currencyMask type="number" class="form-control" [(ngModel)]="selectedFilter.value_to" [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" />
                            </div>
                        </div>
                        <div class="col-12" *ngIf="selectedFilter.type == 'text'">
                            <p class="font-order">{{'GENERAL.SEARCH' | translate}}</p>
                            <input type="text" [(ngModel)]="selectedFilter.value" class="form-control">
                        </div>
                        <div class="col-12" *ngIf="selectedFilter.type == 'number'">
                            <p class="font-order">{{'FIELD-ITEMS.' + selectedFilter.id | translate}}</p>
                            <input type="text" class="form-control" [(ngModel)]="selectedFilter.value" [textMask]="{mask: maskNumber, placeholderChar: ' '}" name="" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 d-flex justify-content-center">
                            <div class="mr-3">
                                <a  class="btn-filter px-4 overated-bg custom-ngb" ngbDropdownItem (click)="setFilter();">{{'GENERAL.FILTER' | translate}}</a>
                            </div>
                            <div>
                                <a class="btn-filter text-white addCmBtnFn m-0 px-4" (click)="cardFilterOpen = !cardFilterOpen;filterIsOpen = !filterIsOpen;">{{'GENERAL.CLOSE' | translate}}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="size-open-select-filter">
        <div class="select-external">
            <div class="selected-internal" *ngFor="let f of filterSelected">
                <p class="m-0 ml-2" *ngIf="f.type != 'search'">{{'FIELD-ITEMS.' + f.id | translate}}: ({{f.selectedValue}})</p>
                <p class="m-0 ml-2" *ngIf="f.type == 'search'">"{{f.value}}"</p>
                <span class="color-btn-x btn p-0 px-2" (click)="removeFilter(f)">×</span>
            </div>
        </div>
    </div>
</div>