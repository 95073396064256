<div class="crm-card">
	<div class="crm-card-header">
		<h3 class="crm-card-title">Análise de uso do CRM</h3>
		<div ngbDropdown>
		    <button ngbDropdownToggle>
		        <span class="dot"></span>
				<span class="dot"></span>
				<span class="dot"></span>
		    </button>
		    <div ngbDropdownMenu class="dropdown-menu">
		        <button class="mb-2">
		        	<i class="icon pdf-icon"></i>
		        	<span class="formo-p2">Baixar gráfico em .PDF</span>
		        </button>
		        <button>
		        	<i class="icon csv-icon"></i>
		        	<span class="formo-p2">Baixar gráfico em .CSV</span>
		        </button>
		    </div>
		</div>
	</div>
	<div class="crm-card-body">
		<div class="crm-card-graph">
			<p class="title">Total de follow-ups</p>
			<div class="progress">
				<div *ngFor="let item of summary; let i = index;" 
					 class="progress-bar"
					 role="progressbar" 
					 [ngStyle]="{
					 	'width': ((item.value / totalUse) | percent),
					 	'background-color': colorScheme.domain[i]
					 }" 
					 [attr.aria-valuenow]="((item.value / totalUse) * 100) | number:'1.0-0'"  
					 aria-valuemin="0" 
					 aria-valuemax="100">
				</div>
			</div>
		</div>

		<div class="crm-card-graph-legend">
			<div *ngFor="let item of summary; let i = index;" 
				 class="legend"
				 [ngStyle]="{'width': ((item.value / totalUse) | percent)}" >
				<header>
					<p>{{ item.value }}</p>
				</header>
				<main>
					<p>{{ item.name }}</p>
				</main>
			</div>	
		</div>
	</div>
</div>

