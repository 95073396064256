import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RestService } from './rest.service';

@Injectable()
export class CompaniesUsersLimitsService extends RestService {
    private _url = 'CompaniesUsersLimits';
    
	constructor(protected _http: HttpClient) {
		super(_http);
    } 
    
    insert(data: any) : any {
        return this.postApi(this._url, data);
    }

    insertMany(info: any) : any {
        let data = {} 
        data['isArray'] = true
        data['items'] = info;
        return this.postApi(this._url, JSON.stringify(data));
    }

    update(obj: any): any {
        let data = JSON.stringify(obj);
        return this.putApi(this._url + '/' + obj.id, data);
    }

    updateField(id: number, field: any, value: any): any {
        let data = JSON.stringify({ id: id, field: field, value: value });
        return this.patchApi(this._url + '/' + id, data);
    }

    getAll(filter_data: any, contain_string: string = '', order_string: string = '', page: number = 0, fields: string = '', limit: number = 20, groupData: any = []): any {
        let qs: string = '';
        let paging: string = '';
    
        if(Array.isArray(filter_data)){
          filter_data.map(item => {
              if(item.operator == null || item.operator == ''){
              qs += item.field + '|' + item.value + ';'
              }else{
              qs += item.field + '|' + item.operator + '|' + item.value + ';'
              }
          })
        }
    
        let offset = (page * limit);
        paging = offset + ";" + limit;
        return this.getApi(this._url, { params: { query: qs, paging: paging, order: order_string, contains: contain_string, fields: fields } });
    }

    getById(id: number){
        return this.getApi(this._url + '/' + id);
    }

}