import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export enum CustomButtonClasses{
  btnWhite = 'customBtn',
  btnBlue = 'customBtn customBtnBlue',
  btnRed  = 'customBtn customBtnRed',
  btnGreen = 'customBtn customBtnGreen',
  btnConclude = 'customBtnConclude',
  btnModalSave = 'customBtnModalSave',
  btnMessageCount = 'customBtnMessageCount',
  btnPlusTrash = 'customBtnPlusTrash',
  btnPlusTrashRed = 'customBtnPlusTrashRed',
  btnIconRed = 'customBtnIconRed',
  btnIcon = 'customBtnIcon',
  btnBack = 'customBtnBack',
  btnDuplicate = 'customBtnDuplicate',
  btnEdit = 'customBtnEdit',
  btnDocumentsUpload = 'customBtnDocumentsUpload',
  btnPDFDownload = 'customBtnPDFDownload',
  btnNewCourse = 'customBtnNewCourse',
  btnAddEmail = 'customBtnAddEmail',
  btnBackBlue = 'customBtnBackBlue',
  btnAdd = 'customBtnAdd',
  btnNewBill = 'customBtnNewBill',
}

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {

  constructor() { }

  @Input()
  Model: string = '1'

  @Input()
  alignBtn: string = 'center';

  @Output()
  click = new EventEmitter();

  @Input()
  buttonLabel: string
  
  @Input()
  buttonTitle: string

  @Input()
  buttonType: string = 'submit';

  @Input()
  textColor: string = 'white';

  @Input()
  buttonDisabled: boolean = false;

  @Input()
  icon: string;

  @Input()
  backgroundColor: string = 'primary';

  @Input()
  buttonClass: CustomButtonClasses = CustomButtonClasses.btnBlue;

  @Input()
  routerLink: string;

  classBtn: string = 'd-flex justify-content-center'
  classDisabled: string = ''

  ngOnInit(): void {
    if ( this.alignBtn == 'start' )
      this.classBtn  = 'd-flex justify-content-start'

    if ( this.alignBtn == 'end' )
      this.classBtn  = 'd-flex justify-content-end'

    if ( this.buttonDisabled ) {
      this.classDisabled  = 'disabled'
    }
  }

  onClick(){
    this.click.emit();
  }

}
