<div class="side-nav">
	<div>
		<nav>
			<ng-container *ngFor="let item of menu">
				<a [class.active]="activeItem == item.content" (click)="setActiveLink(item.content);" *ngIf="!item.children && item.url" href="{{item.url}}" target="{{item.target}}" class="nav-item mmd:w-full mmd:mx-0 mmd:h-11 mmd:flex mmd:flex-row mmd:justify-start">
					<div class="nav-item-icon">
						<span class="fa" [class]="item.icon"></span>
					</div>
					<span class="nav-item-label text-center">{{item.content | translate}}</span>
				</a>
				<a [class.active]="activeItem == item.content" (click)="setActiveLink(item.content);" *ngIf="!item.children && item.action" routerLink="/{{item.action}}" target="{{item.target}}" class="nav-item mmd:w-full mmd:mx-0 mmd:h-11 mmd:flex mmd:flex-row mmd:justify-start">
					<div class="nav-item-icon">
						<span class="fa" [class]="item.icon"></span>
					</div>
					<span class="nav-item-label text-center">{{item.content | translate}}</span>
				</a>
				<a [class.active]="activeItem == item.content" (click)="setActiveLink(item.content);" *ngIf="item.children && !item.action && item.children[0].action" routerLink="/{{item.children[0].action}}" target="{{item.children[0].target}}" class="nav-item mmd:w-full mmd:mx-0 mmd:h-11 mmd:flex mmd:flex-row mmd:justify-start">
					<div class="nav-item-icon">
						<span class="fa" [class]="item.icon"></span>
					</div>
					<span class="nav-item-label text-center">{{item.content | translate}}</span>
				</a>
				<a [class.active]="activeItem == item.content" (click)="setActiveLink(item.content);" *ngIf="item.children && !item.action && !item.children[0].action && item.children[0].children[0].action" routerLink="/{{item.children[0].children[0].action}}" target="{{item.children[0].children[0].target}}" class="nav-item mmd:w-full mmd:mx-0 mmd:h-11 mmd:flex mmd:flex-row mmd:justify-start">
					<div class="nav-item-icon">
						<span class="fa" [class]="item.icon"></span>
					</div>
					<span class="nav-item-label text-center">{{item.content | translate}}</span>
				</a>
			</ng-container>
		</nav>
	</div>
	<div class="bottom-nav-custom">
    	<!-- <hr class="md:hidden border-darker-gray mx-6"> -->
		<nav>
			<!-- <a [class.active]="activeItem == 'profile'" (click)="setActiveLink('profile');" class="nav-item mmd:flex mmd:flex-row mmd:justify-start mmd:h-11 position-relative">
				<div class="position-absolute notification-counter-html">
					<div class="badge" *ngIf="notificationCounter > 99">{{"99+"}}</div>
					<div class="badge" *ngIf="notificationCounter > 0 && notificationCounter < 100">{{notificationCounter}}</div>
				</div>
				<div class="nav-item-icon mmd:ml-9 mmd:mr-6">
					<avatar name="{{logged.user.name}}" color="{{logged.user.avatar_color}}" image="{{actual_img}}"></avatar>
				</div>
				<span class="nav-item-label mmd:text-lg">{{'SIDEBAR.MY' | translate}}</span>
			</a> -->
			<a class="nav-item mmd:flex mmd:flex-row mmd:justify-start mmd:h-11" (click)="logout()">
				<div class="nav-item-icon -with-badge mmd:ml-9 mmd:mr-6">
					<img src="assets/images/icons/ico-logout.svg" alt="">
				</div>
				<span class="nav-item-label mmd:text-lg">{{'GENERAL.SIGN-OUT' | translate}}</span>
			</a>
    	</nav>
	</div>
</div>
