import { Component, OnInit } from "@angular/core";
import { AuthMainService } from "src/app/services/auth.service";
import { Router } from "@angular/router";
import { MenuService } from 'src/app/services/menu.service';
import { PermissionService } from 'src/app/services/permission.service';
import { PersonateLogService } from 'src/app/services/personate_log.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { NotificationsStatusService } from 'src/app/services/notifications_status.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: "side-nav",
    templateUrl: "./side-nav.component.html",
    styleUrls: ["./side-nav.component.scss"],
})
export class SideNavComponent implements OnInit {
    auth: any;
    loading: boolean = false;
    menu: any;

    items: any[] = [];

    activeItem: String = "Comercial";
    notificationCounter: any;
    logged:                 any = null;
    actual_img:             string = '';
    notifications: {
      total: number,
      total_unread: number,
      items: any[]
    } = {
      total: 0,
      total_unread: 0,
      items: []
    };
    personate: any;
    personate_active: boolean = false;

    constructor(
      private router: Router, 
      private authService: AuthMainService,
      private notificationService: NotificationsStatusService,
      private personateLogService: PersonateLogService,
      private translate: TranslateService
    ) {}

    ngOnInit() {
        this.logged         = this.authService.getUser();
        this.actual_img     = localStorage.getItem('avatar');
        if ( this.actual_img == null ) {
          this.authService.getAvatar(this.logged.user.id).then(result => {
              this.actual_img   = localStorage.getItem('avatar');
          });
        }

        if ( localStorage.getItem('activeItem') )
            this.activeItem = JSON.parse(localStorage.getItem('activeItem'));
        
        if ( localStorage.getItem('menu') )
            this.menu       = JSON.parse(localStorage.getItem('menu'));

        this.loadNotifications();
        this.loadPersonate();
    }

    setActiveLink(value) {
        this.activeItem = value;
        this.logged     = this.authService.getUser();
        localStorage.setItem('activeItem', JSON.stringify(this.activeItem));
        localStorage.setItem('internalMenu', null);
        if ( value == 'profile' ) {
            this.translate.get('GENERAL.HELLO').subscribe(translated => {
                localStorage.setItem('title', JSON.stringify(translated + ' ' + this.logged.user.nickname));
                let localMenu = [
                    {"label": "Dados Pessoais", "path": ["/users/profile"], "notification": 0},
                    // {"label": "Gerenciar Notificações", "path": ["/users/notifications-admin"], "notification": 0},
                    {"label": "Notificações", "path": ["/users/notifications"], "notification": 0},
                    // {"label": "Log Out", "path": ["/logout"], "notification": 0},
                ];
                localStorage.setItem('internalMenuLocal', JSON.stringify(localMenu));
                localStorage.setItem('internalMenuActiveItem', JSON.stringify("Dados Pessoais"));
                this.router.navigate(['/users/profile'])
            });
        } else {
            localStorage.removeItem('internalMenuLocal');
        }
    }

    logout() {
        this.authService.unsetUser();
        this.router.navigate(["/login"]);
    }

    loadNotifications() {
      this.notifications.items = [];
      const filter = {
        users_id: this.logged.user.id,
        read_status: 0
      }
      this.notificationService.getNotificationStatus(filter, 'Notifications', '-NotificationsStatus.id', 0, '', 9999).then((ret:any) => {
          this.notifications.items = ret.items;
          if ( this.notifications.items )
            this.notificationCounter = this.notifications.items.length;
      })
    }
    
      markAsRead(index) {
        this.notifications.items[index].data.read_status = 1;
    
        const to_update = {...this.notifications.items[index].data}
    
        delete(to_update.Notifications)
        delete(to_update.modified)
    
        this.notificationService.updateNotificationStatus(to_update).then(() => {
          this.loadNotifications()
        });
      }
    
      markAllAsRead() {    
        this.notificationService.markAllAsRead({user_id: this.logged.user.id}).then(this.loadNotifications());
      }
    
      goTo(url) {
        this.router.navigate([url])
      }
    
      loadPersonate() {
        this.personate = JSON.parse(localStorage.getItem('mainUser'))
        if(this.personate != null && this.personate.personate == true){
          this.personate_active = true;
        }
      }
    
      exitPersonate() {
        const personateUser = JSON.parse(localStorage.getItem('auth'))
        
        this.personateLogService.cancelPersonate(this.personate.items.authP.user.id, personateUser.user.id).then(log => {
    
          // Remover localStorage item token
          localStorage.removeItem('token')
          // Remover localStorage item avatar
          localStorage.removeItem('avatar')
          // Remover localStorage item auth
          localStorage.removeItem('auth')
          // Remover localStorage item sessioHash
          localStorage.removeItem('sessionHash')
          // Remover localStorage item tokenUser
          localStorage.removeItem('tokenUser')
          // Remover localStorage item tokenUserExpires
          localStorage.removeItem('tokenUserExpires')
    
          // Voltar todos acima atraves da chave mainUser
          localStorage.setItem('token', JSON.stringify(this.personate.items.tokenP))
          localStorage.setItem('avatar', this.personate.items.avatarP)
          localStorage.setItem('auth', JSON.stringify(this.personate.items.authP))
          localStorage.setItem('sessionHash', this.personate.items.sessionHashP)
          localStorage.setItem('tokenUser', this.personate.items.tokenUserP)
          localStorage.setItem('tokenUserExpires', this.personate.items.tokenUserExpiresP)
    
          // Remover localStorage item mainUser
          localStorage.removeItem('mainUser')
    
          location.reload()
        })
      }
}