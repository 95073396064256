<app-loading [loading]="loading"></app-loading>
<div class="container-fluid">
    <div class="row">
        <div class="
            col-10 offset-1
            col-xs-8 offset-md-2
            col-sm-8 offset-md-2
            col-md-6 offset-md-3 
            col-lg-4 offset-md-4
            align-center login-wrapper bg-light">
            <h2>{{'CHANGE-PASSWORD.CARD-TITLE' | translate}}</h2>
            <p class="mt-2">{{'CHANGE-PASSWORD.DESC' | translate}}</p>
            <div class="form-group mt-6">
                <label for="new_password">{{ 'CHANGE-PASSWORD.PASSWORD' | translate }}</label>
                <input type="password" class="form-control" 
                    [disabled]="loading" (keyup.enter)="updatePass()" 
                    [(ngModel)]="new_password" id="new_password" name="new_password" 
                    placeholder="{{ 'CHANGE-PASSWORD.NEW-PASSWORD' | translate }}" required autofocus>
            </div>
            <div class="mt-6">
                <input type="submit" [disabled]="loading" (click)="updatePass()" class="btn btn-primary white pt-2 btn-block" value="{{ 'GENERAL.CHANGE' | translate }}">
            </div>
            <div class="mt-6">
                <p class="mt-4 text-xs text-center text-black-50">TAG S/A, {{year}}</p>
            </div>
            <div class="row text-center mt-4">
                <div class="col-12 col-md-4 offset-md-2 text-xs">
                    <a class="text-darkblue" href="https://formo.formaturas.app/termos-de-uso" target="_blank">Termos e Condições</a>
                </div>
                <div class="col-12 col-md-4 text-xs">
                    <a class="text-darkblue" href="https://formo.formaturas.app/politica-de-privacidade" target="_blank">Privacidade</a>
                </div>
            </div>
        </div>
        <div class="col-2 text-right">
            <img *ngIf="numberBg != '3'" src="/assets/images/logo-login.svg" title="" class="logo">
            <img *ngIf="numberBg == '3'" src="/assets/images/logo-login-white.svg" title="" class="logo">
        </div>
    </div>
</div>