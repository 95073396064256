import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { RestService } from './rest.service';

@Injectable()
export class UsersTypesService extends RestService {

    private _url = 'UsersTypes';
    
	constructor(protected _http: HttpClient) {
		super(_http);
    } 
    
    insert(data: any) : any {
        return this.postApi(this._url, data);
    }
    
    deleteUsersTypes(user_id: number) : any {
        let data    = {user_id: user_id};
        return this.postApi(this._url + '/clean', data);
    }
    
    insertMany(info: any) : any {
        let data = {}
        data['isArray'] = true
        data['items'] = info;
        return this.postApi(this._url, JSON.stringify(data));
    }
    
    getUsersTypes(filter_data: any, contain_string: string = '', order_string: string = '-UsersTypes.id', page: number = 0, fields: string = '', limit: number = 20, groupData: any = []): any {
      let qs: string = '';
      let paging: string = '';
      let group: string = '';
  
      if(Array.isArray(filter_data)){
        filter_data.map(item => {
          if(item.operator == null || item.operator == ''){
            qs += item.field + '|' + item.value + ';'
          }else{
            qs += item.field + '|' + item.operator + '|' + item.value + ';'
          }
        })
      }
  
      if(groupData && groupData.length > 0){
        groupData.map(item => {        
             group += item.field + ';'        
        })
      }
      let offset = (page * limit);
      paging = offset + ";" + limit;
      return this.getApi(this._url, { params: { query: qs, paging: paging, order: order_string, contains: contain_string, fields: fields, group: group } });
    }

}