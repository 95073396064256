import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

import { RestService } from './rest.service';


@Injectable()
export class PaymentsMethodsService extends RestService {
    private _url = 'PaymentsMethods';
    
	constructor(protected _http: HttpClient) { 
		super(_http);
	} 

    insertPaymentsMethod(PaymentsMethod: any) : any {
        let data    = JSON.stringify(PaymentsMethod);
        return this.postApi(this._url, data);
    }
    
    updatePaymentsMethod(PaymentsMethod: any) : any {
        let data    = JSON.stringify(PaymentsMethod);
        return this.putApi(this._url + '/' + PaymentsMethod.id, data);
    }

    deleteItem(id: number): any {
      return this.deleteApi(this._url + '/' + id); 
    }
  
    
    getPaymentsMethods(filter_data: any, contains: string = '', order_string: string = '+PaymentsMethods.name', page: number = 0, fields: string = '', limit: number = 20): any {        
        let qs = '';
        let paging  = '';

        if ( filter_data.id && filter_data.id != null ) {
            qs   += 'PaymentsMethods.id|' + filter_data.id + ';';
        }        
        if ( filter_data.name && filter_data.name != '' ) {
            qs   += 'PaymentsMethods.name|like|' + filter_data.name + ';';
        }        
        if ( filter_data.name && filter_data.name != '' ) {
            qs   += 'PaymentsMethods.alias|like|' + filter_data.name + ';';
        }
        if ( filter_data.status && filter_data.status != '' ) {
            qs   += 'PaymentsMethods.status|' + filter_data.status + ';';
        }
        
        
        let offset  = (page * limit);
        paging      = offset + ";" + limit;
        return this.getApi(this._url, {params: {query: qs, paging: paging, order: order_string, contains: contains, fields: fields}});
    }

    getPaymentsMethod(id, contain_string: string = '', fields: string = ''){
        return this.getApi(this._url + '/' + id, { params: {contains: contain_string, fields: fields } });
      }
      
}
