import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RestService } from './rest.service';

@Injectable()
export class GroupService extends RestService {


    private _url = 'Groups';
    private _urlGP = 'GroupsPermissions';
    private _urlGPClean = 'GroupsPermissions/clean';
    private _urlGU = 'GroupsUsers';
    private _urlGUClean = 'GroupsUsers/clean';
    
	constructor(protected _http: HttpClient) {
		super(_http);
    } 
    
    insertGroup(data: any) : any {
        return this.postApi(this._url, data);
    }
    
    updateGroup(group: any) : any {
        let data    = JSON.stringify(group);
        return this.putApi(this._url + '/' + group.id, data);
    }
    
    getGroups(filter_data: any, contains: string = '', order_string: string = '+Groups.name', page: number = 0, fields: string = '', limit: number = 20): any {
        let qs = '';
        let paging  = '';

        if ( filter_data.id && filter_data.id != null ) {
            qs   += 'Groups.id|' + filter_data.id + ';';
        }
        if ( filter_data.name && filter_data.name != '' ) {
            qs   += 'Groups.name|like|' + filter_data.name + ';';
        }
        if ( filter_data.status && filter_data.status != '' ) {
            qs   += 'Groups.status|' + filter_data.status + ';';
        }
        if ( filter_data.root && filter_data.root != '' ) {
            qs   += 'Groups.root|' + filter_data.root + ';';
        }

        if(Array.isArray(filter_data)){
            filter_data.map(item => {
                if(item.operator == null || item.operator == ''){
                qs += item.field + '|' + item.value + ';'
                }else{
                qs += item.field + '|' + item.operator + '|' + item.value + ';'
                }
            })
        }
          
        let offset  = (page * limit);
        paging      = offset + ";" + limit;
        return this.getApi(this._url, {params: {query: qs, paging: paging, order: order_string, contains: contains, fields: fields}});
    }
    
    getGroupsPermissions(filter_data: any, contains: string = '', order_string: string = '+GroupsPermissions.id', page: number = 0, fields: string = '', limit: number = 20): any {
        let qs = '';
        let paging  = '';

        if ( filter_data.id && filter_data.id != null ) {
            qs   += 'GroupsPermissions.id|' + filter_data.id + ';';
        }
        if ( filter_data.permission_id && filter_data.permission_id != null ) {
            qs   += 'GroupsPermissions.permission_id|' + filter_data.permission_id + ';';
        }
        if ( filter_data.group_id && filter_data.group_id != null ) {
            qs   += 'GroupsPermissions.group_id|' + filter_data.group_id + ';';
        }
        let offset  = (page * limit);
        paging      = offset + ";" + limit;
        return this.getApi(this._urlGP, {params: {query: qs, paging: paging, order: order_string, contains: contains, fields: fields}});
    }
    
    getGroup(id: number): any {
        return this.getApi(this._url + '/' + id);
    }
    
    deleteGP(id: number) : any {
        return this.deleteApi(this._urlGP + '/' + id);
    }
    
    insertGP(data: any) : any {
        return this.postApi(this._urlGP, data);
    }
    
    deleteGroupsPermissions(group_id: number) : any {
        let data    = {group_id: group_id};
        return this.postApi(this._urlGPClean, data);
    }
    
    insertGU(data: any) : any {
        return this.postApi(this._urlGU, data);
    }
    
    deleteUserGroups(user_id: number) : any {
        let data    = {user_id: user_id};
        return this.postApi(this._urlGUClean, data);
    }

    insertMany(info: any) : any {
        let data = {}
        data['isArray'] = true
        data['items'] = info;
        return this.postApi(this._urlGP, JSON.stringify(data));
    }

}