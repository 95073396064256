import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LoginLayoutComponent } from './_layouts/login-layout/login-layout.component';
import { AuthMainService } from './services/auth.service';
import { PermissionService } from './services/permission.service';
import { ImageService } from './services/image.service';
import { GeneralComponent } from './_utils/general/general.component';
import { LOCALE_ID } from '@angular/core';
import ptBr from '@angular/common/locales/pt';
import { registerLocaleData, DatePipe } from '@angular/common';
import { InputMaskModule } from 'racoon-mask-raw';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { UsersSocialLoginService } from './services/users_social_login.service';
import { ToasterService, ToasterModule } from 'angular2-toaster';
import { GroupService } from './services/group.service';
import { GroupsUsersService } from './services/groups_users.service';
import { AuthGuard } from './auth.guard';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MainLayoutComponent } from './_layouts/main-layout/main-layout.component';
import { SharedModule } from './shared.module';
import { StatsService } from './services/stats.service';
import { SideNavComponent } from './_layouts/main-layout/side-nav/side-nav.component';
import { TopNavComponent } from './_layouts/main-layout/top-nav/top-nav.component';
import { ContractService } from './services/contract.service';
import { ContractQuotaService } from './services/contract-quota.service';
import { ConfirmationDialogService } from './_utils/confirmation/confirmation-dialog.service';
import { PaymentsMethodsService } from './services/payments_methods.service';
import { ContractReadjustmentService } from './services/contract_readjustment.service';
import { FileService } from './services/file.service';
import { NgbModule, NgbActiveModal, NgbDatepicker, NgbDropdown, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorComponent } from './error/error.component';
import { EventsService } from './services/events.service';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { CurrencyFormat } from './_utils/pipes/currency-pipe';
import { CompaniesUsersService } from './services/companies_users.service';
import { UsersTypesService } from './services/users_types.service';
import { LogService } from './services/log.service';
import { Globals } from 'src/globals';
import { GoalService } from './services/goal.service';
import { GoalsRuleService } from './services/goals_rule.service';
import { GoalsUserService } from './services/goals_user.service';
import { StoresSalesService } from './services/stores_sales.service';
import { FinanceUnitsService } from './services/finance_unit.service';
import { CustomNgbDateParserFormatter } from './_utils/dateparse/dateparseformatter';
import { PercentFormat } from './_utils/pipes/percent-pipe';

export function TranslationLoaderFactory(http: HttpClient) {
  // return new TranslateHttpLoader(http);
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?cb=' + new Date().getTime());
}
registerLocaleData(ptBr);
import {
  GoogleLoginProvider,
} from 'angularx-social-login';
import { FilterDataService } from './_utils/filter/filter-data.service';
import { UsersGuestsService } from './services/users_guests.service';
import { CompaniesUsersLimitsService } from './services/companies_users_limits.service';
import { CollegesClassesPartnersService } from './services/colleges_classes_partners.service';
import { PhonePipe } from './_utils/pipes/phone.pipe';
import { StringDatePipe } from './_utils/pipes/string-date.pipe';
import { CompaniesService } from './services/companies.service';
import { DepartmentService } from './services/department.service';
import { RoundPipe } from './_utils/pipes/round.pipe';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';

@NgModule({
  declarations: [
    
    AppComponent,
    MainLayoutComponent,
    LoginLayoutComponent,
    LoginComponent,
    SideNavComponent,
    TopNavComponent,
    ErrorComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
  ],
  imports: [
    BrowserModule,
    NgxMaskDirective,
    BrowserAnimationsModule,
    AppRoutingModule,
    CommonModule,
    FormsModule,
    InputMaskModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {provide: TranslateLoader, useFactory: TranslationLoaderFactory, deps: [HttpClient]},
      isolate: true,
    }),
    SocialLoginModule,
    ToasterModule.forRoot(),
    FontAwesomeModule,
    SharedModule,
    NgbModule,
  ],
  providers: [
    AuthMainService,
    provideNgxMask({}),
    { provide: LOCALE_ID, useValue: 'pt' },
    PermissionService,
    ImageService,
    CompaniesUsersLimitsService,
    GeneralComponent,
    FilterDataService,
    Globals,
    ToasterService,
    UsersGuestsService,
    GroupService,
    GroupsUsersService,
    AuthGuard,
    UsersSocialLoginService,
    StatsService,
    CollegesClassesPartnersService,
    ContractService,
    ContractQuotaService,
    StoresSalesService,
    ConfirmationDialogService,
    PaymentsMethodsService,
    ContractReadjustmentService,
    FileService,
    CurrencyFormat,
    PercentFormat,
    DepartmentService,
    DatePipe,
    PhonePipe,
    RoundPipe,
    StringDatePipe,
    CompaniesService,
    EventsService,
    CompaniesUsersService,
    UsersTypesService,
    NgbActiveModal, 
    NgbDatepicker,
    NgbDropdown,
    LogService,
    FinanceUnitsService,
    [
      {
        provide: NgbDateParserFormatter, useFactory: () => new CustomNgbDateParserFormatter('dd/MM/yyyy')
      },
      {
        provide: 'SocialAuthServiceConfig',
        useValue: {
          autoLogin: false,
          providers: [
            {
              id: GoogleLoginProvider.PROVIDER_ID,
              provider: new GoogleLoginProvider(
                '146898856043-rqgl10cns52aql8dme8it3vs2e6si6a6.apps.googleusercontent.com'
              ),
            },
          ],
        } as SocialAuthServiceConfig,
      }
    ],
    GoalService,
    GoalsRuleService,
    GoalsUserService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
