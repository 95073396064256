import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { RestService } from './rest.service';

@Injectable({
  providedIn: 'root'
})
export class CollegesClassesCommissionsService extends RestService {
  private _url = 'CollegesClassesCommissions';
  
  constructor(protected _http: HttpClient) {
    super(_http);
  }

  insertCollegesClassesCommission(Item: any): any {
    let data = JSON.stringify(Item); 
    return this.postApi(this._url, data);
  }

  insertManyCollegesClassesCommissions(Items: any): any {
    let data = {} 
    data['isArray'] = true
    data['items'] = Items;
    return this.postApi(this._url, JSON.stringify(data));
  }

  updateManyCollegesClassesCommissions(Items: any): any {
    let data = {} 
    data['isArray'] = true
    data['items'] = Items;
    return this.putApi(this._url, JSON.stringify(data));
  } 

  updateCollegesClassesCommission(Item: any): any {
    let data = JSON.stringify(Item);
    return this.putApi(this._url + '/' + Item.id, data);
  }

  deleteMember(id: number): any {
    return this.deleteApi(this._url + '/' + id);
  }

  updateField(id: number, field: any, value: any): any {
    let data = JSON.stringify({ id: id, field: field, value: value });
    return this.patchApi(this._url + '/' + id, data);
  }

  getCollegesClassesCommissions(filter_data: any, contain_string: string = '', order_string: string = '+CollegesClassesCommissions.id', page: number = 0, fields: string = '', limit: number = 999): any {
    let qs: string = '';
    let paging: string = '';

    filter_data.map(item => {
      if(item.operator == null || item.operator == ''){
        qs += item.field + '|' + item.value + ';'
      }else{
        qs += item.field + '|' + item.operator + '|' + item.value + ';'
      }
    })
    
    let offset = (page * limit);
    paging = offset + ";" + limit;
    return this.getApi(this._url, { params: { query: qs, paging: paging, order: order_string, contains: contain_string, fields: fields } });
  }
  
  getCollegesClassesCommission(id, contain_string: string = ''){
    return this.getApi(this._url + '/' + id, { params: { contains: contain_string }});
  }

}