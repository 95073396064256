import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { RestService } from './rest.service';

@Injectable({
  providedIn: 'root'
})
export class UserService extends RestService {
  private _url = 'Users';
  private _urlAuth = 'Users/getAuthUser';
  private _urlNoAuth = 'Users/find';
  private _urlRecovery = 'Users/recoveryCode';
  private _urlNewPassword = 'Users/newPassword';
  private _urlValidateCode = 'Users/validateCode';
  private _urlSession = 'Users/sessions';
  private _urlKillSession = 'Users/killSession';


  constructor(protected _http: HttpClient) {
    super(_http);
  }

  changePassword(id: number, newpass: string): any {
    let form = { field: 'password', value: newpass };
    let data = JSON.stringify(form);
    return this.patchApi(this._url + '/' + id, data);
  }

  insertUser(user: any): any {
    delete user.Franchises;
    
    let data = JSON.stringify(user);
    return this.postApi(this._url, data);
  }

  deleteUser(id: number): any {
    return this.deleteApi(this._url + '/' + id);
  }

  updateUser(user: any): any {
    delete user.Franchises;
    let data = JSON.stringify(user);
    return this.putApi(this._url + '/' + user.id, data);
  }

  updateField(id: number, field: any, value: any): any {
    let data = JSON.stringify({ id: id, field: field, value: value });
    return this.patchApi(this._url + '/' + id, data); 
  }

  getRecoveryCode(email: string = '', cellphone: string = ''): any {
    return this.getApi(this._urlRecovery, { params: { email: email, cellphone: cellphone } });
  }

  validateCode(email: string = '', cellphone: string = '', code: string = ''): any {
    return this.getApi(this._urlValidateCode, { params: { email: email, cellphone: cellphone, code: code } });
  }

  getUsers(filter_data: any, contain_string: string = '', order_string: any = '+Users.name', page: number = 0, fields: string = '', limit: number = 20, report:boolean = false, groupData: any = [], operationsData: any = [], unlimited = 0): any {
    let qs: string = '';
    let group: string = '';
    let operations: string = '';
    let paging: string = '';
    let order = order_string

    if (filter_data.id && filter_data.id != null) {
      qs += 'Users.id|' + filter_data.id + ';';
    }
    if (filter_data.notId && filter_data.notId != null) {
      filter_data.notId = filter_data.notId.substring(0, filter_data.notId.length - 1)      
      qs += 'Users.id|notIn|' + filter_data.notId + ';';
    }
    if (filter_data.name && filter_data.name != '') {
      qs += 'Users.name|like|' + filter_data.name + ';';
    }
    if (filter_data.email && filter_data.email != '') {
      qs += 'Users.email|' + filter_data.email + ';';
    }
    if (filter_data.emailLK && filter_data.emailLK != '') {
      qs += 'Users.email|like|' + filter_data.emailLK + ';';
    }
    if (filter_data.nickname && filter_data.nickname != '') {
      qs += 'Users.nickname|like|' + filter_data.nickname + ';';
    }
    if (filter_data.login && filter_data.login != '') {
      qs += 'Users.login|' + filter_data.login + ';';
    }
    if (filter_data.cpf && filter_data.cpf != '') {
      qs += 'Users.cpf|' + filter_data.cpf + ';';
    }
    if (filter_data.gender && filter_data.gender != '') {
      qs += 'Users.gender|' + filter_data.gender + ';';
    }
    if (filter_data.rg && filter_data.rg != '') {
      qs += 'Users.rg|' + filter_data.rg + ';';
    }
    if (filter_data.status && filter_data.status != '') {
      qs += 'Users.status|' + filter_data.status + ';';
    }
    if (filter_data.social_login && filter_data.social_login != '') {
      qs += 'Users.social_login|' + filter_data.social_login + ';';
    }


    if(groupData && groupData.length > 0){
      groupData.map(item => {        
           group += item.field + ';'        
      })
    }
    if(operationsData && operationsData.length > 0){
      operationsData.map(item => {        
           operations += item.operation + '|' + item.field + ';'        
      })
    }
    if(Array.isArray(order)){
      const orderArray = order
      order = ''
      orderArray.map(item => order += `${item.order}${item.field};`)
    }
    
    if(Array.isArray(filter_data)){
      filter_data.map(item => {
        if(item.operator == null || item.operator == ''){
          qs += item.field + '|' + item.value + ';'
        }else{
          qs += item.field + '|' + item.operator + '|' + item.value + ';'
        }
      })
    }
    
    // return
    if(report == true)
       qs = filter_data;

    let offset = (page * limit);
    paging = offset + ";" + limit;
    return this.getApi(this._url, { params: { query: qs, paging: paging, order: order, contains: contain_string, fields: fields, group: group, operations: operations, unlimited: unlimited } });
  }

  getUsersNoAuth(filter_data: any, order_string: string = '+Users.name', page: number = 0, fields: string = '', limit: number = 20): any {
    let qs: string = '';
    let paging: string = '';

    if (filter_data.id && filter_data.id != null) {
      qs += 'Users.id|' + filter_data.id + ';';
    }
    if (filter_data.name && filter_data.name != '') {
      qs += 'Users.name|like|' + filter_data.name + ';';
    }
    if (filter_data.email && filter_data.email != '') {
      qs += 'Users.email|' + filter_data.email + ';';
    }
    if (filter_data.status && filter_data.status != '') {
      qs += 'Users.status|' + filter_data.status + ';';
    }
    if (filter_data.social_login && filter_data.social_login != '') {
      qs += 'Users.social_login|' + filter_data.social_login + ';';
    }

    let offset = (page * limit);
    paging = offset + ";" + limit;
    return this.getApi(this._urlNoAuth, { params: { query: qs, paging: paging, order: order_string, fields: fields } });
  }

  getUsersAuth(filter_data: any): any {
    let qs: string = '';

    if (filter_data.id && filter_data.id != null) {
      qs += 'id=' + filter_data.id;
    }
    return this.getApi(this._urlAuth + '/?' + qs);
  }

  getUsersByClients(clientString: string, filter_data: any, order_string: string = '+Users.name', page: number = 0, fields: string = '', limit: number = 20): any {
    let qs = '';
    let paging = '';

    if (clientString != null) {
      qs += 'Users.company_id|IN|' + clientString + ';';
    }

    if (filter_data.id && filter_data.id != null) {
      qs += 'Users.id|' + filter_data.id + ';';
    }
    if (filter_data.name && filter_data.name != '') {
      qs += 'Users.name|like|' + filter_data.name + ';';
    }
    if (filter_data.status && filter_data.status != '') {
      qs += 'Users.status|' + filter_data.status + ';';
    }

    let contain_string = '';
    let offset = (page * limit);
    paging = offset + ";" + limit;
    return this.getApi(this._url, { params: { query: qs, paging: paging, order: order_string, contains: contain_string, fields: fields } });
  }

  getUser(user_id){
    return this.getApi(this._url + '/' + user_id);
  }

  newPassword(user_id){
    let data = JSON.stringify({user_id: user_id});
    return this.postApi(this._urlNewPassword, data);
  }

  killSession(sessionId) {
    let data = JSON.stringify({ id: sessionId, field: 'status', value: 'i' });
    return this.patchApi(this._urlSession + '/' + sessionId, data);
  }

  renewSession(sessionHash) {
    let qs = '[activeSession]=' + sessionHash;
    return this.getApi(this._urlSession + '/renewSession?query' + qs);
  }

  sessions(){
    return this.getApi(this._urlSession);
  }

  killUserSession(sessionId: string){
    return this.getApi(this._urlKillSession, { params: { session_id: sessionId } });
  }

}