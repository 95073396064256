<app-loading [loading]="loading"></app-loading>

<div class="row">
  <div class="col-sm-12 grid-margin stretch-card">
    <div class="card">
      <breadcrumb></breadcrumb>

      <div class="card-body">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>