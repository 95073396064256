import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { RestService } from './rest.service';

@Injectable()
export class ContractReadjustmentService extends RestService {


    private _url = 'ContractsReadjustments';
    
	constructor(protected _http: HttpClient) {
		super(_http);
    } 
    
    insertContractReadjustment(data: any) : any {
        return this.postApi(this._url, data);
    }
    
    updateContractReadjustmentontract(contract: any) : any {
        let data    = JSON.stringify(contract);
        return this.putApi(this._url + '/' + contract.id, data);
    }
    
    getContractsReadjustments(filter_data: any, contains: string = '', order_string: string = '+ContractReadjustment.id', page: number = 0, fields: string = '', limit: number = 20): any {
        let qs = '';
        let paging  = '';

        if ( filter_data.id && filter_data.id != null ) {
            qs   += 'ContractReadjustment.id|' + filter_data.id + ';';
        }
        if ( filter_data.briefings_id && filter_data.briefings_id != '' ) {
            qs   += 'ContractReadjustment.contracts_id|' + filter_data.briefings_id + ';';
        }
        let offset  = (page * limit);
        paging      = offset + ";" + limit;
        return this.getApi(this._url, {params: {query: qs, paging: paging, order: order_string, contains: contains, fields: fields}});
    }
    
    getContractReadjustment(id: number, contains: string = ''): any {
        return this.getApi(this._url + '/' + id, {params: {contains: contains}});
    }
}