import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RestService } from './rest.service';

@Injectable({
  providedIn: 'root'
})
export class CollegesClassesPartnersService extends RestService {
  private _url = 'CollegesClassesPartners';

  constructor(protected _http: HttpClient) {
    super(_http);
  }

  insertCollegesClassesPartner(obj: any): any {
    let data = JSON.stringify(obj);
    return this.postApi(this._url, data);
  }

  updateCollegesClassesPartner(obj: any): any {
    let data = JSON.stringify(obj);
    return this.putApi(this._url + '/' + obj.id, data);
  }

  deleteCollegesClassesPartner(colleges_classes_id: number) : any {
    let data    = {colleges_classes_id: colleges_classes_id};
    return this.postApi(this._url + '/clean', data);
}

  updateField(id: number, field: any, value: any): any {
    let data = JSON.stringify({ id: id, field: field, value: value });
    return this.patchApi(this._url + '/' + id, data);
  }

}