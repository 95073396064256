import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-lead-notification-card',
  templateUrl: './lead-notification-card.component.html',
  styleUrls: ['./lead-notification-card.component.scss']
})
export class LeadNotificationCardComponent implements OnInit {

  constructor() { }

  @Input()
  notificationData: any

  @Input()
  marginTop: boolean = false

  ngOnInit(): void {
  }

}
