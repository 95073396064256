import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { RestService } from './rest.service';

@Injectable({
  providedIn: 'root'
})
export class LdLeadsSellersService extends RestService {
  private _url = 'LdLeadsSellers';
  
  constructor(protected _http: HttpClient) {
    super(_http);
  }

  insertLeadSeller(obj: any): any {
    let data = JSON.stringify(obj);
    return this.postApi(this._url, data);
  }

  updateLeadSeller(obj: any): any {
    let data = JSON.stringify(obj);
    return this.putApi(this._url + '/' + obj.id, data);
  }

  deleteLeadSeller(id: number): any {
    return this.deleteApi(this._url + '/' + id);
  }

  updateField(id: number, field: any, value: any): any {
    let data = JSON.stringify({ id: id, field: field, value: value });
    return this.patchApi(this._url + '/' + id, data);
  }

  getLeadsSellers(filter_data: any, contain_string: string = '', order_string: string = '+LdLeadsSellers.id', page: number = 0, fields: string = '', limit: number = 20): any {
    let qs: string = '';
    let paging: string = '';

    if(Array.isArray(filter_data)){
      filter_data.map(item => {
          if(item.operator == null || item.operator == ''){
          qs += item.field + '|' + item.value + ';'
          }else{
          qs += item.field + '|' + item.operator + '|' + item.value + ';'
          }
      })
    }

    let offset = (page * limit);
    paging = offset + ";" + limit;
    return this.getApi(this._url, { params: { query: qs, paging: paging, order: order_string, contains: contain_string, fields: fields } });
  }
  
  getLeadSeller(id){
    return this.getApi(this._url + '/' + id);
  }

}