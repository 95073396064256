import {Pipe} from '@angular/core';

@Pipe({
    name: 'percentFormat'
})
export class PercentFormat {
    transform(value: number,
        percentSign: string = '%',
        decimalLength: number = 2, 
        chunkDelimiter: string = '.', 
        decimalDelimiter:string = ',',
        chunkLength: number = 3): string {

        if ( value != null ) {
            // value /= 100;
            let result = '\\d(?=(\\d{' + chunkLength + '})+' + (decimalLength > 0 ? '\\D' : '$') + ')';
            let num = value.toFixed(Math.max(0, ~~decimalLength));

            return (decimalDelimiter ? num.replace('.', decimalDelimiter) : num) +percentSign.replace(new RegExp(result, 'g'), '$&' + chunkDelimiter);
        }
    }
}