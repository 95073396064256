<div class="crm-card">
	<div class="crm-card-header">
		<h3 class="crm-card-title">Faturamento médio mensal por produto</h3>
		<div ngbDropdown>
		    <button ngbDropdownToggle>
		        <span class="dot"></span>
				<span class="dot"></span>
				<span class="dot"></span>
		    </button>
		    <div ngbDropdownMenu class="dropdown-menu p-3">
		        <button class="mb-2">
		        	<i class="icon pdf-icon"></i>
		        	<span class="formo-p2">Baixar gráfico em .PDF</span>
		        </button>
		        <button>
		        	<i class="icon csv-icon"></i>
		        	<span class="formo-p2">Baixar gráfico em .CSV</span>
		        </button>
		    </div>
		</div>
	</div>
	<div class="crm-card-body">

		<div class="crm-card-info-detail mmd:ml-1">
			<div class="item" *ngFor="let data of summary; let i = index" [attr.data-index]="i">
				<header>
					<i [style.background]="colorScheme.domain[i]"></i>
					<p class="mmd:pr-3">{{ data.name }}</p>
				</header>
				<main>
					<small>R$</small>
					<p class="mmd:text-sm">104.764,12</p>
				</main>
			</div> 
		</div>

		<div style="width: 100%; height: 300px;">
			<ngx-charts-line-chart 
				[scheme]="colorScheme"
				[xAxis]="true"
				[yAxis]="true"
				[autoScale]="false"
				[results]="summary"
				[yAxisTickFormatting]="yAxisTickFormatting"
				[curve]="curve"
				stroke-width="5px"
				schemeType="ordinal"
			>
			</ngx-charts-line-chart>
		</div>
	</div>
</div>

