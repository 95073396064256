import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";

import { Router } from "@angular/router";
import { Login } from "../model/login.model";
import { GeneralComponent } from "../_utils/general/general.component";
import { SocialAuthService } from "angularx-social-login";
import { GoogleLoginProvider } from "angularx-social-login";
import { UsersSocialLoginService } from "../services/users_social_login.service";
import { Md5 } from "md5-typescript";
import { UserSocialLogin } from '../model/user_social_login.model';
import { AuthMainService } from "../services/auth.service";
import { AppComponent } from '../app.component';
import { Globals } from 'src/globals';

@Component({
  selector: "app-login-page",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})


export class LoginComponent implements OnInit {
  @ViewChild("password") loginPassElement: ElementRef;

  email: string = '';
  password: string = '';


  loading: boolean = false;
  login: Login = new Login();
  year: number;
  numberBg: string = "1";


  constructor(
    public globals: Globals,
    private authService: AuthMainService,
    private appComponent: AppComponent,
    private socialAuthService: SocialAuthService,
    private general: GeneralComponent,
    private router: Router,
    private userSocialLoginService: UsersSocialLoginService
  ) {
    if (this.router.url == "/logout") {
      this.logout();
    }
    let date = new Date();
    this.year = date.getFullYear();
    this.numberBg = localStorage.getItem("bg-login");
  }

  ngOnInit() {
    const auth = JSON.parse(localStorage.getItem("auth"));
    if (auth && auth.isLogged) this.router.navigate(["/"]);
  }

  signIn() {
    this.loading = true;
    this.authService.unsetUser();
    this.authService.preLogin(this.login.email, this.login.password).then(
      (response) => {
        if (
          response.summary &&
          (response.summary.process == false ||
            response.summary.message == "error")
        ) {
          this.general.setMessage("LOGIN.PASSWD-NOT-FOUND");
          this.loading = false;
        } else {
          this.authService
            .login(this.login.email, this.login.password)
            .then((response) => {
              localStorage.removeItem("mainUser");
              localStorage.setItem("tokenUser", response.data.tokenUser);
              localStorage.setItem(
                "tokenUserExpires",
                response.data.tokenUserExpires
              );

              let permissions = [];
              if (
                response &&
                response.data.permissions &&
                response.data.permissions.length > 0
              )
                permissions = response.data.permissions;

              if (permissions.length == 0) {
                localStorage.removeItem("preUser");
                localStorage.removeItem("tokenUser");
                localStorage.removeItem("tokenUserExpires");
                localStorage.removeItem("auth");
                localStorage.removeItem("sessionHash");
                localStorage.removeItem("avatar");
                localStorage.removeItem("lData");

                this.general.setMessage("LOGIN.USER-NOT-HAVE-ACCESS");
                this.loading = false;
                this.router.navigate(["/login"]);
                return;
              }

              localStorage.removeItem("internalMenu");
              localStorage.removeItem("internalMenuLocal");
              localStorage.removeItem("internalMenuActiveItem");
              permissions.push("users/change-password");
              permissions.push("users/profile");
              if (permissions.length > 0) {
                this.authService
                  .setUser(response.data, permissions)
                  .then((r) => {
                    if (r) {
                      this.authService
                        .getAvatar(response.data.id)
                        .then(async (result) => {
                          localStorage.setItem(
                            "sessionHash",
                            response.data.sessionHash
                          );
                          await this.verifySocialAccountSync()
                          this.general.setMessage("LOGIN.WELCOME", "success");
                          this.appComponent.loadConfig().then(() => {
                            this.router.navigate(["/home"]);
                          });
                          this.loading = false;
                        });
                    }
                  });
              } else {
                localStorage.removeItem("tokenUser");
                this.general.setMessage("LOGIN.USER-NOT-HAVE-ACCESS");
                this.loading = false;
              }
            });
        }
      },
      (error) => {
        this.general.setMessage(<any>error);
        this.loading = false;
      }
    );
    // }
  }

  async verifySocialAccountSync() {
    const verifySocialData  = JSON.parse(localStorage.getItem('social_to_sync_google'))
    const user              = JSON.parse(localStorage.getItem('auth')).user

    if(verifySocialData){
      const now         = new Date()
      const validUntil  = new Date(verifySocialData.sync_expires)
      if(now < validUntil){
        await this.userSocialLoginService.getUsersSocialLogin([{field: 'UsersSocialLogin.user_id', value: user.id}]).then(async (ret) => {
          if(ret.summary.total > 0){
            this.general.setMessage('SOCIAL.ACCOUNT-ALREADY-LINKED')
          }else{
            await this.userSocialLoginService.getUsersSocialLogin([{field: 'UsersSocialLogin.account', value: verifySocialData.email}]).then(async (ret) => {
              if(ret.summary.total > 0){
                this.general.setMessage('SOCIAL.OTHER-ACCOUNT-ALREADY-LINKED')
              }else{
                const newSocialLogin = new UserSocialLogin(null, 'google', user.id, verifySocialData.email, verifySocialData.id, verifySocialData.authToken, verifySocialData.photoUrl)

                await this.userSocialLoginService.insertSocialLogin(newSocialLogin).then(() => {
                  localStorage.setItem('avatar', newSocialLogin.photo_url);
                  this.general.setMessage('LOGIN.LINKED-ACCOUNT', 'success')
                })
              }

            })

          }
        })
      }

    }

    localStorage.removeItem('social_to_sync_google')
  }

  backStep() {
    this.login.email = null;
  }

  logout() {
    this.authService.unsetUser();
    this.appComponent.ngOnInit();
    this.router.navigate(["/login"]);
  }

  signInWithGoogle(): void {
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then((googleData) => {
        //verify if user exists
        this.authService.getSocialLogin(Md5.init(googleData.email)).then((userData: any) => {
            if (userData.summary.process == false && userData.summary.message == "user_not_found") {
              //NENHUM USUARIO ENCONTRADO, AGUARDAR LOGIN SISTEMA PARA VINCULAR
              const today = new Date()
              today.setMinutes(today.getMinutes() + 10)
              const sync_expires = today
              const toSave = {...googleData, sync_expires}
              localStorage.setItem('social_to_sync_google', JSON.stringify(toSave))
              this.general.setMessage('LOGIN.SOCIAL-USER-NOT-FOUND')
            } else if (userData.summary.process == true && userData.summary.message == "logined") {
              localStorage.removeItem("mainUser");
              localStorage.setItem("tokenUser", userData.data.tokenUser);
              localStorage.setItem(
                "tokenUserExpires",
                userData.data.tokenUserExpires
              );

              let permissions = [];
              if (
                userData &&
                userData.data.permissions &&
                userData.data.permissions.length > 0
              )
                permissions = userData.data.permissions;

              if (permissions.length == 0) {
                localStorage.removeItem("preUser");
                localStorage.removeItem("tokenUser");
                localStorage.removeItem("tokenUserExpires");
                localStorage.removeItem("auth");
                localStorage.removeItem("sessionHash");
                localStorage.removeItem("avatar");
                localStorage.removeItem("lData");

                this.general.setMessage("LOGIN.USER-NOT-HAVE-ACCESS");
                this.loading = false;
                this.router.navigate(["/login"]);
                return;
              }

              localStorage.removeItem("internalMenu");
              localStorage.removeItem("internalMenuLocal");
              localStorage.removeItem("internalMenuActiveItem");
              permissions.push("users/change-password");
              permissions.push("users/profile");
              permissions.push("users/notifications-admin");
              if (permissions.length > 0) {
                this.authService
                  .setUser(userData.data, permissions)
                  .then((r) => {
                    if (r) {
                      this.authService.getAvatar(userData.data.id, true, googleData.photoUrl).then((result) => {
                          localStorage.setItem(
                            "sessionHash",
                            userData.data.sessionHash
                          );
                          this.general.setMessage("LOGIN.WELCOME", "success");
                          this.appComponent.loadConfig().then(() => {
                            this.router.navigate(["/home"]);
                          });
                          this.loading = false;
                        });
                    }
                  });
              } else {
                localStorage.removeItem("tokenUser");
                this.general.setMessage("LOGIN.USER-NOT-HAVE-ACCESS");
                this.loading = false;
              }
            }
          });
      })
      .catch((err) => {});
  }
}



