import { Component, Input, OnInit } from '@angular/core';
// import * as color from 'color';

@Component({
  selector: 'crm-use-card',
  templateUrl: './crm-use-card.component.html',
})
export class CrmUseCardComponent implements OnInit {
	totalUse = 0;
  initialColor = '#22315B';

	@Input() summary: any[];

	colorScheme = {
    domain: [],
  };

  constructor() {
  }

  ngOnInit() {
    for (let item of this.summary) {
      this.totalUse += item.value;

      this.colorScheme.domain.push(this.initialColor);
      // this.initialColor = color(this.initialColor).lighten(0.4);
    }
  }
}
