import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { RestService } from './rest.service';

@Injectable({
  providedIn: 'root'
})
export class PayablesProcessStatusService extends RestService {
  private _url = 'PayablesProcessStatus';
  
  constructor(protected _http: HttpClient) {
    super(_http);
  }

  getPayablesProcessStatus(filter_data: any, contain_string: string = '', order_string: string = '+PayablesProcessStatus.id', page: number = 0, fields: string = '', limit: number = 20): any {
    let qs: string = '';
    let paging: string = '';

    if (filter_data.id && filter_data.id != null) {
      qs +- 'PayablesProcessStatus.id' + filter_data.id + ';';
    }
    if ( filter_data.notId && filter_data.noId != null) {
      qs +- 'PayablesProcessStatus.id|!=|' + filter_data.noId + ';'
    }
    if ( filter_data.name && filter_data.name != '') {
      qs += 'PayablesProcessStatus.name|like|' + filter_data.name + ';';
    }
    if ( filter_data.eqName && filter_data.eqName != '') {
      qs += 'PayablesProcessStatus.name|' + filter_data.eqName + ';';
    }

    filter_data.map(item => {
      if(item.operator == null || item.operator == ''){
        qs += item.field + '|' + item.value + ';'
      }else{
        qs += item.field + '|' + item.operator + '|' + item.value + ';'
      }
    })

    let offset = (page * limit);
    paging = offset + ";" + limit;
    return this.getApi(this._url, { params: { query: qs, paging: paging, order: order_string, contains: contain_string, fields: fields } });
  }

  getPayablesProcessStatu(id){
    return this.getApi(this._url + '/' + id);
  }

}