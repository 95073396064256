<form>
    <div class="modal-header border-bottom-0">
        <!-- Botão fechar modal -->
        <div (click)="activeModal.dismiss()" class="button-close-modal d-none d-md-flex">
          <p class="m-0">&times;</p>
        </div>
        <div class="d-lg-none">
          <button type="button" class="close" (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
    </div>
    <!-- Corpo Modal -->
    <div class="card border-top-0">
        <div class="card-body">
            <ng-container *ngIf="tab != 'config'">
                <!-- Menu -->
                <div class="row pt-">
                    <div class="col-12">
                        <div class="card-body prop-tabs d-flex pb-0 pl-0 pr-0 overflow-auto">
                            <a [class.border-color-select]="tab == 'unread'" (click)="select('unread', 'unread', 20)" class="border-bottom-nav nowrap text-center c-pointer text-decoration-none">{{ 'NOTIFICATIONS.NOT-READ' | translate }} ({{not_read}})</a>
                            <a [class.border-color-select]="tab == 'read'" (click)="select('read', 'read', 20)" class="border-bottom-nav nowrap text-center c-pointer text-decoration-none">{{ 'NOTIFICATIONS.READ' | translate }}</a>
                            <a [class.border-color-select]="tab == 'config'" (click)="select('config', null)" class="border-bottom-nav c-pointer text-decoration-none">{{ 'NOTIFICATIONS.CONFIGURATIONS' | translate }}</a>
                            <a class="border-bottom-nav flex-fill"></a>
                        </div>
                    </div>
                </div>
                <!-- Botão marcar todas como lidas -->
                <div class="row justify-content-end" *ngIf="data && data.summary && data.summary.total > 0">
                    <div class="col-12 col-md-4 d-block d-sm-flex mt-4 mr-3 p-0 justify-content-end">
                        <div class="c-pointer" (click)="markAllAsRead()">
                            <p class="button-markall">{{ 'NOTIFICATIONS.MARK-ALL-AS-READ' | translate }}</p>
                        </div>
                    </div>
                </div>
                <!-- Box notificações Não Lidas -->
                <ng-container *ngIf="data && data.summary && data.summary.total > 0">
                    <div *ngFor="let item of data.items">
                        <div class="mt-5"><h5 class="datetime">{{item.data.Notifications.created | date: 'dd/mm/yyyy'}}</h5></div>
                        <div class="box-notifications d-inline-block">
                            <div class="row">
                                <div class="col-7"><h5>{{item.data.Notifications.NotificationsTypes.name}}</h5></div>
                                <div class="col-5 d-md-flex text-lowercase small text-info justify-content-end">
                                    <a class="button-markall" *ngIf="item.data.read_status == false" href="javascript:void(0);" (click)="markReadUnread(item)">{{'NOTIFICATIONS.MARK-AS-READ' | translate}}</a>
                                    <a class="button-markall" *ngIf="item.data.read_status == true" href="javascript:void(0);" (click)="markReadUnread(item)">{{'NOTIFICATIONS.MARK-AS-UNREAD' | translate}}</a>
                                </div>
                            </div>
                            <div class="row mt-1">
                                <div class="col-7 font-weight-bold" *ngIf="item.data.Notifications.link != null">
                                    <a routerLink="{{item.data.Notifications.link}}" (click)="markReadUnread(item)">{{item.data.Notifications.description}}</a>
                                </div>
                                <div class="col-7 font-weight-bold" *ngIf="item.data.Notifications.link == null">{{item.data.Notifications.description}}</div>
                                <div class="col-5 ">
                                    <h5 class="datetime justify-content-end">{{item.data.Notifications.created | date: 'HH:mm'}}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <!-- Box notificação Sem Notificações -->
                <ng-container *ngIf="data && data.summary && data.summary.total == 0">
                    <div class="notificacao">
                        <img src="assets/images/ilustra-sem-propostas.svg">
                        <h5>{{ 'GENERAL.NO-NOTIFICATION' | translate}}</h5>
                    </div>
                </ng-container>
            </ng-container>
            <!-- Aba configurações -->
            <ng-container *ngIf="tab == 'config'">
                <div class="row pt-">
                    <div class="col-12">
                        <div class="card-body prop-tabs d-flex pb-0 pl-0 pr-0 overflow-auto">
                            <a [class.border-color-select]="tab == 'unread'" (click)="select('unread', 'unread', 20)" class="border-bottom-nav nowrap text-center c-pointer text-decoration-none">{{ 'NOTIFICATIONS.NOT-READ' | translate }} ({{not_read}})</a>
                            <a [class.border-color-select]="tab == 'read'" (click)="select('read', 'read', 20)" class="border-bottom-nav nowrap text-center c-pointer text-decoration-none">{{ 'NOTIFICATIONS.READ' | translate }}</a>
                            <a [class.border-color-select]="tab == 'config'" (click)="select('config', null)" class="border-bottom-nav c-pointer text-decoration-none">{{ 'NOTIFICATIONS.CONFIGURATIONS' | translate }}</a>
                            <a class="border-bottom-nav flex-fill"></a>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="!loading && modules">
                    <div class="col-12 mt-5">
                        <div class="module-item mb-4 p-0" *ngFor="let module of modules; let i = index">
                            <div class="row">
                                <div class="col-6 col-md-8">
                                    <h4>{{module.data.module}}</h4>
                                </div>
                                <div class="col-6 col-md-4">
                                    <div class="row">
                                        <div class="col-7 col-md-6 p-o p-md-2">
                                            <small>{{'GENERAL.PLATFORM' | translate}}</small>
                                        </div>
                                        <div class="col-5 col-md-6 p-o p-md-2">
                                            <small>{{'GENERAL.EMAIL' | translate}}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row item" *ngFor="let item of module.items; let i1 = index">
                                <div class="col-6 col-md-8 p-0">
                                    <p>{{item.data.name}}</p>
                                </div>
                                <div class="col-4">
                                    <div class="row d-flex align-items-center">
                                        <div class="col-6">
                                            <div class="form-group row">
                                                <div class="col-12">
                                                    <label class="custom-checkbox secondary">
                                                        <input type="checkbox" (click)="updateNotificationUser(i, i1, 'system', $event);" [checked]="item.data.nu && item.data.nu['system'] != null">
                                                        <span class="checkmark">
                                                            <i class="fa fa-check checked"></i>
                                                            <i class="fa fa-minus not-checked"></i>
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group row">
                                                <div class="col-12">
                                                    <label class="custom-checkbox secondary">
                                                        <input type="checkbox" (click)="updateNotificationUser(i, i1, 'email', $event);" [checked]="item.data.nu && item.data.nu['email'] != null">
                                                        <span class="checkmark">
                                                            <i class="fa fa-check checked"></i>
                                                            <i class="fa fa-minus not-checked"></i>
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</form>
















