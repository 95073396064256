<div class="top-nav">
    <div class="flex">
        <div class="brand no-hover">
            <div class="mx-4 my-4">
                <a routerLink="/"  *ngIf="isNormalTopNav">
                    <img [src]="globals.brand" [alt]="globals.title" class="brand-logo">
                </a>
                <button (click)="showResponsiveMenu = !showResponsiveMenu">
                    <span *ngIf = "!showResponsiveMenu" class="fa fa-bars d-md-none white mr-7 w-3">
                    </span>
                    <span *ngIf = "showResponsiveMenu" class="fa fa-times d-md-none white mr-7 w-3">
                    </span>
                </button>
            </div>
            <div *ngIf="showResponsiveMenu" class="show-menu">
                <side-nav></side-nav>
            </div>
        </div>
        <div class="nav-wrapper" *ngIf="isNormalTopNav">
            <div class="top-wrapper">
                <h1 class="h1-mobile mt-2">{{title}}</h1>
                <div class="row top-wrapper-right d-flex align-items-center">
                    <a class="nav-item d-flex row justify-end mmd:h-11 mr-3 ml-4 ml-md-1"
                        (click)="openNotificationsModalComponent(Notifications)">
                        <div class="position-absolute notification-counter-html">
                            <div class="badge" *ngIf="notificationCounter > 99">{{"99+"}}</div>
                            <div class="badge" *ngIf="notificationCounter > 0 && notificationCounter < 100">{{notificationCounter}}</div>
                        </div>
                        <div class="-with-badge mmd:ml-9 mmd:mr-5" *ngIf="notificationCounter >= 1">
                            <img src="assets/images/icons/ico-notificacoes-on.svg" class="ico-notification-on">
                        </div>
                        <div class="-with-badge mmd:ml-9 mmd:mr-5" *ngIf="notificationCounter == 0">
                            <img src="assets/images/icons/ico-notificacoes.svg" class="ico-notification">
                        </div>
                    </a>
                    <div class="nav-item-icon ml-2">
                        <a [class.active]="activeItem == 'profile'" (click)="goToProfile()"
                            class="nav-item mmd:flex mmd:flex-row mmd:justify-start mmd:h-11 position-relative">
                            <avatar name="{{logged.user.name}}" color="{{logged.user.avatar_color}}" image="{{actual_img}}"
                            size="size-2x"></avatar>
                        </a>
                    </div>
                </div>
            </div>
            <nav class="mt-3">
                <ng-container *ngFor="let item of items; let i = index">
                    <a class="nav-item" [ngClass]="{'active': item.label === activeItem}" tabindex="0" *ngIf="item.path && item.path != ''" [routerLink]="item.path" (click)="onItemClick(i)" >
                        <div *ngIf="item.notification > 0" class="badge "> {{item.notification}} </div>
                        <span class="nav-item-label"> {{item.label}} </span>
                    </a>
                    <a *ngIf="item.children && item.children.length > 0" class="nav-item" role="button" (click)="toggleDropdown(i)" [ngClass]="{'active': item.label === activeItem}" >
                        <span class="nav-item-label clickOutsideToClose"> {{item.label}} </span>
                        <div *ngIf="item.children && item.children.length > 0" [class.d-block]="subs[i]" class="d-none dropdown">
                            <ul>
                                <li *ngFor="let sub of item.children">
                                    <a [ngClass]="{'active': sub.content === activeItem, 'pr-md-6': i === 8}" *ngIf="sub.action && sub.action != ''" [routerLink]="'/' + sub.action" (click)="onItemClick(i)" >
                                        <span class="nav-item-label">
                                            {{sub.content}}
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </a>
                </ng-container>
            </nav>
        </div>
    </div>
    <div class="nav-wrapper" *ngIf="!isNormalTopNav">
        <nav>
            <a *ngFor="let item of items; let i = index" class="nav-item cursor-pointer" [ngClass]="{'active': item.label === activeProjectItem}" (click)="onItemProjectClick(i)" >
                <div *ngIf="item.notification > 0" class="badge">
                    {{item.notification}}
                </div>
                <span class="nav-item-label">
                    {{item.label}}
                </span>
            </a>
        </nav>
    </div>
</div>