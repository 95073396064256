import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { RestService } from './rest.service';

@Injectable({
  providedIn: 'root'
})
export class CollegesCoursesService extends RestService {
  
  private _url = 'CollegesCourses';
  
  constructor(protected _http: HttpClient) {
    super(_http);
  }

  insertCollegeCourse(collegeCourse: any): any {
    let data = JSON.stringify(collegeCourse);
    return this.postApi(this._url, data);
  }

  updateCollegeCourse(course:any){
    let data    = JSON.stringify(course);
    return this.putApi(this._url + '/' + course.id, data);
  }

  getCollegeCourses(filter_data: any, contain_string: string = '', order_string: string = '', page: number = 0, fields: string = '', limit: number = 20, group: string = ''): any {
    let qs: string = '';
    let paging: string = '';

    if (filter_data.id && filter_data.id != null) {
      qs += 'CollegesCourses.id|' + filter_data.id + ';';
    }

    if (filter_data.colleges_campus_id && filter_data.colleges_campus_id != null) {
      qs += 'CollegesCourses.colleges_campus_id|' + filter_data.colleges_campus_id + ';';
    }
    if (filter_data.colleges_id && filter_data.colleges_id != null) {
      qs += 'CollegesCourses.colleges_id|' + filter_data.colleges_id + ';';
    }
    if (filter_data.name && filter_data.name != '') {
      qs += 'CollegesCourses.name|like|' + filter_data.name + ';';
    }
    let offset = (page * limit);
    paging = offset + ";" + limit;
    return this.getApi(this._url, { params: { query: qs, paging: paging, order: order_string, contains: contain_string, fields: fields, group: group } });
  }
  
  getCollegeCourseById(id){
    return this.getApi(this._url + '/' + id, { params: {contains: 'Courses'} });
  }

  deleteCourse(id: number): any {
    return this.deleteApi(this._url + '/' + id);
  }

}