import { Injectable } from '@angular/core';

@Injectable()
export class Globals {
  title: string = 'Tag S/A';
  brand: string = '/assets/images/brand.svg';

  colors = {
    white: '#FFFFFF',
    lighterGray: '#FDFDFF',
    lightGray: '#F7F7FC',
    gray: '#DDDDEE',
    darkGray: '#BDBDDC',
    darkerGray: '#6C6C75',
    darkestGray: '#45444B',
    almostBlack: '#27272a',
    lightBlue: '#60CAEA',
    blue: '#4767BC',
    darkBlue: '#354E92',
    darkerBlue: '#22315B',
    yellow: '#FCBA59',
    pink: '#F9C6C7',
    red: '#F16655',
    green: '#87B9A2',
    lightBeige: '#FFF6E9',
    beige: '#FFECD2',
  };

  avatarColors: string[] = [
    this.colors.pink,
    this.colors.beige,
    this.colors.green,
    this.colors.yellow,
    this.colors.red,
    this.colors.blue,
    this.colors.lightBlue,
  ];

  productColor: any[] = [
    {
      label: 'Formatura',
      color: 'light-blue',
      hex: '#60CAEA',
    },
    {
      label: 'Fotografia',
      color: 'blue',
      hex: '#2F4173',
    },
    {
      label: 'Convites',
      color: 'pink',
      hex: '#F9C6C7',
    },
  ];

  getRecurrencyLabel(rec) {
    switch (rec) {
      case 'monthly':
        return 'RECURRENCY.MONTHLY';
      case 'quarterly':
        return 'RECURRENCY.QUARTERLY';
      case 'semiannual':
        return 'RECURRENCY.SEMIANNUAL';
      case 'yearly':
        return 'RECURRENCY.YEARLY';
      case 'biannual':
        return 'RECURRENCY.BIANNUAL';
      default:
        return rec;
    }
  }

  getPayableTypeLabel(type) {
    switch (type) {
      case 'bill':
        return 'PAYABLES.TYPES.BILL';
      case 'transfer':
        return 'PAYABLES.TYPES.TRANSFER';
      default:
        return type;
    }
  }

  getMonthLabel(month) {
    month = month + 1;

    switch (month) {
      case 1:
        return 'MONTHS.JANUARY';
      case 2:
        return 'MONTHS.FEBRUARY';
      case 3:
        return 'MONTHS.MARCH';
      case 4:
        return 'MONTHS.APRIL';
      case 5:
        return 'MONTHS.MAY';
      case 6:
        return 'MONTHS.JUNE';
      case 7:
        return 'MONTHS.JULY';
      case 8:
        return 'MONTHS.AUGUST';
      case 9:
        return 'MONTHS.SEPTEMBER';
      case 10:
        return 'MONTHS.OCTOBER';
      case 11:
        return 'MONTHS.NOVEMBER';
      case 12:
        return 'MONTHS.DECEMBER';
    }
  }

  getMonthShortLabel(month) {
    month = month + 1;

    switch (month) {
      case 1:
        return 'Jan';
      case 2:
        return 'Fev';
      case 3:
        return 'Mar';
      case 4:
        return 'Abr';
      case 5:
        return 'Mai';
      case 6:
        return 'Jun';
      case 7:
        return 'Jul';
      case 8:
        return 'Ago';
      case 9:
        return 'Set';
      case 10:
        return 'Out';
      case 11:
        return 'Nov';
      case 12:
        return 'Dez';
    }
  }

  getPriorityLabel(value) {
    switch (value) {
      case 'low':
        return 'Baixa';
      case 'normal':
        return 'Média';
      case 'high':
        return 'Alta';
    }
  }

  validateEmail(email: string) {
    let test: boolean;
    let regexp = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    test = regexp.test(email);
    return test;
  }

  validateCPF(cpf) {
    var soma = 0;
    var resto;
    var inputCPF = cpf.replace('.', '').replace('.', '').replace('-', '');

    if (inputCPF == '00000000000') return false;
    for (let i = 1; i <= 9; i++)
      soma = soma + parseInt(inputCPF.substring(i - 1, i)) * (11 - i);
    resto = (soma * 10) % 11;

    if (resto == 10 || resto == 11) resto = 0;
    if (resto != parseInt(inputCPF.substring(9, 10))) return false;

    soma = 0;
    for (let i = 1; i <= 10; i++)
      soma = soma + parseInt(inputCPF.substring(i - 1, i)) * (12 - i);
    resto = (soma * 10) % 11;

    if (resto == 10 || resto == 11) resto = 0;
    if (resto != parseInt(inputCPF.substring(10, 11))) return false;
    return true;
  }

  validateCNPJ(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj == '') return false;

    if (cnpj.length != 14) return false;

    // Elimina CNPJs invalidos conhecidos
    if (
      cnpj == '00000000000000' ||
      cnpj == '11111111111111' ||
      cnpj == '22222222222222' ||
      cnpj == '33333333333333' ||
      cnpj == '44444444444444' ||
      cnpj == '55555555555555' ||
      cnpj == '66666666666666' ||
      cnpj == '77777777777777' ||
      cnpj == '88888888888888' ||
      cnpj == '99999999999999'
    )
      return false;

    // Valida DVs
    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(0)) return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(1)) return false;

    return true;
  }
}
