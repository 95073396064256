import { Component, OnInit, Input, EventEmitter, Output, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Globals } from 'src/globals';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { NotificationsModalComponent } from 'src/app/users/notifications/notifications-modal/notifications-modal';
import { NotificationsStatusService } from 'src/app/services/notifications_status.service';
import { AuthMainService } from 'src/app/services/auth.service';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: "top-nav",
    templateUrl: "./top-nav.component.html",
    styleUrls: ['./top-nav.component.scss'],
    providers: [Globals]
})
export class TopNavComponent implements OnInit {
    showResponsiveMenu = false;
    @Input() isNormalTopNav = true;
    @Input() projectPageIndex = 1;
    @Output() newPageIndex: EventEmitter<any> = new EventEmitter;
    internalMenuSubscription: any;

    auth: any;
    actual_img: string = '';
    itemsProject: any[] = []

    items: any[] = [];
    subs: any[] = [];

    title: String;
    activeItem: String;
    activeProjectItem: string;

    personateActive: boolean = false
    personate: any;

    isMenuOpened: boolean = false;
    notifications: {
        total: number,
        total_unread: number,
        items: any[]
      } = {
        total: 0,
        total_unread: 0,
        items: []
      };
      logged:                 any = null;
      notificationCounter: any;

    toggleDropdown(index: number): void {
        this.subs[index] = !this.subs[index];
        this.subs.forEach((_, i) => {
            if (index != i)this.subs[i] = false
        })
        this.isMenuOpened = this.subs.some(value => value);
    }

    @HostListener("document:click", ['$event.target'])
    clickedOutside(event: any): void {
        if (event.classList.contains('clickOutsideToClose')) return;

        this.subs.forEach((_, i) => {
            this.subs[i] = false
        })
    }

    constructor(
        public globals: Globals,
        public router: Router,
        private authService: AuthMainService,
        private modalService: NgbModal,
        private titleService: Title,
        private notificationService: NotificationsStatusService,
        private translate: TranslateService
    ) {
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        };
        this.internalMenuSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
              this.router.navigated = false;
            }
        });
    }

    ngOnInit() {
        this.logged         = this.authService.getUser();
        this.load();
        this.loadPersonate()
        this.loadNotifications()
        this.auth       = this.authService.getUser();
        this.actual_img   = localStorage.getItem('avatar');
    if ( this.actual_img == null ) {
      this.authService.getAvatar(this.auth.user.id).then(result => {
        this.actual_img   = localStorage.getItem('avatar');
      });
    }
    }

    logout() {
        this.authService.unsetUser();
        this.router.navigate(["/login"]);
    }

    ngOnDestroy() {
        if (this.internalMenuSubscription) {
            this.internalMenuSubscription.unsubscribe();
        }
    }

    loadPersonate() {
        this.personate = JSON.parse(localStorage.getItem('mainUser'))
        if(this.personate != null && this.personate.personate == true) {
          this.personateActive = true;
        }
    }

    load() {
        let menu = [];
        if ( localStorage.getItem('menu') )
            menu = JSON.parse(localStorage.getItem('menu'));

        this.processMenu(menu);
    }

    async processMenu(menu) {
        let getUrl      = window.location.href.split('/');
        let pathString  = getUrl[3];
        if ( getUrl[4] != undefined )
            pathString += '/' + getUrl[4];
        if ( pathString == '' ) {
            pathString  = 'dashboard';
        }
        if ( menu.length > 0 ) {
            let actualIndex = 0;
            let newTitle    = JSON.parse(localStorage.getItem('title'));
            this.items      = JSON.parse(localStorage.getItem('internalMenu'));
            this.activeItem = JSON.parse(localStorage.getItem('internalMenuActiveItem'));
            await menu.map((itemmenu: any, i) => {
                if ( itemmenu.action ) {
                    if ( itemmenu.action == pathString ) {
                        newTitle  = itemmenu.content;
                    }
                } else if ( itemmenu.children ) {
                    itemmenu.children.map((itemmenu1: any) => {
                        if ( itemmenu1.children ) {
                            for ( let i2 = 0; itemmenu1.children[i2]; i2++ ) {
                                if ( itemmenu1.children[i2].action == pathString ) {
                                    newTitle  = itemmenu.content;
                                    this.activeItem = itemmenu.content;
                                    actualIndex = i;
                                }
                            }
                        } else {
                            if ( itemmenu1.action == pathString ) {
                                newTitle  = itemmenu.content;
                                this.activeItem = itemmenu.content;
                                actualIndex = i;
                            }
                        }
                    })
                }
            });
            if ( newTitle != null && newTitle != JSON.parse(localStorage.getItem('title')) ) {
                localStorage.setItem('title', JSON.stringify(newTitle));
                this.title  = newTitle;
                let newItems  = [];
                if ( menu[actualIndex].children && menu[actualIndex].children.length > 0 ) {
                    menu[actualIndex].children.map((itemmenu: any, i1) => {
                        if ( itemmenu.children && itemmenu.children.length > 0 ) {
                            this.subs[i1] = false;
                            newItems.push({
                                label: itemmenu.content,
                                children: itemmenu.children,
                                notification: 0
                            });
                        } else {
                            newItems.push({
                                label: itemmenu.content,
                                path: ['/' + itemmenu.action],
                                notification: 0
                            });
                        }
                    })
                    this.items  = newItems;
                }
            } else {
                this.title  = newTitle;
                if ( localStorage.getItem('internalMenu') ) {
                    this.items  = JSON.parse(localStorage.getItem('internalMenu'));
                }
            }
            if ( localStorage.getItem('internalMenuLocal') ) {
                this.items  = JSON.parse(localStorage.getItem('internalMenuLocal'));
                if(this.personateActive){
                    this.items = this.items.filter(item => item.path != '/logout')
                }
                localStorage.setItem('internalMenu', JSON.stringify(this.items));
            } else {
                localStorage.setItem('internalMenu', JSON.stringify(this.items));
            }
            if ( this.items != null ) {
                this.items.find(a => {
                    if ( a.path ) {
                        if ( a.path[0] === '/' + String(pathString) ) {
                            this.activeItem = a.label;
                            localStorage.setItem('internalMenuActiveItem', JSON.stringify(this.activeItem));
                        }
                    } else if ( a.children && a.children.length > 0 ) {
                        for ( let i = 0; a.children[i]; i++ ) {
                            if ( '/' + a.children[i].action === '/' + String(pathString) ) {
                                this.activeItem = a.label
                                localStorage.setItem('internalMenuActiveItem', JSON.stringify(this.activeItem));
                            }
                        }
                    }
                });
            }
        }
    }

    onItemClick(index: number) {
        // if ( this.items[index] && this.items[index].label ) {
        //     this.title = this.items[index].label;
        //     this.activeItem = this.items[index].label;
        // }
    }

    onItemProjectClick(index: number)
    {
        this.updatePageIndex(index);
        // this.activeProjectItem = this.itemsProject[index].label;
    }

    updatePageIndex(index: number) {
        index = index + 1;
        this.newPageIndex.emit({index});
    }

    openNotificationsModalComponent(data: any) {
        const options: NgbModalOptions = {
          ariaLabelledBy: 'modal-basic-title',
          windowClass: 'position-modal-md',
          size: 'md',
          backdrop: 'static'
        }
        const modalRef = this.modalService.open(NotificationsModalComponent, options)

        // modalRef.componentInstance.lead = data
        // modalRef.componentInstance.kanban_lists = this.kanban_lists
        // modalRef.componentInstance.load()
        // modalRef.componentInstance.updateBoards.subscribe(( { update } ) => {
        //   if(update) this.NotificationsModalComponent()
        // })
    }


    loadNotifications() {
        this.notifications.items = [];
        const filter = {
          users_id: this.logged.user.id,
          read_status: 0
        }
        this.notificationService.getNotificationStatus(filter, 'Notifications', '-NotificationsStatus.id', 0, '', 9999).then((ret:any) => {
            this.notifications.items = ret.items;
            if ( this.notifications.items )
              this.notificationCounter = this.notifications.items.length;
        })
      }

    markAsRead(index) {
        this.notifications.items[index].data.read_status = 1;

        const to_update = {...this.notifications.items[index].data}

        delete(to_update.Notifications)
        delete(to_update.modified)

        this.notificationService.updateNotificationStatus(to_update).then(() => {
        this.loadNotifications()
        });
    }

    markAllAsRead() {
        this.notificationService.markAllAsRead({user_id: this.logged.user.id}).then(this.loadNotifications());
    }

    goTo(url) {
        this.router.navigate([url])
    }

    goToProfile() {
        this.translate.get('GENERAL.HELLO').subscribe(translated => {
            localStorage.setItem('title', JSON.stringify(translated + ' ' + this.logged.user.nickname));
            let localMenu = [
                {"label": "Dados Pessoais", "path": ["/users/profile"], "notification": 0},
            ];
            localStorage.setItem('internalMenuLocal', JSON.stringify(localMenu));
            localStorage.setItem('internalMenuActiveItem', JSON.stringify("Dados Pessoais"));
            this.router.navigate(['/users/profile'])
        });
    }
}