import { PaymentsMethodsService } from './../../services/payments_methods.service';
import { StoresImagesTypesService } from './../../services/store_images_types.service';
import { StoresImagesService } from './../../services/store_images.service';
import { ClassService } from './../../services/class.service';
import { CollegeService } from './../../services/college.service';
import { CollegesCoursesService } from './../../services/college_courses.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AccountPlanService } from 'src/app/services/account_plan.service';
import { CostCenterService } from 'src/app/services/cost_center.service';
import { ProviderService } from 'src/app/services/provider.service';
import { ReceivablesStatusService } from 'src/app/services/receivables_status.service';
import { BriefingService } from 'src/app/services/briefing.service';
import { ContractService } from 'src/app/services/contract.service';
import { UserService } from 'src/app/services/user.service';
import { StoresProductsCategoriesService } from 'src/app/services/store_products_categories.service';
import { CollegesClassesCommissionsService } from 'src/app/services/colleges_classes_commissions.service';
import { FinanceUnitsService } from 'src/app/services/finance_unit.service';
import { PayablesProcessStatusService } from 'src/app/services/payables_process_status.service';
import { CoursesService } from 'src/app/services/courses.service';


@Injectable()
export class FilterDataService {
  filters: any;
  
  constructor(
    private costCenterService: CostCenterService,
    private payablesProcessStatusService: PayablesProcessStatusService,
    private accountPlanService: AccountPlanService,
    private contract: ContractService,
    private receivablesStatusServices: ReceivablesStatusService,
    private providerService: ProviderService, 
    private CollegeCourse: CollegesCoursesService,
    private _route: Router,
    private briefing: BriefingService,
    private college: CollegeService, 
    private courses: CoursesService,
    private user: UserService,
    private storeProductCategory: StoresProductsCategoriesService,
    private collegeClassService: ClassService,
    private collegeClassesCommissions: CollegesClassesCommissionsService,
    private storeImage: StoresImagesService,
    private storeImageType: StoresImagesTypesService,
    private paymentMethod: PaymentsMethodsService,
    private financeUnits: FinanceUnitsService,
  ) { }

  async load(orSearch, filters) {
    let filtersLoad = {
      filters: await this.loadFilters(filters),
      config: await this.loadConfig(orSearch)
    }
    return filtersLoad;
  }

  loadConfig(orSearch: string = '') {
    let savedOrder        = [];
    let filterPage        = [];
    let filters           = [];
    let preFilters        = [];
    let preContains       = [];
    
    let pageDefaultOption = 25;
    let sort              = '';
    if ( localStorage.getItem('order.' + this._route.url) ) {
      savedOrder = JSON.parse(localStorage.getItem('order.' + this._route.url));
    }
    if ( localStorage.getItem('filter.' + this._route.url) ) {
      filters = JSON.parse(localStorage.getItem('filter.' + this._route.url));
    }
    if ( localStorage.getItem('toFilter.' + this._route.url) ) {
      preFilters = JSON.parse(localStorage.getItem('toFilter.' + this._route.url));
    }
    if ( localStorage.getItem('toContains.' + this._route.url) ) {
      const checkAddContains = JSON.parse(localStorage.getItem('toContains.' + this._route.url))
      if(checkAddContains.length > 0){
        checkAddContains.map((add: string) => {
          preContains.push(add)
        })
      }
    }
    if ( savedOrder.length > 0 ) {
      savedOrder.map(item => {
        let direction = '-';
        if ( item.sort == 'asc' )
          direction = '+';
        sort = direction + item.field + ';';
      })
    }

    if ( filters != null ) {
      filters.map(item => {
        let where = false;
        if ( item.type == 'text' ) { 
          filterPage.push({field: item.id, operator: 'like', value: '%' + item.value + '%'});
          where = true;
        }
        if ( item.type == 'money' ) {
          filterPage.push({field: item.id, operator: '>=', value: item.value_from}, {field: item.id, operator: '<=', value: item.value_to});
          where = true;
        }
        if ( item.type == 'date' ) {
          filterPage.push({field: item.id, operator: '>=', value: item.date_from}, {field: item.id, operator: '<=', value: item.date_to});
          where = true;
        }
        if ( item.type == 'select' ) {
          let inData  = [];

          item.options.map((checked: any) => {
            if ( checked.data.selected )
              inData.push(checked.data.id);
          })

          filterPage.push({field: item.id, operator: 'IN', value: inData.join(',')});

          where = true;
        }
        if ( item.type == 'search' ) {
          let fieldOr = orSearch;
          let checked = false;
          if ( filterPage.length > 0 ) {
            filterPage.map(check => {
              if ( check.field == fieldOr ) {
                check.value = item.value;
                checked = true;
              }
            })
          }
          if ( checked == false ) {
            filterPage.push({field: fieldOr, operator: 'OR', value: item.value});
          }
          where = true;
        }
        if ( !where ) { 
          filterPage.push({field: item.id, value: item.value});
        }
      });
    }
    
    if ( preFilters != null ) {
      preFilters.map((item, index) => {
        if ( item.id == 'Payables.origin_account_plan_id' )
          filterPage.push({field: 'Payables.origin_account_plan_id', operator: 'IN', value: item.value});
        else if ( item.id == 'Receivables.origin_account_plan_id' )
          filterPage.push({field: 'Receivables.origin_account_plan_id', operator: 'IN', value: item.value});
        else {
          if ( item.id == 'Payables.competence_date' ) {
            filterPage.push({field: 'Payables.competence_date', operator: '>=', value: item.date_from});
            filterPage.push({field: 'Payables.competence_date', operator: '<=', value: item.date_to});
          }
          if ( item.id == 'Payables.due_date' ) {
            filterPage.push({field: 'Payables.due_date', operator: '>=', value: item.date_from});
            filterPage.push({field: 'Payables.due_date', operator: '<=', value: item.date_to});
          }
          if ( item.id == 'Receivables.competence_date' ) {
            filterPage.push({field: 'Receivables.competence_date', operator: '>=', value: item.date_from});
            filterPage.push({field: 'Receivables.competence_date', operator: '<=', value: item.date_to});
          }
          if ( item.id == 'Receivables.due_date' ) {
            filterPage.push({field: 'Receivables.due_date', operator: '>=', value: item.date_from});
            filterPage.push({field: 'Receivables.due_date', operator: '<=', value: item.date_to});
          }
          if ( item.id == 'Payables.paid' ) {
            filterPage.push({field: 'Payables.paid', operator: 'IN', value: item.value});
          }
          if ( item.id == 'Receivables.receivables_status_id' ) {
            filterPage.push({field: 'Receivables.receivables_status_id', operator: 'IN', value: item.value});
          }
          if ( item.id == 'StoresSales.sale_type' ) {
            let filter = {field: 'StoresSales.sale_type', operator: 'IN', value: item.value}
            
            if(item.operator) filter.operator = item.operator

            filterPage.push(filter);
          }
        }
      })
    }

    if ( localStorage.getItem('byPage.' + this._route.url) )
        pageDefaultOption = +localStorage.getItem('byPage.' + this._route.url);
    
    let filterConfigData  = {
      savedOrder: savedOrder,
      filters: filters,
      filterPage: filterPage,
      pageDefaultOption: pageDefaultOption,
      sort: sort,
      addToContains: preContains
    }
    return filterConfigData;
  }

  async loadFilters(filters){
    await Promise.all(
      filters.map(async item => {
        let where = ( filters.condition ) ? filters.condition : []
        item.options = [];
        if ( item.contain == 'CostsCenters' ) {
          let data = await this.costCenterService.getCostsCenters(where, '', '+CostsCenters.name', 0, '', 99999);
          item.options  = data.items;
        }
        if( item.contain == 'PayablesProcessStatus' ) {
          let data = await this.payablesProcessStatusService.getPayablesProcessStatus(where, '', '', 0, '', 9999);
          item.options  = data.items;
        }
        if ( item.contain == 'ReceivablesStatus' ) {
          let data = await this.receivablesStatusServices.getReceivablesStatus(where, '', '+ReceivablesStatus.name', 0, '', 99999);
          item.options  = data.items;
        }
        if ( item.contain == 'AccountsPlans' || item.contain == 'FinanceAccountsPlansOrigin' ) {
          let data = await this.accountPlanService.getAccountsPlans(where, '', '+AccountsPlans.name', 0, '', 99999);
          item.options  = data.items;
          item.options.map((data, index) => {
            if ( data.data.id === 214 ) {
              data.selected = true;
            }
          })
        }
        if ( item.contain == 'Providers' ) {
          let data = await this.providerService.getProviders(where, '', '+Providers.name', 0, '', 99999);
          item.options  = data.items;
        }
        if ( item.contain == 'Briefings' ) {
          let data = await this.briefing.getBriefings(where, '', '', 0, '', 99999);
          item.options  = data.items;
        }
        if ( item.contain == 'Contracts' ) {
          let data = await this.contract.getContracts(where, '', '', 0, '', 99999);
          item.options  = data.items;
        }
        if ( item.contain == 'CollegesCourses' ) {
          let data = await this.CollegeCourse.getCollegeCourses(where, '', '+CollegesCourses.name', 0, '', 99999);
          item.options  = data.items;
        }
        if ( item.contain == 'Colleges') {
          let data = await this.college.getColleges(where, '', '+Colleges.name', 0, '', 99999);
          item.options  = data.items;
        }
        if (item.contain == 'CollegesClasses' ) {
          let data = await this.college.getColleges(where, '', '', 0, '', 99999);
          item.options  = data.items;
        }
        if (item.contain == 'Courses'){
          let data = await this.courses.getCourses(where, '', '+Courses.name', 0, '',  99999);
          item.options  = data.items;
        }
        if ( item.contain == 'CollegesClassesCommissions' ) {
          let data = await this.collegeClassesCommissions.getCollegesClassesCommissions(where, '', '', 0, '', 99999);
          item.options  = data.items;
        }
        if ( item.contain == 'Users' ) {
          let data = await this.user.getUsers(where, '', '+Users.name', 0, '', 99999);
          item.options  = data.items;
        }
        if ( item.contain == 'CollegesClasses' ) {
          where.push({field: 'CollegesClasses.archived', value: 'N'})
          let data = await this.collegeClassService.getClasses(where, '', '+CollegesClasses.name', 0, '', 99999);
          item.options  = data.items;
        }
        if ( item.contain == 'StoresProductsCategories' ) {
          let data = await this.storeProductCategory.getStoresProductsCategories(where, '', '', 0, '', 99999);
          item.options  = data.items;
        }
        if ( item.contain == 'FinanceUnits') {
          let data = await this.financeUnits.getFinanceUnits(where, '', '+FinanceUnits.name', 0, '', 9999);
          item.options  = data.items;
        }
        if ( item.contain == 'StoresImages' ) {
          let data = await this.storeImage.getStoresImages(where, '', '', 0, '', 99999);
          item.options  = data.items;
        }
        if ( item.contain == 'StoresImagesTypes' ) {
          let data = await this.storeImageType.getStoresImagesTypes(where, '', '', 0, '', 99999);
          item.options  = data.items;
        }
        if ( item.contain == 'PaymentsMethods' ) {
          let data = await this.paymentMethod.getPaymentsMethods(where, '', '+PaymentsMethods.name', 0, '', 99999);
          item.options  = data.items;
        }
        if ( item.contain == 'conclusion_period' ) {
          const options = []
          let filter_data = [{field: 'CollegesClasses.conclusion_period', operator: 'NOTNULL'}];
          
          const periods = await this.collegeClassService.getClasses(filter_data, '', '+CollegesClasses.conclusion_period', 0, '', 999999)
          periods.items.map(opt => {
            const checkOptInOptions = options.filter(item => item.data.name == opt.data.conclusion_period)

            if(!checkOptInOptions.length){
              options.push({data : {id: opt.data.conclusion_period, name: opt.data.conclusion_period, value: opt.data.conclusion_period}})
            }

          })
          item.options = options
        }
        return item;
      })
    );
    return filters;
  }
}

