import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { RestService } from './rest.service';
import { College } from '../model/college.model';

@Injectable({
  providedIn: 'root'
})
export class CollegeService extends RestService {
  private _url = 'Colleges';

  constructor(protected _http: HttpClient) {
    super(_http);
  }

  insertCollege(college: College): any {
    return this.postApi(this._url, college.toJson());
  }

  updateCollege(college: College): any {
    return this.putApi(this._url + '/' + college.id, college.toJson());
  }

  deleteCollege(id: number): any {
    return this.deleteApi(this._url + '/' + id, undefined, true);
  }

  updateField(id: number, field: any, value: any): any {
    let data = JSON.stringify({ id: id, field: field, value: value });
    return this.patchApi(this._url + '/' + id, data, undefined, true)
  }

  getColleges(filter_data: any, contain_string: string = '', order_string: string = '+Colleges.name', page: number = 0, fields: string = '', limit: number = 20, groupData: any = []): any {
    let qs: string = '';
    let paging: string = '';
    let group: string = '';

    filter_data?.map(item => {
      if (item.operator == null || item.operator == '') {
        qs += item.field + '|' + item.value + ';'
      } else {
        qs += item.field + '|' + item.operator + '|' + item.value + ';'
      }
    })


    if (groupData && groupData.length > 0) {
      groupData.map(item => {
        group += item.field + ';'
      })
    }
    let offset = (page * limit);
    paging = offset + ";" + limit;
    return this.getApi(this._url, { params: { query: qs, paging: paging, order: order_string, contains: contain_string, fields: fields, group: group } });
  }

  getCollege(id) {
    return this.getApi(this._url + '/' + id);
  }

}