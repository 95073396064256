import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RestService } from './rest.service';


@Injectable()
export class EventsService extends RestService {
  private _url = 'Events';

  constructor(protected _http: HttpClient) {
    super(_http);
  }

  insertEvent(data: any): any {
    const obj = {
      id: 123,
      name: data.name,
      status: data.status,
    }
    return this.postApi(this._url, obj);
  }

  updateEvent(Events: any): any {
    let data = {
      name: Events.name,
      status: Events.status,
    }
    return this.putApi(this._url + '/' + Events.id, data, undefined, true);
  }

  deleteItem(id: number): any {
    return this.deleteApi(this._url + '/' + id, undefined, true);
  }

  getEvents(filter_data: any, contains: string = '', order_string: string = '+Events.name', page: number = 0, fields: string = '', limit: number = 20): any {
    let qs = '';
    let paging = '';

    if (filter_data.id && filter_data.id != null) {
      qs += 'Events.id|' + filter_data.id + ';';
    }
    if (filter_data.name && filter_data.name != '') {
      qs += 'Events.name|like|' + filter_data.name + ';';
    }
    if (filter_data.status && filter_data.status != '') {
      qs += 'Events.status|' + filter_data.status + ';';
    }

    if (Array.isArray(filter_data)) {
      filter_data.map(item => {
        if (item.operator == null || item.operator == '') {
          qs += item.field + '|' + item.value + ';'
        } else {
          qs += item.field + '|' + item.operator + '|' + item.value + ';'
        }
      })
    }

    let offset = (page * limit);
    paging = offset + ";" + limit;
    return this.getApi(this._url, { params: { query: qs, paging: paging, order: order_string, contains: contains, fields: fields } });
  }

  getEvent(id, contain_string: string = '', fields: string = '') {
    return this.getApi(this._url + '/' + id, { params: { contains: contain_string, fields: fields } });
  }
}
