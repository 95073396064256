import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginLayoutComponent } from './_layouts/login-layout/login-layout.component';
import { LoginComponent } from './login/login.component';
import { MainLayoutComponent } from './_layouts/main-layout/main-layout.component';
import { ErrorComponent } from './error/error.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';

const routes: Routes = [
  {
    //Main Layout
    path : '',
    component: MainLayoutComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
      },
      {
        path: 'home',
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
      },
      {
        path: 'briefings',
        loadChildren: () => import('./briefings/briefings.module').then(m => m.BriefingsModule)
      },
      {
        path: 'contracts',
        loadChildren: () => import('./contracts/contracts.module').then(m => m.ContractsModule)
      },
      {
        path: 'contract-quota',
        loadChildren: () => import('./contracts-quotas/contracts-quotas.module').then(m => m.ContractsQuotasModule)
      },
      {
        path: 'companies',
        loadChildren: () => import('./companies/companies.module').then(m => m.CompaniesModule)
      },
      {
        path: 'colleges',
        loadChildren: () => import('./colleges/colleges.module').then(m => m.CollegesModule)
      },
      {
        path: 'classes',
        loadChildren: () => import('./classes/classes.module').then(m => m.ClassesModule)
      },
      {
        path: 'courses',
        loadChildren: () => import('./courses/courses.module').then(m => m.CoursesModule)
      },
      {
        path: 'banks',
        loadChildren: () => import('./banks/banks.module').then(m => m.BanksModule)
      },
      {
        path: 'banners',
        loadChildren: () => import('./banners/banners.module').then(m => m.BannersModule)
      },
      {
        path: 'accounts-plans',
        loadChildren: () => import('./accounts-plans/accounts-plans.module').then(m => m.AccountsPlansModule) 
      },
      {
        path: 'accounts-plans-groups',
        loadChildren: () => import('./accounts-plans-groups/accounts-plans-groups.module').then(m => m.AccountsPlansGroupsModule) 
      },
      {
        path: 'contracts-models', 
        loadChildren: () => import('./contracts-models/contracts-models.module').then(m => m.ContractsModelsModule)
      },
      {
        path: 'costs-centers',
        loadChildren: () => import('./costs-centers/costs-centers.module').then(m => m.CostsCentersModule)
      },
      {
        path: 'events',
        loadChildren: () => import('./events/events.module').then(m => m.EventsModule)
      },
      {
        path: 'finances-rates',
        loadChildren: () => import('./finances-rates/finances-rates.module').then(m => m.FinancesRatesModule)
      },
      {
        path: 'fonts',
        loadChildren: () => import('./fonts/fonts.module').then(m => m.FontsModule) 
      },
      {
        path: 'gateways',
        loadChildren: () => import('./gateways/gateways.module').then(m => m.GatewaysModule)
      },
      {
        path: 'groups',
        loadChildren: () => import('./groups/groups.module').then(m => m.GroupsModule)
      },
      {
        path: 'modules',
        loadChildren: () => import('./modules/modules.module').then(m => m.ModulesModule)
      },
      {
        path: 'mailmarketing',
        loadChildren: () => import('./mailmarketing/mailmarketing.module').then(m => m.MailmarketingModule)
      },
      {
        path: 'menus',
        loadChildren: () => import('./menus/menus.module').then(m => m.MenusModule) 
      },
      {
        path: 'p',
        loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule) 
      },
      {
        path: 'products-categories',
        loadChildren: () => import('./products-categories/products-categories.module').then(m => m.ProductsCategoriesModule)
      },
      {
        path: 'notifications',
        loadChildren: () => import('./notifications/notifications.module').then(m => m.NotificationsModule)
      },
      {
        path: 'notifications-types',
        loadChildren: () => import('./notifications-types/notifications-types.module').then(m => m.NotificationsTypesModule)
      },
      {
        path: 'packages',
        loadChildren: () => import('./packages/packages.module').then(m => m.PackagesModule)
      },
      {
        path: 'payables',
        loadChildren: () => import('./payables/payables.module').then(m => m.PayablesModule)
      },
      {
        path: 'payments-methods',
        loadChildren: () => import('./payments-methods/payments-methods.module').then(m => m.PaymentsMethodsModule)
      },
      {
        path: 'products',
        loadChildren: () => import('./products/products.module').then(m => m.ProductsModule)
      },
      {
        path: 'providers',
        loadChildren: () => import('./providers/providers.module').then(m => m.ProvidersModule)
      },
      {
        path: 'receivables',
        loadChildren: () => import('./receivables/receivables.module').then(m => m.ReceivablesModule)
      },
      {
        path: 'receivables-logs',
        loadChildren: () => import('./receivables-logs/receivables-logs.module').then(m => m.ReceivablesLogsModule)
      },
      {
        path: 'researches', 
        loadChildren: () => import('./researches/researches.module').then(m => m.ResearchesModule) 
      },
      {
        path: 'units',
        loadChildren: () => import('./units/units.module').then(m => m.UnitsModule) 
      },
      {
        path: 'reports',
        loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule) 
      },
      {
        path: 'users',
        loadChildren: () => import('./users/users.module').then(m => m.UsersModule) 
      },
      {
        path: 'variables',
        loadChildren: () => import('./variables/variables.module').then(m => m.VariablesModule)
      },
      {
        path: 'sellers',
        loadChildren: () => import('./sellers/sellers.module').then(m => m.SellersModule)
      },
      {
        path: 'schedule',
        loadChildren: () => import('./schedule/schedule.module').then(m => m.ScheduleModule)
      },
      {
        path: 'goals',
        loadChildren: () => import('./goals/goals.module').then(m => m.GoalsModule)
      },
      {
        path: 'store',
        loadChildren: () => import('./store/store.module').then(m => m.StoreModule)
      },
      {
        path: 'leads',
        loadChildren: () => import('./leads/leads.module').then(m => m.LeadsModule)
      },
      {
        path: 'leads-photo',
        loadChildren: () => import('./leads-photo/leads-photo.module').then(m => m.LeadsPhotoModule)
      },
      {
        path: 'operations',
        loadChildren: () => import('./operations/operations.module').then(m => m.OperationsModule)
      },
      {
        path: 'partners-categories',
        loadChildren: () => import('./partners/partners-categories/partners-categories.module').then(m => m.PartnersCategoriesModule)
      },
      {
        path: 'partners-establishments',
        loadChildren: () => import('./partners/partners-establishments/partners-establishments.module').then(m => m.PartnersEstablishmentsModule)
      },
      {
        path: 'partners',
        loadChildren: () => import('./partners/partners/partners.module').then(m => m.PartnersModule)
      },
    ]
  },

  {
    path: 'quests', 
    loadChildren: () => import('./quests/quests.module').then(m => m.QuestsModule) 
  },
  {
    path: 'fornecedor', 
    loadChildren: () => import('./register-providers/register-providers.module').then(m => m.RegisterProvidersModule) 
  },
  {
    //Login Layout
    path : '',
    component: LoginLayoutComponent,
    children: [
      { path: 'login', component: LoginComponent},
      { path: 'logout', component: LoginComponent},
      { path: 'change-password', component: ChangePasswordComponent},
      { path: 'forgot', component: ForgotPasswordComponent, data: { title: 'GENERAL.FORGOT' } },
    ]
  },  
  { path: '**', component: ErrorComponent, data: { title: 'Erro' } },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
