import { Component, Input } from '@angular/core';
import { Globals } from 'src/globals';

@Component({
  selector: 'icon-balloons',
  template: `
    <svg width="44" height="50" viewBox="0 0 44 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0)">
        <path d="M37.5378 50C37.0414 50 36.5755 49.7977 36.2514 49.4438L30.9236 43.7095H17.6648C15.1731 43.7095 13.1473 41.6869 13.1473 39.199V34.6582L9.1768 38.9361C8.82228 39.3204 8.32597 39.5429 7.80939 39.5429C7.55617 39.5429 7.33333 39.5024 7.1105 39.4114C6.39135 39.1282 5.93554 38.4405 5.93554 37.6618V31.6646H5.34807C2.40055 31.6646 0 29.2678 0 26.3248V5.28924C0.0202578 2.37662 2.42081 0 5.34807 0H32.7164C35.6436 0 38.0442 2.37662 38.0645 5.29935V25.1618H39.4622C40.698 25.1618 41.8223 25.627 42.6832 26.4765C43.5341 27.3159 44.0101 28.4891 44.0101 29.6824V39.2698C44 41.7172 42.0046 43.7298 39.5534 43.7601H39.2698V48.301C39.2698 49.019 38.8241 49.6663 38.1556 49.9191C37.9834 49.9798 37.8011 50.0101 37.6087 50.0101L37.5378 50ZM36.8389 46.6121V41.3532H39.523C40.6777 41.3228 41.5792 40.4025 41.5893 39.2597V29.6723C41.5792 28.5194 40.6372 27.5789 39.4825 27.5789H37.9024L37.8416 27.7811C37.8112 27.8823 37.7707 27.9834 37.7302 28.0744L37.6289 28.3374C37.6087 28.3879 37.5884 28.4385 37.5681 28.4891C37.4365 28.7824 37.2845 29.0554 37.1225 29.2981C36.9402 29.5712 36.7274 29.824 36.4945 30.0566C36.4641 30.087 36.3223 30.2083 36.3223 30.2083C36.1501 30.3701 35.9678 30.5218 35.7753 30.6533C35.7044 30.7039 35.6335 30.7443 35.5626 30.7848C35.3195 30.9365 35.1372 31.0376 34.9448 31.1185C34.8333 31.1691 34.732 31.2197 34.6206 31.2601C34.418 31.341 34.2155 31.4017 34.0028 31.4523L33.8913 31.4826C33.8204 31.5028 33.7597 31.5231 33.6888 31.5332C33.3545 31.5939 33.0405 31.6343 32.7366 31.6343H15.9227L15.5479 32.0793V39.2091C15.5479 40.3722 16.4899 41.3127 17.6547 41.3127H31.9669L36.8389 46.6121ZM8.34622 36.3167L13.1473 31.1489V31.1084L14.8996 29.2071H32.7063C34.3066 29.2071 35.593 27.9632 35.6538 26.3653V5.28924C35.6234 3.69134 34.3066 2.39684 32.7164 2.39684H5.34807C3.7477 2.39684 2.43094 3.69134 2.41068 5.28924V26.3248C2.41068 27.943 3.72744 29.2678 5.34807 29.2678H8.34622V36.3167Z" fill="#E5E5EF"/>
        <path d="M17.8876 21.8042V20.712C17.9787 20.2771 17.604 20.1254 17.452 20.0748C16.7633 19.8726 16.1859 19.4883 15.7706 18.9523C15.5174 18.6286 15.3553 18.2747 15.3047 17.9005H17.7052C17.9686 18.4061 18.475 18.7197 19.0524 18.7197C19.3968 18.7197 19.7209 18.5983 20.0045 18.3758C20.2577 18.1735 20.4097 17.9106 20.4097 17.6274C20.4198 17.3442 20.2881 17.0712 20.0551 16.8588C19.7614 16.6161 19.4069 16.4947 19.0321 16.4947C18.1408 16.4442 17.4824 16.2925 16.9051 15.9891C16.0745 15.5239 15.6086 15.0081 15.4161 14.3204C15.1528 13.3697 15.4971 12.4595 16.3581 11.8224C16.6113 11.6201 16.9051 11.4887 17.2191 11.3471C17.3406 11.2965 17.4621 11.2358 17.5736 11.1752C17.8065 11.0639 17.9382 10.9324 17.9382 10.629V9.90088H20.2577V10.6998C20.2577 10.983 20.3691 11.1347 20.6122 11.2156C21.22 11.4179 21.7163 11.7314 22.1316 12.1865C22.4962 12.5809 22.6887 12.9854 22.7494 13.4304H20.3489C20.3286 13.3192 20.2679 13.218 20.197 13.1371C20.0045 12.8236 19.5284 12.591 19.0119 12.591C18.5156 12.591 18.08 12.7933 17.847 13.127C17.5837 13.5214 17.6343 14.0473 17.9585 14.3709C18.2421 14.6541 18.6168 14.7856 19.1233 14.7856C20.1058 14.7856 20.9971 15.0789 21.7568 15.6452C22.5165 16.2419 22.8406 16.9701 22.7393 17.8499C22.6785 18.3556 22.4051 18.8107 21.8682 19.3062C21.534 19.5995 21.1187 19.8422 20.6325 20.004C20.3691 20.0647 20.2273 20.2569 20.2273 20.5603V21.784H17.8876V21.8042Z" [attr.fill]="color"/>
        </g>
        <defs>
        <clipPath id="clip0">
        <rect width="44" height="50" fill="white"/>
        </clipPath>
        </defs>
    </svg>

  `,
  providers: [ Globals ]
})
export class IconBalloonsComponent {

  @Input() color: string;

  constructor(public globals: Globals) {
    this.color = this.color || globals.colors.lightBlue;
  }

}
