import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'internal-menu',
  templateUrl: './internal-menu.component.html',
  styleUrls: ['./internal-menu.component.scss']
})

export class InternalMenuComponent {

  @Input() items: any = [];

  @Output() valueChanged: EventEmitter<string> = new EventEmitter();
  @Output() inputClear: EventEmitter<string> = new EventEmitter();

  constructor() { }

  onInput() {
    this.valueChanged.emit(this.items);
  }
  clear(){
    this.inputClear.emit();
  }

  handleInput(value){
  }

}
