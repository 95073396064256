import {Pipe} from '@angular/core';

@Pipe({
    name: 'currencyK'
})
export class CurrencyKFormat {
    transform(value: number,
        currencySign: string = 'R$ ',
        decimalLength: number = 2, 
        chunkDelimiter: string = '.', 
        decimalDelimiter:string = ',',
        chunkLength: number = 3): string {

        if ( value != null ) {           

            return currencySign + this.kFormatter(value)
        }
    }

    kFormatter(num:any) {
      return Math.abs(num) > 999 ? Math.sign(num) * ( Math.round(Math.abs(num)/100)/10 ) + 'k' : Math.sign(num)*Math.abs(num)
  }
}