import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { RestService } from './rest.service';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceService extends RestService {
  private _url = 'Portal';
  
  constructor(protected _http: HttpClient) {
    super(_http);
  }

  checkStatus(){
    return this.getApi(this._url + '/checkStatus');
  }

}