

export class UserSocialLogin {

  id:number
  platform:string
  user_id:number
  account:string
  account_id: string|number
  account_token:string
  photo_url:string


  constructor(
    id:number = null,
    platform:string = null,
    user_id:number = null,
    account:string = null,
    account_id: string|number = null,
    account_token:string = null,
    photo_url:string = null
  
  ){
    this.id = id
    this.platform = platform
    this.user_id = user_id
    this.account = account
    this.account_id = account_id
    this.account_token = account_token
    this.photo_url = photo_url
  }

}