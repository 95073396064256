import { Component, OnInit } from '@angular/core';
import { Validation } from 'src/app/model/validation.model';
import { GeneralComponent } from 'src/app/_utils/general/general.component';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

    validation: Validation = new Validation();
    new_password: any = '';
    loading: boolean = false;
    year: number;
    numberBg: string = '1';

    constructor(
        private general: GeneralComponent,
        private router: Router,
        private userService: UserService
    ) { }

    ngOnInit() {
        let date        = new Date();
        this.year       = date.getFullYear();
        this.numberBg   = localStorage.getItem('bg-login');
    }

    updatePass() {
        this.validate(); 
        if ( this.validation.valid ) {
            this.loading = true;
            let auth    = JSON.parse(localStorage.getItem('auth'));
            this.userService.changePassword(auth.user.id, this.new_password).then((ret:any) => {
                if(ret.summary.process == true) {
                    this.userService.updateField(auth.user.id, 'change_password', 'N').then(res => {
                        if ( res.summary.process == true ) {
                            this.loading = false;
                            auth.user.change_password   = 'N';
                            localStorage.setItem('auth', JSON.stringify(auth));
                            this.general.setMessage('CHANGE-PASSWORD.CHANGED', 'success');
                            this.router.navigate(['/']);
                        } else {
                            res.summary.errors.map((data:any) => {
                                this.general.apiErrors(data.code);
                            });
                            this.loading = false;        
                        }
                    });
                }else{
                    ret.summary.errors.map((data:any) => {
                        this.general.apiErrors(data.code);
                    });
                    this.loading = false;
                }
            }); 
        } else {
            this.general.setMessage(this.validation.messages);
        }
    }
 
    validate() {
        this.validation.fields      = [];
        this.validation.messages    = [];

        if ( this.new_password && this.new_password != null ) {
            if ( this.new_password.length < 8 ) {
                this.validation.fields.push('new_password');
                this.validation.messages.push("ERROR.USER.PASSWORD_LENGTH");
            }
        } else {
            this.validation.fields.push('new_password');
            this.validation.messages.push("ERROR.USER.PASSWORD");
        }
        if ( this.validation.messages.length > 0 )      
            this.validation.valid   = false;
        else
            this.validation.valid   = true;
    
    }

}
