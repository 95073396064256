import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RestService } from './rest.service';

@Injectable()
export class DepartmentService extends RestService {
    private _url = 'Departments';    
    
	constructor(protected _http: HttpClient) {
		super(_http);
    } 
    
    insertDepartment(data: any) : any {
        return this.postApi(this._url, data);
    }
    
    updateDepartment(obj: any) : any {
        let data    = JSON.stringify(obj);
        return this.putApi(this._url + '/' + obj.id, data);
    }
    
    getDepartments(filter_data: any, contains: string = '', order_string: string = '-Departments.id', page: number = 0, fields: string = '', limit: number = 20): any {
        let qs = '';
        let paging  = '';

        filter_data.map(item => {
            if(item.operator == null || item.operator == ''){
              qs += item.field + '|' + item.value + ';'
            }else{
              qs += item.field + '|' + item.operator + '|' + item.value + ';'
            }
        })

        let offset  = (page * limit);
        paging      = offset + ";" + limit;
        return this.getApi(this._url, {params: {query: qs, paging: paging, order: order_string, contains: contains, fields: fields}});
    }
    

    getDepartment(id: number): any {
        return this.getApi(this._url + '/' + id);
    }

}