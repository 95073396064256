import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { RestService } from './rest.service';

@Injectable({
  providedIn: 'root'
})
export class BriefingService extends RestService {
  private _url = 'Briefings';
  private _duplicateBriefingUrl = 'Briefings/duplicate';
  private _downloadBriefingUrl = 'Briefings/download';
  private _createBriefingUrl = 'Briefings/create';
  private _updateBriefingUrl = 'Briefings/update';
  
  constructor(protected _http: HttpClient) {
    super(_http);
  }

  insertBriefing(briefing: any): any {
    let data = JSON.stringify(briefing);
    return this.postApi(this._url, data);
  }

  updateBriefing(briefing: any): any {
    let data = JSON.stringify(briefing);
    return this.putApi(this._url + '/' + briefing.id, data);
  }

  deleteBriefing(id: number): any {
    return this.deleteApi(this._url + '/' + id);
  }

  updateField(id: number, field: any, value: any): any {
    let data = JSON.stringify({ id: id, field: field, value: value });
    return this.patchApi(this._url + '/' + id, data);
  }

  getBriefings(filter_data: any, contain_string: string = '', order_string: string = '+Briefings.created', page: number = 0, fields: string = '', limit: number = 20, groupData: any = []): any {
    let qs: string = '';
    let paging: string = '';

    if (filter_data.id && filter_data.id != null) {
      qs += 'Briefings.id|' + filter_data.id + ';';
    }
    if (filter_data.name && filter_data.name != '') {
      qs += 'Briefings.name|like|' + filter_data.name + ';';
    }
    if (filter_data.CollegesClassesName && filter_data.CollegesClassesName != '') {
      qs += 'CollegesClasses.name|like|' + filter_data.CollegesClassesName + ';';
    }
    if (filter_data.CollegesClassesArchived && filter_data.CollegesClassesArchived != '') {
      qs += 'CollegesClasses.archived|' + filter_data.CollegesClassesArchived + ';';
    }
    if (filter_data.briefings_status_id && filter_data.briefings_status_id != '') {
      qs += 'Briefings.briefings_status_id|' + filter_data.briefings_status_id + ';';
    }
    if (filter_data.status && filter_data.status != '') {
      qs += 'Briefings.status|' + filter_data.status + ';';
    }
    if (filter_data.version && filter_data.version != '') {
      qs += 'Briefings.version|=|' + filter_data.version + ';';
    }
    if (filter_data.validUntil && filter_data.validUntil != '') {
      qs += 'Briefings.valid_until|>=|' + filter_data.validUntil + ';';
    }

    if(Array.isArray(filter_data)){
      filter_data.map(item => {
          if(item.operator == null || item.operator == ''){
          qs += item.field + '|' + item.value + ';'
          }else{
          qs += item.field + '|' + item.operator + '|' + item.value + ';'
          }
      })
    }

    let offset = (page * limit);
    paging = offset + ";" + limit;
    return this.getApi(this._url, { params: { query: qs, paging: paging, order: order_string, contains: contain_string, fields: fields } });
  }
  
  getBriefing(id){
    return this.getApi(this._url + '/' + id);
  }

  duplicateBriefing(briefing_id){
    let data = JSON.stringify({briefing_id: briefing_id});
    return this.postApi(this._duplicateBriefingUrl, data);   
  }

  async downloadBriefing(briefing_id, format = 'reduced'){
    let data = JSON.stringify({briefing_id: briefing_id, format: format});
    return this.postApi(this._downloadBriefingUrl, data);   
  }

  createBriefing(briefing: any): any {
    let data = JSON.stringify(briefing);
    return this.postApi(this._createBriefingUrl, data);
  }

  editBriefing(briefing: any): any {
    let data = JSON.stringify(briefing);
    return this.putApi(this._updateBriefingUrl, data);
  }

}