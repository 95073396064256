<div id="custom-component-modal" class="mt-5 pt-5">
  <div class="custom-component-modal-header">
      <p class="title-modal white">{{ 'REPORTS.CASH-FLOW.SURE' | translate }}</p>
      <a (click)="activeModal.dismiss()" style="font-size: 30px;">&times;</a>
  </div>
  <div class="custom-component-modal-content d-flex flex-column justify-content-center">
    <div class="confirm-title text-center">
      {{ 'REPORTS.CASH-FLOW.SURE-TEXT' | translate }}
    </div>     
    <div class="custom-component-modal-footer d-flex justify-content-center align-items-center mt-5">
      <button type="button" class="cancel-button-modal mr-3" (click)="activeModal.dismiss()">{{ 'CHANGE-STATUS.CANCEL' | translate }}</button>
      <button type="submit" class="save-button-modal" (click)="exec()">{{ 'CHANGE-STATUS.CONFIRM' | translate }}</button>
    </div>
  </div>
</div>