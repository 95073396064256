import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask'; 
import { LoadingComponent } from './_utils/loading/loading.component';
import { BreadcrumbComponent } from './_utils/breadcrumb/breadcrumb.component';
import { KeysPipe } from './_utils/pipes/key-pipe';
import { PaginationComponent } from './_utils/pagination/pagination.component';
import { NgxCurrencyModule } from "ngx-currency";
import { CurrencyFormat } from './_utils/pipes/currency-pipe';
import { CurrencyKFormat } from './_utils/pipes/currency-k.pipe';
import { GoogleChartsModule } from 'angular-google-charts';
import { ConfirmationDialogComponent } from './_utils/confirmation/confirmation-dialog.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NestableModule } from 'ngx-nestable';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { NgbModule, NgbNavModule, NgbTooltipModule, NgbDropdownModule, NgbDatepicker, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { InternalMenuComponent } from './_layouts/main-layout/internal-menu/internal-menu.component';
import { InitialsPipe } from './_utils/pipes/initials.pipe';
import { AvatarComponent } from './_utils/avatar/avatar.component';
import { SearchUserComponent } from './_utils/search-user/search-user.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import { BillingSummaryByProductComponent } from './_utils/graphs/billing-summary-by-product/billing-summary-by-product.component';
import { SalesFunnelCardComponent } from './_utils/graphs/sales-funnel-card/sales-funnel-card.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { CrmUseCardComponent } from './_utils/graphs/crm-use-card/crm-use-card.component';
import { PhonePipe } from './_utils/pipes/phone.pipe';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { ParameterizePipe } from './_utils/pipes/parameterize.pipe';
import { IconFolderComponent } from './_utils/icons/folder.component';
import { IconBalloonsComponent } from './_utils/icons/balloons.component';
import { IconFunnelComponent } from './_utils/icons/funnel.component';
import { PercentFormat } from './_utils/pipes/percent-pipe';
import { DragDropDirective } from './_utils/directives/drag-drop-directive';
import { MaintenanceComponent } from './_utils/maintenance/maintenance.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { ListSellersComponent } from './_utils/list-sellers/list-sellers.component';
import { DateDescriptionFormat } from './_utils/pipes/date-description.pipe';
import { FilterComponent } from './_utils/filter/filter.component';
import { StringDatePipe } from './_utils/pipes/string-date.pipe';
import { CodeInputModule } from 'angular-code-input';
import { LeadNotificationCardComponent } from './_utils/lead-notification-card/lead-notification-card.component';
import { ChangeStatusConfirmationComponent } from './_utils/change-status-confirmation/change-status-confirmation.component';
import { ConfirmPreviewFlowComponent } from './_utils/confirm-preview-flow/confirm-preview-flow.component';
import { ButtonComponent } from './_utils/button/button.component';
import { CardComponent } from './_utils/card/card.component';
import { RoundProgressComponent } from './_utils/chart-component/round-progress.component';
import { RoundProgressModule } from './_utils/chart-component/round-progress.module';
import { RoundPipe } from './_utils/pipes/round.pipe';
import { NgxMaskDirective } from 'ngx-mask';

// FullCalendarModule.registerPlugins([ // register FullCalendar plugins
//   dayGridPlugin,
//   interactionPlugin,
//   timeGridPlugin
// ]);

@NgModule({
    declarations: [
        KeysPipe,
        InitialsPipe,
        BreadcrumbComponent,
        AvatarComponent,
        SearchUserComponent,
        LoadingComponent,
        MaintenanceComponent,
        DateDescriptionFormat,
        ListSellersComponent,
        PaginationComponent,
        FilterComponent,
        CurrencyFormat,
        PercentFormat,
        IconFolderComponent,
        IconBalloonsComponent,
        IconFunnelComponent,
        CurrencyKFormat,
        ParameterizePipe,
        ConfirmationDialogComponent,
        InternalMenuComponent,
        SalesFunnelCardComponent,
        BillingSummaryByProductComponent,
        CrmUseCardComponent,
        PhonePipe,
        StringDatePipe,
        DragDropDirective,
        LeadNotificationCardComponent,
        ChangeStatusConfirmationComponent,
        ConfirmPreviewFlowComponent,
        ButtonComponent,
        CardComponent,
        RoundPipe
    ],
    imports: [
        NgxMaskDirective,
        FontAwesomeModule,
        CommonModule,
        RoundProgressModule,
        TranslateModule,
        RouterModule,
        FormsModule,
        NgxJsonViewerModule,
        TextMaskModule,
        ReactiveFormsModule,
        FullCalendarModule,
        AngularEditorModule,
        GoogleChartsModule,
        NestableModule,
        NgbModule,
        NgbNavModule,
        NgbTooltipModule,
        NgbDropdownModule,
        NgbDatepickerModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        NgxChartsModule,
        DragDropModule,
        CodeInputModule,
        AngularMultiSelectModule,
    ],
    exports: [
        NgxMaskDirective,
        RoundProgressModule,
        FontAwesomeModule,
        BreadcrumbComponent,
        AvatarComponent,
        SearchUserComponent,
        InternalMenuComponent,
        LoadingComponent,
        MaintenanceComponent,
        TranslateModule,
        FullCalendarModule,
        RouterModule,
        FormsModule,
        LoadingComponent,
        PaginationComponent,
        IconFolderComponent,
        IconBalloonsComponent,
        IconFunnelComponent,
        NgxJsonViewerModule,
        TextMaskModule,
        NgxCurrencyModule,
        CurrencyFormat,
        FilterComponent,
        PercentFormat,
        ParameterizePipe,
        LeadNotificationCardComponent,
        CurrencyKFormat,
        KeysPipe,
        InitialsPipe,
        ReactiveFormsModule,
        GoogleChartsModule,
        NestableModule,
        AngularEditorModule,
        NgbNavModule,
        NgbTooltipModule,
        NgbDropdownModule,
        NgbDatepickerModule,
        ListSellersComponent,
        DateDescriptionFormat,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        SalesFunnelCardComponent,
        BillingSummaryByProductComponent,
        CrmUseCardComponent,
        CodeInputModule,
        PhonePipe,
        RoundPipe,
        StringDatePipe,
        DragDropModule,
        NgxChartsModule,
        ButtonComponent,
        DragDropDirective,
        AngularMultiSelectModule,
        CardComponent
    ]
})
export class SharedModule { 
  // get customButtonClasses(){
  //     return CustomButtonClasses
  // }
}
