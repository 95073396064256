import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { RestService } from './rest.service';

@Injectable({
  providedIn: 'root'
})
export class CostCenterService extends RestService {
  private _url = 'CostsCenters';
  
  constructor(protected _http: HttpClient) {
    super(_http);
  }

  insertCostCenter(bank: any): any {
    let data = JSON.stringify(bank);
    return this.postApi(this._url, data);
  }

  updateCostCenter(bank: any): any {
    let data = JSON.stringify(bank);
    return this.putApi(this._url + '/' + bank.id, data);
  }

  deleteCostCenter(id: number): any {
    return this.deleteApi(this._url + '/' + id);
  }

  updateField(id: number, field: any, value: any): any {
    let data = JSON.stringify({ id: id, field: field, value: value });
    return this.patchApi(this._url + '/' + id, data);
  }

  getCostsCenters(filter_data: any, contain_string: string = '', order_string: string = '+CostsCenters.name', page: number = 0, fields: string = '', limit: number = 20): any {
    let qs: string = '';
    let paging: string = '';

    if (filter_data.id && filter_data.id != null) {
      qs += 'CostsCenters.id|' + filter_data.id + ';';
    }
    if (filter_data.notId && filter_data.notId != null) {
      qs += 'CostsCenters.id|!=|' + filter_data.notId + ';';
    }
    if (filter_data.name && filter_data.name != '') {
      qs += 'CostsCenters.name|like|' + filter_data.name + ';';
    }
    if (filter_data.eqName && filter_data.eqName != '') {
      qs += 'CostsCenters.name|' + filter_data.eqName + ';';
    }
    if (filter_data.status && filter_data.status != '') {
      qs += 'CostsCenters.status|' + filter_data.status + ';';
    }
    if (filter_data.code && filter_data.code != '') {
      qs += 'CostsCenters.code|like|' + filter_data.code + ';';
    }
    if (filter_data.classification && filter_data.classification != '') {
      qs += 'CostsCenters.classification|' + filter_data.classification + ';';
    }

    if(Array.isArray(filter_data)){
      filter_data.map(item => {
        if(item.operator == null || item.operator == ''){
          qs += item.field + '|' + item.value + ';'
        }else{
          qs += item.field + '|' + item.operator + '|' + item.value + ';'
        }
      })
    }
    
    let offset = (page * limit);
    paging = offset + ";" + limit;
    return this.getApi(this._url, { params: { query: qs, paging: paging, order: order_string, contains: contain_string, fields: fields } });
  }
  
  getCostCenter(id){
    return this.getApi(this._url + '/' + id);
  }

}