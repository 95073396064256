<div *ngIf="loading && type == 'entire'" class="loading-background">
	<div class="gooey">
		<span class="dot"></span>
		<div class="dots">
			<span></span>
			<span></span>
			<span></span>
		</div>
	</div>
	<p>{{ 'LOADING.LOADING' | translate }}</p>
</div> 

<div *ngIf="loading && type == 'internal'" class="loading-background-internal">
	<div class="gooey">
		<span class="dot"></span>
		<div class="dots">
			<span></span>
			<span></span>
			<span></span>
		</div>
	</div>
	<p>{{ 'LOADING.LOADING' | translate }}</p>
</div> 