import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { RestService } from './rest.service';

@Injectable({
  providedIn: 'root'
})
export class GoalService extends RestService {
  private _url = 'Goals';
  
  constructor(protected _http: HttpClient) {
    super(_http);
  }

  insertGoal(objectData: any): any {
    let data = JSON.stringify(objectData);
    return this.postApi(this._url, data);
  }

  updateGoal(objectData: any): any {
    let data = JSON.stringify(objectData);
    return this.putApi(this._url + '/' + objectData.id, data);
  }

  deleteGoal(id: number): any {
    return this.deleteApi(this._url + '/' + id);
  }

  updateField(id: number, field: any, value: any): any {
    let data = JSON.stringify({ id: id, field: field, value: value });
    return this.patchApi(this._url + '/' + id, data);
  }

  getGoals(filter_data: any, contain_string: string = '', order_string: string = '+Goals.name', page: number = 0, fields: string = '', limit: number = 20, groupData: any = []): any {
    let qs: string = '';
    let paging: string = '';
    let group: string = '';

    if ( filter_data.length > 0 ) {
      filter_data.map(item => {
        if(item.operator == null || item.operator == ''){
          qs += item.field + '|' + item.value + ';'
        }else{
          qs += item.field + '|' + item.operator + '|' + item.value + ';'
        }
      })
    }

    if(groupData && groupData.length > 0){
      groupData.map(item => {        
           group += item.field + ';'        
      })
    }
    let offset = (page * limit);
    paging = offset + ";" + limit;
    return this.getApi(this._url, { params: { query: qs, paging: paging, order: order_string, contains: contain_string, fields: fields, group: group } });
  }
  
  getGoal(id){
    return this.getApi(this._url + '/' + id);
  }

}