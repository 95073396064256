<nav class="d-none d-md-inline-block" aria-label="breadcrumb">
    <ol class="breadcrumb breadcrumb-custom pl-4 mt-4">
        <li class="disabled-breadcumb">
            <a routerLink="/">{{ 'GENERAL.HOME' | translate}}</a>
        </li>
        <li class="disabled-breadcumb">
            <i class="fa fa-chevron-right breadcrumb-arrow mx-2"></i>
        </li>
        <ng-container *ngFor="let item of items; let i = index">
            <li *ngIf="items.length != (i + 1)" [class.disabled-breadcumb]="items.length != (i + 1)">
                <a routerLink="{{item.link}}">{{item.name | translate}}</a>
                <i class="fa fa-chevron-right breadcrumb-arrow mx-2"></i>
            </li>
        </ng-container>
    </ol>
</nav>

<ng-conntainer *ngFor="let item of items; let i = index">
    <ol class="breadcrumb-custom mt-3 mt-md-0">
        <li *ngIf="items.length == (i + 1)" class="font-breadcrumb pl-4 list-unstyled" aria-current="page">{{item.name | translate}}</li>
    </ol>
</ng-conntainer>

<div [class.d-none]="router.url == '/users/notifications'" class="hr-2px"></div>