import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'breadcrumb',
    templateUrl: './breadcrumb.component.html'
})
export class BreadcrumbComponent implements OnInit {

    items: any;
    

    constructor(
        public router: Router,
        private route: ActivatedRoute,
    ){}
 
    ngOnInit() {
        this.setBreadCrumbs();
    }

    ngOnChanges() {
        this.setBreadCrumbs();
    }

    setBreadCrumbs(){
        this.items = this.route.snapshot.data['breadcrumbs'];
    }

}