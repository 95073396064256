import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-change-status-confirmation',
  templateUrl: './change-status-confirmation.component.html',
  styleUrls: ['./change-status-confirmation.component.scss']
})
export class ChangeStatusConfirmationComponent {

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  @Input()
  status: string
  @Output()
  modalCallback: EventEmitter<boolean> = new EventEmitter();

  exec(){
    this.activeModal.close()
    this.modalCallback.emit(true)
  }
}
