import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { RestService } from './rest.service';

@Injectable({
  providedIn: 'root'
})
export class ProviderService extends RestService {
  private _url = 'Providers';
  
  constructor(protected _http: HttpClient) {
    super(_http);
  }

  insertProvider(provider: any): any {
    provider.cpfcnpj  = provider.cpfcnpj.replace("/", "").replace(".", "").replace("-", "").replace(".", "").replace(".", "");
    let data = JSON.stringify(provider);
    return this.postApi(this._url, data);
  }

  updateProvider(provider: any): any {
    provider.cpfcnpj  = provider.cpfcnpj.replace("/", "").replace(".", "").replace("-", "").replace(".", "").replace(".", "");
    let data = JSON.stringify(provider);
    return this.putApi(this._url + '/' + provider.id, data);
  }

  deleteProvider(id: number): any {
    return this.deleteApi(this._url + '/' + id, undefined, true);
  }

  updateField(id: number, field: any, value: any): any {
    if ( field == 'cpfcnpj' )
      value  = value.replace("/", "").replace(".", "").replace("-", "").replace(".", "").replace(".", "");

    let data = JSON.stringify({ id: id, field: field, value: value });
    return this.patchApi(this._url + '/' + id, data, undefined, true)
  }

  getProviders(filter_data: any, contain_string: string = '', order_string: string = '+Providers.name', page: number = 0, fields: string = '', limit: number = 20): any {
    let qs: string = '';
    let paging: string = '';

    if (filter_data.id && filter_data.id != null) {
      qs += 'Providers.id|' + filter_data.id + ';';
    }
    if (filter_data.name && filter_data.name != '') {
      qs += 'Providers.name|like|' + filter_data.name + ';';
    }

    if(Array.isArray(filter_data)){
      filter_data.map(item => {
          if(item.operator == null || item.operator == ''){
          qs += item.field + '|' + item.value + ';'
          }else{
          qs += item.field + '|' + item.operator + '|' + item.value + ';'
          }
      })
    }

    let offset = (page * limit);
    paging = offset + ";" + limit;
    return this.getApi(this._url, { params: { query: qs, paging: paging, order: order_string, contains: contain_string, fields: fields } })
  }
  
  getProvider(id){
    return this.getApi(this._url + '/' + id);
  }

}