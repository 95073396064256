import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import * as FunnelGraph from 'funnel-graph-js';

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Globals } from 'src/globals';

@Component({
  selector: "sales-funnel-card",
  templateUrl: "./sales-funnel-card.component.html",
  providers: [Globals]
})

export class SalesFunnelCardComponent implements OnInit {

  products = ['Formatura', 'Fotografia', 'Convites'];
  colors: string[] = [];
  @ViewChild('graphFunnel') screen: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;

  constructor(public globals: Globals) { }

  ngOnInit() {
    for (let value of this.products) {
      for (let item of this.globals.productColor) {
        if (item.label === value) {
          this.colors.push(item.hex);
        }
      }
    }

    this.createFunnelGraph();
  }

  downloadPDF(){
    setTimeout(() => {  html2canvas(this.screen.nativeElement).then(canvas => {
      const imgData = canvas.toDataURL('image/jpeg', 1.0);
      const pdf = new jsPDF('l');
      pdf.addImage(imgData, 'JPEG', 40, 15, 217, 157);
      pdf.save('funnel-graph.pdf');
    }); }, 1);
  }

  createFunnelGraph() {
    const graph = new FunnelGraph({
      container: '.funnel',
      height: 224,
      gradientDirection: 'horizontal',
      data: {
        labels: ['Entrega do lead', 'Qualificação', 'Negociação', 'Aprovação', 'Fechamento'],
        subLabels: this.products,
        colors: this.colors,
        values: [
          [150, 130, 32],
          [125, 100, 25],
          [75, 50, 34],
          [40, 30, 11],
          [25, 17, 4]
        ]
      },
      displayPercent: true,
      subLabelValue: 'raw',
    });

    graph.draw();
  }

  createGraphOutsideOnInit(){
    if(document.getElementsByClassName('svg-funnel-js').length === 0) {
      this.createFunnelGraph();
    }
  }
}
