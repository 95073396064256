<nav id="custom-pagination" aria-label="navigation">
    <ul class="pagination pagination-rounded-separated m-0">
        <li class="">
            <a class="btn pt-2 font-pagination" [class.disabled]="!first" (click)="pageCommand('first')">{{ 'PAGINATION.FIRST' | translate }}</a>
        </li>
        <li class="">
            <a class="btn p-0" [class.disabled]="!prev" (click)="pageCommand('prev')" aria-label="Previous">
                <span class="size-icon" aria-hidden="true">&laquo;</span>
                <span class="sr-only">{{ 'PAGINATION.PREVIOUS' | translate }}</span>
            </a>
        </li>
        <li *ngFor="let page_item of pages; let i = index;" [class.addCmBtnFnActive]="(actualPage + 1 == page_item.name)">
            <a [class.addCmBtnFnActive]="(actualPage + 1 == page_item.name)" class="btn pt-2 font-pagination-n" *ngIf="i < number_of_pages" (click)="pageCommand(page_item.name)"> {{page_item.name}} </a>
        </li>
        <li>
            <a class="btn p-0" [class.disabled]="!next" (click)="pageCommand('next')" arial-label="Next">
                <span class="size-icon" aria-hidden="true">&raquo;</span>
                <span class="sr-only">{{ 'PAGINATION.NEXT' | translate }}</span>
            </a>
        </li>
        <li>
            <a class="btn pt-2 font-pagination" [class.disabled]="!last" (click)="pageCommand('last')">{{ 'PAGINATION.LAST' | translate }}</a>
        </li>
    </ul>
</nav>
<div class="ml2">
    <p class="custom-pagination-desc font-pagination-description" *ngIf="total > 1"> {{ 'PAGINATION.RECORDS-FOUND' | translate:{actualTotal: fromCount, a: toCount, total: total, page: (page + 1), number_of_pages: number_of_pages} }}</p>
    <p class="custom-pagination-desc font-pagination-description" *ngIf="total == 1"> {{ 'PAGINATION.RECORD-FOUND' | translate:{actualTotal: fromCount, total: total, page: (page + 1), number_of_pages: number_of_pages} }}</p>
</div>