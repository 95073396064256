import { Component, Input } from '@angular/core';
import { Globals } from 'src/globals';

@Component({
  selector: 'icon-folder',
  template: `
    <svg width="44" height="50" viewBox="0 0 44 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M35.6 6.78H24.78L22.8 2.81C21.94 1.09 20.17 0 18.24 0H1.69C0.76 0 0 0.76 0 1.7V37.84C0 38.29 0.18 38.72 0.5 39.04C0.82 39.36 1.25 39.54 1.7 39.54H35.6C36.05 39.54 36.48 39.36 36.8 39.04C37.12 38.72 37.3 38.29 37.3 37.84V8.48C37.3 8.03 37.12 7.6 36.8 7.28C36.48 6.96 36.05 6.78 35.6 6.78ZM3.39 3.39H18.24C18.89 3.39 19.48 3.75 19.77 4.32L20.99 6.78H3.39V3.39ZM33.9 36.14H3.39V10.17H33.9V36.14Z" fill="#E5E5EF"/>
        <path d="M34 30C39.52 30 44 34.48 44 40C44 45.52 39.52 50 34 50C28.48 50 24 45.52 24 40C24 34.48 28.48 30 34 30Z" [attr.fill]="color"/>
        <path d="M32.7402 41.4997V34.7297C32.7402 34.0397 33.3002 33.4697 34.0002 33.4697C34.7002 33.4697 35.2602 34.0297 35.2602 34.7297V41.4997C35.2602 42.1897 34.7002 42.7597 34.0002 42.7597C33.3002 42.7597 32.7402 42.1997 32.7402 41.4997Z" fill="white"/>
        <path d="M35.2601 45.2795C35.2601 45.7895 34.9501 46.2495 34.4801 46.4395C34.0101 46.6295 33.4701 46.5295 33.1101 46.1695C32.7501 45.8095 32.6401 45.2695 32.8401 44.7995C33.0301 44.3295 33.4901 44.0195 34.0001 44.0195C34.3301 44.0195 34.6501 44.1495 34.8901 44.3895C35.1301 44.6295 35.2601 44.9395 35.2601 45.2795Z" fill="white"/>
    </svg>
  `,
  providers: [ Globals ]
})
export class IconFolderComponent {

  @Input() color: string;

  constructor(public globals: Globals) {
    this.color = this.color || globals.colors.lightBlue;
  }

}
