<div class="searchUser row">
    <div class="p-3" [class.col-8]="teams" [class.col-12]="!teams">
        <h5 class="mb-0">{{'TASK.COLABORATORS' | translate}}</h5>
        <div *ngFor="let user of users" class="getUser" style="cursor: pointer" (click)="selectUser(user.data)">
          <ng-container *ngIf="multiple == true">
            <div class="input-check-round" [class.checked]="selected[user.data.users_id]">
              <span class="fa fa-check"></span>
            </div>
          </ng-container>
          <avatar [size]="'size-1x'" [color]="user.data.user.avatar_color" [name]="user.data.user.name"></avatar>
          {{user.data.user.name}}
        </div>
    </div>
    <div class="col-4 bl-1 p-0 text-center list-team" *ngIf="teams">
        <h6 class="text-center p-2">{{'TASK.TEAMS' | translate}}</h6>
        <ul class="list-unstyled">
          <li *ngFor="let team of teamsList; let i = index" class="p-2 bb-1" [class.bt-1]="i == 0"><a (click)="selectTeam(team.data);">{{team.data.name}}</a></li>
        </ul>
        <ul class="list-unstyled bottom">
          <li class="p-2 pb-0 bt-1"><a (click)="hide();">{{ 'GENERAL.CLOSE' | translate }}</a></li>
        </ul>
    </div>
</div>