import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { LdLeadsSellersService } from 'src/app/services/ld_leads_sellers.service';
import { UsersTypesService } from 'src/app/services/users_types.service';

@Component({
  selector: 'app-list-sellers',
  templateUrl: './list-sellers.component.html',
  styleUrls: ['./list-sellers.component.scss'],
})
export class ListSellersComponent implements OnInit {
  constructor(
    private sellersService: UsersTypesService,
    private ldLeadsSellersService: LdLeadsSellersService
  ) {}

  @Input() listType: string = 'reduced'; //reduced, normal
  @Input() avatarSize: string = '1x';
  @Input() leadId: number = null;
  @Input() previewNumber: number = 4;
  @Input() onlyPreview: boolean = false;
  @Input() data: any[] = [];
  @Input() read: boolean = true;

  @Output() notifyOnProcess = new EventEmitter();

  openRemove: boolean = false;
  addSellers: boolean = false;

  sellerToRemove: any = {};
  sellers: any[] = [];

  ngOnInit(): void {
    this.loadSellers();
  }

  clickedOutsideHandler(): void {
    this.addSellers = false;
  }

  async loadSellers() {
    this.sellers.length = 0;

    if (this.read) {
      const filter = [
        { field: 'LdLeadsSellers.ld_leads_id', value: this.leadId },
      ];
      const leadSellers = await this.ldLeadsSellersService.getLeadsSellers(
        filter
      );

      const filter_2 = [{ field: 'UsersTypes.type', value: 'seller' }];
      const sellers = await this.sellersService.getUsersTypes(
        filter_2,
        'Users'
      );

      if (sellers.summary.total > 0) {
        sellers.items.forEach((sel: any) => {
          sel = sel.data;
          sel.isInLead = false;
          sel.leader = false;

          if (leadSellers.summary.total > 0) {
            const checkSeller = leadSellers.items.find(
              (i) => i.data.users_id == sel.users_id
            );
            if (checkSeller) {
              sel.lead_seller_id = checkSeller.data.id;
              sel.isInLead = true;

              const checkSellerLeader = leadSellers.items.some(
                (i) =>
                  i.data.users_id == sel.users_id &&
                  i.data.default_seller == true
              );
              if (checkSellerLeader) {
                sel.leader = true;
              }
            }
          }
          this.sellers.push(sel);
        });
      }
      this.sellers.sort((a, b) => b.isInLead - a.isInLead);
    } else {

      this.data.forEach((item: any) => {
        item.isInLead = true; // Defina como necessário
        item.leader = false; // Defina como necessário
      });


      this.sellers = this.data;
    }
  }

  removeSeller(index) {
    this.sellerToRemove = this.sellers[index];
    this.sellerToRemove.index = index;

    this.openRemove = !this.openRemove;
  }

  async execRemoveSeller() {
    const toRemove = this.sellers[this.sellerToRemove.index];
    toRemove.isInLead = false;
    await this.removeSellerFromDataBase(toRemove.users_id);
    this.sellers = this.sellers;
    this.openRemove = !this.openRemove;
  }

  showSellers() {
    this.addSellers = !this.addSellers;
  }

  async addOrRemoveSeller(index) {
    const seller = this.sellers[index];

    if (seller.isInLead) {
      this.removeSellerFromDataBase(seller.users_id);
      seller.isInLead = false;
    } else {
      const obj = {
        users_id: seller.users_id,
        ld_leads_id: this.leadId,
      };
      await this.ldLeadsSellersService.insertLeadSeller(obj).then((ret) => {
        seller.isInLead = true;
        seller.lead_seller_id = ret.items.id;
      });
    }
    this.sellers = this.sellers;
    this.notifyOnProcess.emit('');
  }

  async removeSellerFromDataBase(user_id) {
    const filter = [
      { field: 'LdLeadsSellers.ld_leads_id', value: this.leadId },
      { field: 'LdLeadsSellers.users_id', value: user_id },
    ];

    await this.ldLeadsSellersService
      .getLeadsSellers(filter)
      .then(async (ret: any) => {
        await this.ldLeadsSellersService.deleteLeadSeller(ret.items[0].data.id);
      });
    this.notifyOnProcess.emit('');
  }

  async markAsDefaultSeller(user_id) {
    const oldLeader = this.sellers.find((sel) => sel.leader);
    if (oldLeader) {
      oldLeader.leader = false;
      await this.ldLeadsSellersService.updateField(
        oldLeader.lead_seller_id,
        'default_seller',
        0
      );
    }

    const newLeader = this.sellers.find((sel) => sel.users_id == user_id);
    newLeader.leader = true;

    await this.ldLeadsSellersService.updateField(
      newLeader.lead_seller_id,
      'default_seller',
      1
    );
  }
}
