<app-maintenance></app-maintenance>
<div id="personate" style="background: #d04d4d !important;" *ngIf="personate_active == true">
	<p>{{ 'PERSONATE.ACTIVE' | translate}} <span (click)="exitPersonate()">{{ 'PERSONATE.LOGOUT' | translate}}</span></p>
</div>

<div class="app" [class.hideScrollBar]="scrollDisabled">
	<top-nav></top-nav>
	<div class="content flex mmd:overflow-y-visible mmd:flex-col">
    	<side-nav class="hideScrollBar d-none d-md-block"></side-nav>
    	<top-nav class="d-none hideScrollBar"></top-nav>
    	<main class="main-content mmd:max-w-full p-md-6">
			<router-outlet></router-outlet>
		</main>
	</div>
</div>