import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit { 

    @Input()
	total: any;
    @Input()
	page: string;
    @Input()
	actualTotal: number;
    @Input()
	registerByPage: number = 20;
    @Input()
	number_of_pages: number;
    @Output() 
    newPage = new EventEmitter();
 
    pages: any = [];

    loading: boolean = false;

    first: boolean = false;
    prev: boolean = false;
    next: boolean = false;
    last: boolean = false;
    
    actualPage  = 0;
    fromCount = 0;
    toCount = 0;

    constructor(
        private router: Router
    ){}

    ngOnInit() {
        this.loadPage();
    }

    ngOnChanges() {
        this.loadPage();
    }

    loadPage(){
        this.loading    = true;
        delete this.pages;
        this.pages      = [];
        if ( this.number_of_pages == undefined )  
            this.number_of_pages = 1;
        if ( localStorage.getItem('pagination.' + this.router.url) )
            this.actualPage = parseInt(localStorage.getItem('pagination.' + this.router.url));
        else
            this.actualPage = 0;
        let i = this.actualPage;
        let pages = 8;

        let from = i - pages;
        let diff = 1;
        if ( from < 1 ) {
            diff = 1 - from + 1;
            from = 1;
        }
        let page    = i;
        if ( page < 1 )
            page    = 1;
        let to = diff + pages + page + 1;
        if ( to > this.number_of_pages ) {
            to = this.number_of_pages;
            if ( from > (this.number_of_pages - (pages * 2)) ) {
                let finalDiff = (this.number_of_pages - (pages * 2));
                from = finalDiff;
            }
        }
        if ( from < 1 ) {
            from = 1;
        }
        this.fromCount  = i * this.registerByPage + 1;
        this.toCount  = (i + 1) * this.registerByPage;
        if ( this.toCount >= this.total )
            this.toCount    = this.total;

        for ( let i1 = from; i1 <= to; i1++ ) {
            let item    = {name: i1}
            this.pages.push(item);
        }

        this.first  = true;
        this.prev   = true;
        this.next   = true;
        this.last   = true;
        if ( this.number_of_pages > 1 ) {
            if ( this.actualPage == 0 ) {
                this.first  = false;
                this.prev   = false;
            } 
            if ( this.actualPage == this.number_of_pages - 1 ){
                this.last   = false;
                this.next   = false;
            }
        } else {
            this.first  = false;
            this.prev   = false;
            this.next   = false;
            this.last   = false;
        }
        this.loading    = false;
    }

    pageChange(page){
        page            = page -1;
        localStorage.setItem('pagination.' + this.router.url, page);
        this.actualPage = page;
        this.newPage.emit(page);
        this.loadPage();
    }

    pageCommand(action = 'first'){
        let set_page    = this.actualPage;
        if ( action == 'first' ) {
            set_page    = 1;
        } else if ( action == 'prev' ) {
            set_page    = set_page + 1 - 1;
            if ( set_page < 1 )
                set_page= 1;
        } else if ( action == 'next' ) {
            set_page    = set_page + 1 + 1;
        } else if ( action == 'last' ) {
            set_page    = this.number_of_pages;
        } else {
            set_page    = parseInt(action);
        }
        if ( set_page > this.number_of_pages ) {
            set_page    = this.number_of_pages;
        }
        if ( set_page < 0 ) {
            set_page    = 0;
        }

        this.pageChange(set_page);
    }

}