<div class="avatar" [class]="size" *ngIf="icon == '' && name != '' && ( image == '' || image == null )"
  [style.background]="color" [style.color]="textColor">
  <span>{{ name | initials }} <i *ngIf="deleteEnabled" class="icon close-icon"></i> </span>
</div>
<div class="avatar" [class]="size" *ngIf="icon == '' && name != '' && image != '' && image != null"
  [style.background]="color" [style.color]="textColor">
  <img src="{{image}}" alt="{{name}}" class=" border rounded-circle" style="object-fit: cover">
</div>
<div class="avatar" [class]="size" *ngIf="icon != '' && icon != null && text == ''" [style.background]="color">
<div class="avatar" [class]="size" *ngIf="icon != '' && icon != null" [style.background]="color" [style.color]="textColor">
  <span class="fa {{icon}}"></span>
</div>
<div class="avatar" [class]="size" *ngIf="icon != '' && icon != null && text != '' && text != null" [style.background]="color">
  <span class="fa {{icon}}"></span><span>{{text}}</span>
</div>
