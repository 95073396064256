import {Pipe} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Globals } from 'src/globals';

@Pipe({
    name: 'dateDescription'
})
export class DateDescriptionFormat {

    constructor(
        private translateService: TranslateService,
        private globals: Globals
    ){}
    
    async transform(value: Date): Promise<string> {
        const date  = new Date(value)
        const today = new Date()

        if(
            date.getDate() == today.getDate() &&
            date.getMonth() == date.getMonth() &&
            date.getFullYear() == date.getFullYear()
        ){
            const today = await this.translateService.get("SCHEDULE.TODAY").toPromise().then((v:any) => {
                return v
            })
            return today;    
        }

        const of    = await this.translateService.get("GENERAL.OF").toPromise().then((v:any) => {
            return v
        })

        const month = await this.getMonthLabel(date.getMonth())
        return `${date.getDate().toString().padStart(2, '0')} ${of} ${month} ${of} ${date.getFullYear()}`
    }

    private async getMonthLabel(month, numberOfChars = 0): Promise<string> {
        const mth = await this.translateService.get(this.globals.getMonthLabel(month)).toPromise().then((v:any) => {
            if ( numberOfChars == 0 )
                return v
            else {
                return v.substring(0,numberOfChars)
            }
        })
        return mth
    }
}