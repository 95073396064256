import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RestService } from './rest.service';

@Injectable()
export class ContractQuotaService extends RestService {


    private _url = 'ContractsQuotas';
    private _contractFileUrl = 'ContractsQuotas/generateContractFile';
    
	constructor(protected _http: HttpClient) {
		super(_http);
    } 
    
    insertQuota(data: any) : any {
        return this.postApi(this._url, data);
    }
    
    updateQuota(quota: any) : any {
        let data    = JSON.stringify(quota);
        return this.putApi(this._url + '/' + quota.id, data);
    }

    updateField(id: number, field: any, value: any): any {
        let data = JSON.stringify({ id: id, field: field, value: value });
        return this.patchApi(this._url + '/' + id, data);
    }
    
    deleteQuota(id) : any {
        return this.deleteApi(this._url + '/' + id);
    }

    generateContractFile(quota_id){
        return this.postApi(this._contractFileUrl, JSON.stringify({quota_id: quota_id}))
    }
    
    getQuotas(filter_data: any, contain_string: string = '', order_string: any = '+ContractsQuotas.id', page: number = 0, fields: string = '', limit: number = 999, groupData: any = [], report:boolean = false, operationsData: any = [], unlimited = 0): any {
        let qs: string = '';
        let group: string = '';
        let operations: string = '';
        let paging: string = '';
        let order = order_string
    
        // (filter_data: any, contains: string = '', order_string: string = '+ContractsQuotas.id', page: number = 0, fields: string = '', limit: number = 20, group: string = ''): any {
        //     let qs = '';
        //     let paging  = '';

        if ( filter_data.id && filter_data.id != null ) {
            qs   += 'ContractsQuotas.id|' + filter_data.id + ';';
        }
        if ( filter_data.contract_id && filter_data.contract_id != '' ) {
            qs   += 'ContractsQuotas.contract_id|' + filter_data.contract_id + ';';
        }
        if ( filter_data.package_id && filter_data.package_id != '' ) {
            qs   += 'ContractsQuotas.package_id|' + filter_data.package_id + ';';
        }
        if ( filter_data.receivables_status_id && filter_data.receivables_status_id != '' ) {
            qs   += 'Receivables.receivables_status_id|' + filter_data.receivables_status_id;
        }
        if ( filter_data.package_id && filter_data.package_id != '' ) {
            qs   += 'ContractsQuotas.package_id|' + filter_data.package_id + ';';
        }
        if ( filter_data.user_id && filter_data.user_id != '' ) {
            qs   += 'ContractsQuotas.user_id|' + filter_data.user_id + ';';
        }
        if ( filter_data.contracts_quotas_status_id && filter_data.contracts_quotas_status_id != '' ) {
            qs   += 'ContractsQuotas.contracts_quotas_status_id|' + filter_data.contracts_quotas_status_id + ';';
        }

        if(Array.isArray(filter_data)){
          filter_data.map(item => {
            if(item.operator == null || item.operator == ''){
              qs += item.field + '|' + item.value + ';'
            }else{
              qs += item.field + '|' + item.operator + '|' + item.value + ';'
            }
          })
        }
        if(groupData && groupData.length > 0){
            if ( Array.isArray(groupData) ) {
                groupData.map(item => {        
                    group += item.field + ';'        
                })
            } else {
                group = groupData
            }
        } 
        if(operationsData && operationsData.length > 0){
          operationsData.map(item => {        
               operations += item.operation + '|' + item.field + ';'        
          })
        }
        if(Array.isArray(order)){
          const orderArray = order
          order = ''
          orderArray.map(item => order += `${item.order}${item.field};`)
        }
        
        
        // return
        if(report == true)
           qs = filter_data;
    
        let offset = (page * limit);
        paging = offset + ";" + limit;
        return this.getApi(this._url, { params: { query: qs, paging: paging, order: order, contains: contain_string, fields: fields, group: group, operations: operations, unlimited: unlimited  } });
    }
    
    getQuota(id: number, contain_string: string = '', fields: string = ''): any {
        return this.getApi(this._url + '/' + id, { params: {contains: contain_string, fields: fields } });
    }

    importQuotaReceivablesPreview(file: File, contractId) : any {
        let formData:FormData = new FormData();
        formData.append('file', file, file.name);
        formData.append('contract_id', contractId);
    
        return this.postApi(this._url + '/previewImportContractQuotaReceivables', formData);
    }

    importQuotaReceivablesFromPreview(data: Array<any>, contractId) : any {
        return this.postApi(this._url + '/importContractQuotaReceivables', JSON.stringify({data: data, contract_id: contractId}));
    }

    async changeContractQuotaPackage(quotaId, oldPackge, newPackage, receivablesToDelete, newReceivables, log) {
        const body = {
            quotaId, oldPackge, newPackage, receivablesToDelete, newReceivables, log
        }
        
        return this.postApi(this._url + '/changeContractQuotaPackage', JSON.stringify(body));
    }
}