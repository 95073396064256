
import { Component, OnInit,HostListener } from '@angular/core';
import { Router, ActivationEnd, ActivatedRoute, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { VariableService } from './services/variable.service';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import { MenuService } from './services/menu.service';
import { PermissionService } from './services/permission.service';
import { GroupsUsersService } from './services/groups_users.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html', 
    providers: []
})
export class AppComponent implements OnInit {

    constructor(
        private router: Router, 
        private translateService: TranslateService,
        private activatedRoute: ActivatedRoute,
        private menuService: MenuService,
        private permissionsService: PermissionService,
        private translate: TranslateService,
        private titleService: Title,
        private variablesService: VariableService,
        private groupsUsersService: GroupsUsersService
    ) {}

    scrollDisabled: boolean = false;
    new_password: string = '';
    title: string = '';
    config: any = [];
    loading: boolean = false;
    invalid: boolean = false;
    errorMessage: any = [];
    watch: any;
    offline_mode:boolean = false;
    menu: any;

    async ngOnInit() {
        await this.loadConfig();
        this.reloadPermissionsAndMenu()

        this.router.events.subscribe((evt) => this.reloadPermissionsAndMenu() ); 

        this.sleep(500).then(()=>this.handleModalBackDrop())

        this.translateService.setDefaultLang('pt'); 
        this.loading    = true;
    }

    reloadPermissionsAndMenu(){
        const checkIsReloading = localStorage.getItem('isReloadingPermissionsAndMenu');
        if(!checkIsReloading){
            localStorage.setItem('isReloadingPermissionsAndMenu', '1');
            let auth            = JSON.parse(localStorage.getItem('auth'));
            const filter = [
                { field: 'GroupsUsers.user_id', value: auth.user.id }
            ]

            const newPermissions = []
            this.groupsUsersService.getGroupsUsers(filter, 'Groups.Permissions', '', 0, '', 9999).then(async (data: any) => {
                if(data && data.summary.total > 0){
                    data.items.map((groupUser: any) => {
                        if(groupUser.data.group.Permissions && groupUser.data.group.Permissions.length > 0){
                            groupUser.data.group.Permissions.map((permission: any) => {
                                newPermissions.push(`${permission.component}/${permission.action}`)
                            })
                        }
                    })

                    if(newPermissions.length){
                        newPermissions.push("users/change-password");
                        newPermissions.push("users/profile");

                        auth.permissions = newPermissions;
                        localStorage.setItem('auth', JSON.stringify(auth));

                        await this.loadConfig() 
                    }else{
                        localStorage.clear()
                        this.router.navigate([ "/login" ]);
                    }
                }
                localStorage.removeItem('isReloadingPermissionsAndMenu');
            })
        }else{
            setTimeout(() => {
                localStorage.removeItem('isReloadingPermissionsAndMenu');
            }, 3000);
        }
    }
    
    async loadConfig(): Promise<any> {
        await this.variablesService.getVariables({}).then(async result => {
            if ( !result.summary.total || result.summary.total == 0 ) {
                this.router.navigate(['/logout']);
                return false;
            } else {
                this.config = {}
                await result.items.map((config: any) => {
                    this.config[config.data.name]  = config.data.value;
                });
                localStorage.setItem('config', JSON.stringify(this.config));
                
                this.title  = this.config['title'];
                let language = localStorage.getItem('language');
                if ( language == '' || language == null ) {
                    if ( this.config['default_language'] )
                        language = this.config['default_language'];
                    else
                        language = 'pt';
                }
                this.translate.use(language);

                this.router.events
                    .filter((event) => event instanceof NavigationEnd)
                    .map(() => this.activatedRoute)
                    .map((route) => {
                        while (route.firstChild) route = route.firstChild;
                        return route;
                    })
                    .filter((route) => route.outlet === 'primary')
                    .mergeMap((route) => route.data)
                    .subscribe((event) => {
                        if ( event && event['title'] && event['title'] != '' ) {
                            this.translate.get(event['title']).subscribe(value => { 
                                this.titleService.setTitle(this.title + ' - ' + value);
                            });
                        }
                    });

                let filter  = {id: this.config['menu_id']}
                await this.menuService.getMenus(filter).then(async result => {
                    if ( result.summary.total > 0 ) {
                        this.menu   = JSON.parse(result.items[0].data.data);
                        await this.processMenu();
                        localStorage.setItem('menu', JSON.stringify(this.menu));
                    }
                    this.loading    = false;
                });
                return true;
            }
        });
    }

    private async processMenu(): Promise<any> {
        let auth            = JSON.parse(localStorage.getItem('auth'));
        if ( this.menu ) {
            let read_permissions    = [];
            await this.menu.map((itemmenu: any) => {
                if ( itemmenu.children && itemmenu.children.length > 0 ) {
                    itemmenu.children.map((children1: any) => {
                        if ( children1.children && children1.children.length > 0 ) {
                            children1.children.map((children2: any) => {
                                if ( children2.permission_id )
                                    read_permissions.push(children2.permission_id);
                            });
                        } else {
                            if ( children1.permission_id )
                                read_permissions.push(children1.permission_id);
                        }
                    });
                } else if ( itemmenu.permission_id ) {
                    read_permissions.push(itemmenu.permission_id);
                }
            });
            
            let filter      = {idIN: read_permissions.join(',')}
            let allowed     = [];
            let newMenu     = [];
            let references  = [];
            this.loading    = true;
            await this.permissionsService.getPermissions(filter, '', '', 0, '', 1000).then(async result => {
                if ( result.summary.total > 0 ) {
                    await result.items.map((perm: any) => {
                        if ( auth.permissions.includes(perm.data.component + '/' + perm.data.action) ) {
                            allowed.push(perm.data.id);
                            references.push({id: perm.data.id, obj: perm.data})
                        } 
                    });
                    await this.menu.map((itemmenu: any) => {
                        if ( itemmenu.permission_id ) {
                            //link interno
                            if ( allowed.includes(parseInt(itemmenu.permission_id)) )
                                newMenu.push(itemmenu);
                        } else  if ( itemmenu.children ) {
                            //group
                            let children1   = [];
                            for ( let i1 = 0; itemmenu.children[i1]; i1++ ) {
                                if ( itemmenu.children[i1].permission_id ) {
                                    //link interno
                                    if ( allowed.includes(parseInt(itemmenu.children[i1].permission_id)) )
                                        children1.push(itemmenu.children[i1]);
                                } else if ( itemmenu.children[i1].children ) {
                                    //group
                                    let children2   = [];
                                    for ( let i2 = 0; itemmenu.children[i1].children[i2]; i2++ ) {
                                        if ( itemmenu.children[i1].children[i2].permission_id ) {
                                            //link interno
                                            if ( allowed.includes(parseInt(itemmenu.children[i1].children[i2].permission_id)) )
                                                children2.push(itemmenu.children[i1].children[i2]);
                                        } else {
                                            children2.push(itemmenu.children[i1].children[i2]);
                                        }
                                    }
                                    if ( children2.length > 0 ) {
                                        itemmenu.children[i1].children   = children2;
                                        children1.push(itemmenu.children[i1]);
                                    }
                                } else {
                                    //link externo
                                    children1.push(itemmenu.children[i1]);
                                }
                            }
                            if ( children1.length > 0 ) {
                                itemmenu.children   = children1;
                                newMenu.push(itemmenu);
                            }
                        } else {
                            //link externo
                            newMenu.push(itemmenu);
                        }
                    });
                }
                this.loading    = false;
                this.menu       = newMenu;
                if ( this.menu.length == 0 ) {
                    this.router.navigate(['/logout']);
                }
                return this.menu;
            });
        }
    }

    @HostListener('window:wheel', ['$event'])
    onWindowScroll(event) {
        this.scrollDisabled = false;
        // Here we use the displacement between two vertical points during the user scrolling. As the values get closer to
        // zero, the scrollbar is set to disappear. A further implementation intends to get rid of the rather ugly magic number (1.3)
        if (Math.abs(event.deltaY) < 1.3){
            this.sleep(500).then(() => {this.scrollDisabled = true;});
        }
    }

    @HostListener('document:click', ['$event'])
    onDocumentClick() {
        this.handleModalBackDrop();
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    handleModalBackDrop(){
        const nodelist = document.getElementsByTagName("ngb-modal-backdrop");
        const index = document.getElementsByTagName("ngb-modal-backdrop").length;
        const classList = document.getElementsByClassName('final-modal');
        if(classList.length == 0){
            for (let i = 1; i < index; i++) {
                const element = nodelist[i];
                nodelist[i].classList.add('opacity-0');
            }
        } else{
            for (let i = 1; i < index - 1; i++) {
                const element = nodelist[i];
                nodelist[i].classList.add('opacity-0');
            }
        }
    }
}

export class modalBackdrop{
    constructor(){
    
    }
    handleModalBackDrop(){
        const nodelist = document.getElementsByTagName("ngb-modal-backdrop");
        const index = document.getElementsByTagName("ngb-modal-backdrop").length;
        for (let i = 1; i < index; i++) {
            const element = nodelist[i];
            nodelist[i].classList.add('opacity-0');
        }
    }
}

