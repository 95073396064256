<div class="modal-header">
    <h4 class="modal-title">{{ title | translate }}</h4>
    <button type="button" class="close-modal" aria-label="Close" (click)="dismiss()">
        <svg width="13" height="13" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.99776 5.40192L1.70137 7.69831C0.778655 8.62102 -0.61523 7.21686 0.297548 6.30442L2.60421 3.99776L0.297548 1.70137C-0.61489 0.778655 0.778995 -0.61523 1.70137 0.297548L3.99776 2.60421L6.30442 0.297548C7.21686 -0.61489 8.62102 0.778995 7.69831 1.70137L5.40192 3.99776L7.69831 6.30442C8.62102 7.21686 7.21686 8.62102 6.30442 7.69831L3.99776 5.40192Z" fill="#45444B"/>
        </svg>
    </button>
</div>
<div class="modal-body">
    <p>{{ message | translate}}</p>
</div>
<div class="modal-footer">
    <button type="button" class="btn-left" (click)="decline()">{{ btnCancelText | translate}}</button>
    <button type="button" class="btn-right" (click)="accept()">{{ btnOkText | translate}}</button>
</div>