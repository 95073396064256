import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/take';
import 'rxjs/add/operator/map';

import { AuthMainService } from './services/auth.service';
import { GeneralComponent } from './_utils/general/general.component';
import { TranslateService } from '@ngx-translate/core';
 
@Injectable()
export class AuthGuard implements CanActivate {

    errorMessage: any = [];

    constructor(
        private router: Router,
        private authService: AuthMainService,
        private general: GeneralComponent,
        private translate: TranslateService
    ) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
        
    ): Observable<boolean> {
        let urlSplit = state.url.split('/');
        const comp = (urlSplit[1] == '' ? 'home': urlSplit[1]);
        
        return this.authService.isLoggedIn
            .take(1)
            .map((isLoggedIn: boolean) => {
                if (!isLoggedIn){
                    this.router.navigate(['/login']);
                    return false;
                } else {
                    //check user permission
                    let auth                = JSON.parse(localStorage.getItem('auth'));
                    let permissions         = auth.permissions;
                    let action              = next.routeConfig.path.split(':');
                    let tokenUserExpires    = localStorage.getItem('tokenUserExpires');
                    let d = new Date();
                    const tokenDate = new Date(tokenUserExpires) 

                    if ( this.router.url != '/p/changelog' ) {
                        return true;
                    } else {

                    // if ( tokenDate > d) {
                        if ( action[0].slice(-1) == '/' ) 
                            action[0]   = action[0].slice(0, -1)
                            
                        if(action[0] == null || action[0] == '' || comp === action[0])
                            action[0] = 'index';
                            
                        const compAction = comp + '/' + action[0];

                        if ( action[0] != null && permissions && permissions.length > 0 && permissions.indexOf(compAction) == -1 ) {
                            return false;
                        } else {
                            if ( auth.user.change_password == 'Y' ) {
                                if ( compAction != 'change-password' )
                                    this.router.navigate(['/change-password']);
                            }
                            return true;
                        }
                    // } else {
                    //     if ( this.router.url != '/login' ) {
                    //         if ( this.translate.langs.length == 0 ) {
                    //             this.translate.onLangChange.subscribe(() => {
                    //                 this.translate.get('GENERAL.LOGIN_ERROR.EXPIRED_USER_SESSION').subscribe(data => {
                    //                     this.general.setMessage(data);
                    //                 });
                    //             });
                    //         } else {
                    //             this.translate.get('GENERAL.LOGIN_ERROR.EXPIRED_USER_SESSION').subscribe(data => {
                    //                 this.general.setMessage(data);
                    //             });
                    //         }
                    //         this.router.navigate(['/login']);
                    //     }
                    //     return false;
                    // }
                    }
                }
            }
        );
    }
}