import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { RestService } from './rest.service';

@Injectable({
  providedIn: 'root'
})
export class ReceivablesStatusService extends RestService {
  private _url = 'ReceivablesStatus';
  
  constructor(protected _http: HttpClient) {
    super(_http);
  }

  insertReceivableStatus(Receivable: any): any {
    let data = JSON.stringify(Receivable); 
    return this.postApi(this._url, data);
  }

  updateReceivableStatus(Receivable: any): any {
    let data = JSON.stringify(Receivable);
    return this.putApi(this._url + '/' + Receivable.id, data);
  }

  deleteReceivableStatus(id: number): any {
    return this.deleteApi(this._url + '/' + id);
  }

  updateField(id: number, field: any, value: any): any {
    let data = JSON.stringify({ id: id, field: field, value: value });
    return this.patchApi(this._url + '/' + id, data);
  }

  getReceivablesStatus(filter_data: any, contain_string: string = '', order_string: string = '+ReceivablesStatus.name', page: number = 0, fields: string = '', limit: number = 20): any {
    let qs: string = '';
    let paging: string = '';

    if (filter_data.id && filter_data.id != null) {
      qs += 'ReceivablesStatus.id|' + filter_data.id + ';';
    }
    if (filter_data.notId && filter_data.notId != null) {
      qs += 'ReceivablesStatus.id|!=|' + filter_data.notId + ';';
    }
    if (filter_data.name && filter_data.name != '') {
      qs += 'ReceivablesStatus.name|like|' + filter_data.name + ';';
    }
    if(Array.isArray(filter_data)){
      filter_data.map(item => {
        if(item.operator == null || item.operator == ''){
          qs += item.field + '|' + item.value + ';'
        }else{
          qs += item.field + '|' + item.operator + '|' + item.value + ';'
        }
      })
    }

    let offset = (page * limit);
    paging = offset + ";" + limit;
    return this.getApi(this._url, { params: { query: qs, paging: paging, order: order_string, contains: contain_string, fields: fields } });
  }
  
  getReceivable(id){
    return this.getApi(this._url + '/' + id);
  }

}