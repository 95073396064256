import { Component, OnInit } from '@angular/core';
import { PersonateLogService } from 'src/app/services/personate_log.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {

  constructor(
    private personateLogService: PersonateLogService
  ) {}
 
  ngOnInit(): void {

    this.loadPersonate()
  }

  loadPersonate(){
    this.personate = JSON.parse(localStorage.getItem('mainUser'))
    if(this.personate != null && this.personate.personate == true){
      this.personate_active = true;
    }
  }
  
  scrollDisabled: boolean = false;
  personate: any;
    personate_active: boolean = false;

  exitPersonate(){
    const personateUser = JSON.parse(localStorage.getItem('auth'))
    
    this.personateLogService.cancelPersonate(this.personate.items.authP.user.id, personateUser.user.id).then(log => {

      
      // Remover localStorage item token
      localStorage.removeItem('token')
      // Remover localStorage item avatar
      localStorage.removeItem('avatar')
      // Remover localStorage item auth
      localStorage.removeItem('auth')
      // Remover localStorage item sessioHash
      localStorage.removeItem('sessionHash')
      // Remover localStorage item tokenUser
      localStorage.removeItem('tokenUser')
      // Remover localStorage item tokenUserExpires
      localStorage.removeItem('tokenUserExpires')


      // Voltar todos acima atraves da chave mainUser
      localStorage.setItem('token', JSON.stringify(this.personate.items.tokenP))
      localStorage.setItem('avatar', this.personate.items.avatarP)
      localStorage.setItem('auth', JSON.stringify(this.personate.items.authP))
      localStorage.setItem('sessionHash', this.personate.items.sessionHashP)
      localStorage.setItem('tokenUser', this.personate.items.tokenUserP)
      localStorage.setItem('tokenUserExpires', this.personate.items.tokenUserExpiresP)


      // Remover localStorage item mainUser
      localStorage.removeItem('mainUser')

      
      location.reload()
    })


  }
}
