import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'parameterize'
})
export class ParameterizePipe implements PipeTransform {

  transform(value: string): string {
    return value
      .toLowerCase()
      .replace(/[ ]/g, '_')
      .replace('.', '') 
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
  }

}
