import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PersonateLogService  extends RestService {

  private _url = 'PersonateLog';
  
  constructor(protected _http: HttpClient) {
    super(_http);
  }
  
  startPersonate(user_id_from, user_id_to): any {
    let _data = JSON.stringify({ 
      user_id_from: user_id_from,
      user_id_to: user_id_to,
      action: 'in'
    });
    return this.postApi(this._url, _data);
  } 

  cancelPersonate(user_id_from, user_id_to) : any {
    let _data = JSON.stringify({
      user_id_from: user_id_from,
      user_id_to: user_id_to,
      action: 'out'
    });
    return this.postApi(this._url, _data);
  }

}
