import { Component, OnInit, Input } from '@angular/core';
import { MaintenanceService } from 'src/app/services/maintenance.service';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit {

	status: string = 'online';

	constructor(
		private maintenanceService: MaintenanceService
	) {}

	ngOnInit() {
		this.load();
	}

	async load(){
		// setInterval(() => { 
		// 	this.maintenanceService.checkStatus().then((ret:any) => this.status = ret.status)
		// }, 15000);
	}

}