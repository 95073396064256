<app-loading [loading]="loading"></app-loading>
<div class="container-fluid">
    <div class="row">
        <div class="
            col-10 offset-1
            col-xs-8 offset-md-2
            col-sm-8 offset-md-2
            col-md-6 offset-md-3 
            col-lg-4 offset-md-4
            align-center login-wrapper bg-light" *ngIf="!code && !new_password">
            <div class="row mb-6">
                <div class="col-12 text-black-50">
                    <a (click)="back()"><span class="fa fa-chevron-left"></span> {{'LOGIN.BACK_TO_LOGIN' | translate}}</a>
                </div>
            </div>
            <h2 class="mt-6">{{ 'GENERAL.FORGOT' | translate }}</h2>
            <p class="mt-3">{{ 'GENERAL.FORGOT-INSTRUCTIONS' | translate }}</p>
            <div class="form-group mt-5">
                <label for="">{{ 'GENERAL.EMAIL' | translate }}</label>
                <input type="text" class="form-control" [disabled]="loading" [(ngModel)]="forgot.email" id="forgot" name="forgot" placeholder="E-mail" autofocus [ngClass]="validation.fields.indexOf('forgot.email') > -1 ? 'is-invalid' : ''">
            </div>
            <div class="mv-2">
                <p class="text-center">{{ 'GENERAL.OR' | translate }}</p>
            </div>
            <div class="form-group">
                <label for="">{{ 'GENERAL.CELLPHONE' | translate }}</label>
                <input type="tel" [textMask]="{mask: maskPhone}" [disabled]="loading" [(ngModel)]="forgot.cellphone" id="cellphone" name="cellphone" class="form-control" placeholder="(99) 9 9999-9999" [ngClass]="validation.fields.indexOf('forgot.cellphone') > -1 ? 'is-invalid' : ''">
            </div>
            <div>
                <input type="submit" (click)="recoveryCode()" class="btn btn-primary white pt-2 btn-block btn-xs" value="{{'GENERAL.RECOVERY' | translate}}">
            </div>
            <div class="mt-6">
                <p class="mt-4 text-xs text-center text-black-50">TAG S/A, {{year}}</p>
            </div>
            <div class="row text-center mt-4">
                <div class="col-12 col-md-4 offset-md-2 text-xs">
                    <a class="text-darkblue" href="https://formo.formaturas.app/termos-de-uso" target="_blank">Termos e Condições</a>
                </div>
                <div class="col-12 col-md-4 text-xs">
                    <a class="text-darkblue" href="https://formo.formaturas.app/politica-de-privacidade" target="_blank">Privacidade</a>
                </div>
                <!-- <div class="col-12 col-md-3 text-xs">
                    <a href="">Ajuda</a>
                </div> -->
            </div>
        </div>
        <div class="
            col-10 offset-1
            col-xs-8 offset-md-2
            col-sm-8 offset-md-2
            col-md-6 offset-md-3 
            col-lg-4 offset-md-4
            align-center login-wrapper bg-light" *ngIf="code">
            <div class="row mb-6">
                <div class="col-12 text-black-50">
                    <a (click)="back()"><span class="fa fa-chevron-left"></span> {{'LOGIN.BACK_TO_LOGIN' | translate}}</a>
                </div>
            </div>
            <h2 class="mt-6">{{ 'GENERAL.FORGOT-CODE-TITLE' | translate }}</h2>
            <p class="mt-3">{{ 'GENERAL.FORGOT-CODE-INSTRUCTIONS' | translate }}</p>

            <div class="form-group mt-5">
                <label for="">{{ 'GENERAL.FORGOT-CODE' | translate }}</label>
                <input type="tel" class="form-control" [disabled]="loading" [(ngModel)]="forgot.code" id="code" name="code" placeholder="Code" autofocus [ngClass]="validation.fields.indexOf('forgot.code') > -1 ? 'is-invalid' : ''">
            </div>
            <div>
                <input type="submit" (click)="checkCode()" class="btn btn-primary white pt-2 btn-block btn-xs" value="{{'GENERAL.CHECK-CODE' | translate}}">
            </div>

            <div class="mt-6">
                <p class="mt-4 text-xs text-center text-black-50">TAG S/A, {{year}}</p>
            </div>
            <div class="row text-center mt-4">
                <div class="col-12 col-md-4 offset-md-2 text-xs">
                    <a class="text-darkblue" href="https://formo.formaturas.app/termos-de-uso" target="_blank">Termos e Condições</a>
                </div>
                <div class="col-12 col-md-4 text-xs">
                    <a class="text-darkblue" href="https://formo.formaturas.app/politica-de-privacidade" target="_blank">Privacidade</a>
                </div>
                <!-- <div class="col-12 col-md-3 text-xs">
                    <a href="">Ajuda</a>
                </div> -->
            </div>
        </div>

        <div class="
            col-10 offset-1
            col-xs-8 offset-md-2
            col-sm-8 offset-md-2
            col-md-6 offset-md-3 
            col-lg-4 offset-md-4
            align-center login-wrapper bg-light" *ngIf="!code && new_password">
            <div class="row mb-6">
                <div class="col-12 text-black-50">
                    <a (click)="back()"><span class="fa fa-chevron-left"></span> {{'LOGIN.BACK_TO_LOGIN' | translate}}</a>
                </div>
            </div>
            <h2 class="mt-6">{{ 'GENERAL.FORGOT-NEW-PASSWORD' | translate }}</h2>
            <p class="mt-3">{{ 'GENERAL.FORGOT-PASSWORD-INSTRUCTIONS' | translate }}</p>

            <div class="form-group mt-5">
                <label for="">{{ 'GENERAL.PASSWORD' | translate }}</label>
                <input type="text" class="form-control" [disabled]="loading" [(ngModel)]="forgot.password" id="password" name="password" placeholder="{{ 'GENERAL.PASSWORD' | translate}}" autofocus [ngClass]="validation.fields.indexOf('forgot.code') > -1 ? 'is-invalid' : ''">
            </div>
            <div>
                <input type="submit" (click)="changePassword()" class="btn btn-primary white pt-2 btn-block btn-xs" value="{{'GENERAL.CHANGE-PASSWORD' | translate}}">
            </div>

            <div class="mt-6">
                <p class="mt-4 text-xs text-center text-black-50">TAG S/A, {{year}}</p>
            </div>
            <div class="row text-center mt-4">
                <div class="col-12 col-md-4 offset-md-2 text-xs">
                    <a class="text-darkblue" href="https://formo.formaturas.app/termos-de-uso" target="_blank">Termos e Condições</a>
                </div>
                <div class="col-12 col-md-4 text-xs">
                    <a class="text-darkblue" href="https://formo.formaturas.app/politica-de-privacidade" target="_blank">Privacidade</a>
                </div>
                <!-- <div class="col-12 col-md-3 text-xs">
                    <a href="">Ajuda</a>
                </div> -->
            </div>
        </div>

        <div class="col-2 text-right">
            <img *ngIf="numberBg != '3'" src="/assets/images/logo-login.svg" title="" class="logo">
            <img *ngIf="numberBg == '3'" src="/assets/images/logo-login-white.svg" title="" class="logo">
        </div>
    </div>
</div>
