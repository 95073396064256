
<!-- Botão -->
<ng-container *ngIf="Model == '1' ">
    <div [className]="classBtn">
        <button
          type="{{buttonType}}"
          class="btn {{buttonClass}} btn-{{classDisabled}}"
          [disabled]="classDisabled != ''"
        >
        {{ buttonLabel ? (buttonLabel | translate) : 'GENERAL.SAVE' | translate }}
        </button>
    </div>
</ng-container>

<!-- Botão com link -->
<ng-container *ngIf="Model == '2' ">
    <div [className]="classBtn">
        <div class="btnCustom">
            <div class="btnWhite"></div>
        </div>
        <a routerLink="{{routerLink}}" class="{{buttonClass}} {{classDisabled}}">
        {{ buttonLabel ? (buttonLabel | translate) : 'GENERAL.SAVE' | translate }}
        </a>
    </div>
</ng-container>

<!-- Botão com ícone e texto -->
<ng-container *ngIf="Model == '3' ">
    <div [className]="classBtn">
        <div class="btnCustom">
            <div class="blue"></div>
        </div>
        <a routerLink="{{routerLink}}" class="{{buttonClass}} {{classDisabled}}">
            <i class="mr-3 {{icon}}"></i>
            {{ buttonLabel ? (buttonLabel | translate) : 'GENERAL.SAVE' | translate }}
        </a>
    </div>
</ng-container>

<!-- Botão com ícone -->
<ng-container *ngIf="Model == '4' ">
    <div [className]="classBtn">
        <a title="{{ buttonTitle ? (buttonTitle | translate) : '' }}" class="customBtnIcon{{classDisabled}}">
            <i class="{{icon}}"></i>
        </a>
    </div>
</ng-container>