import { Component, OnInit } from '@angular/core';
import { NotificationsStatusService } from 'src/app/services/notifications_status.service';
import { NotificationsTypesService } from 'src/app/services/notifications_types.service';
import { NotificationsTypesUsersService } from 'src/app/services/notifications_types_users.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-notifications',
  templateUrl: './notifications-modal.html',
  styleUrls: ['./notifications-modal.scss']
})
export class NotificationsModalComponent implements OnInit {
  loading: boolean = false;
  modules: any = [];
  auth: any;
  tab: any = 'unread';
  filter: any[] = []

  data: any = {
    summary: {
      total: 0,
      pages: 0
    },
    items: []
  }
  not_read: number = 0;
  page: number = 0;
  limit: number = 0

  constructor(
    public activeModal: NgbActiveModal,
    private notificationTypesService: NotificationsTypesService,
    private notificationsTypesUsersService: NotificationsTypesUsersService,
    private notificationService: NotificationsStatusService
  ) { }

  ngOnInit(): void {
    this.auth   = JSON.parse(localStorage.getItem('auth'));

    this.filter = [
      {field: 'NotificationsStatus.users_id', value: this.auth.user.id}
    ]

    this.select(this.tab, this.tab);

    // this.load()
    // this.loadNotifications()
  }

  loadNotifications(){
    this.loading      = true;
		this.data         = {
      summary: {
        total: 0,
        pages: 0
      },
      items: []
    };

    let filterCount = [];
    filterCount.push({field: 'NotificationsStatus.read_status', value: '0'})
    filterCount.push({field: 'NotificationsStatus.users_id', value: this.auth.user.id});
    this.notificationService.getNotificationStatus(filterCount).then(response => {
      this.not_read = response.summary.total;
    });

    this.notificationService.getNotificationStatus(this.filter, 'Notifications.NotificationsTypes;Users', '-NotificationsStatus.id', 0, '', this.limit).then(response => {
        if ( response.summary.process == false || response.summary.message == 'error' ) {
        } else {
          this.data = response;
          this.loading = false
        }
      },
      error => {
        this.loading = false
    });
    this.load()
  }

  setPage(setPage: number){
    this.page = setPage;
    this.loadNotifications();
  }

  select(tab: string, filter = null, limit = 20) {
      this.tab  = tab;
      // if(filter == 'all'){
      //   this.filter = [
      //     {field: 'NotificationsStatus.users_id', value: this.auth.user.id}
      //   ]
      // }

      if(filter == 'read'){
        this.filter = [
          {field: 'NotificationsStatus.users_id', value: this.auth.user.id},
          {field: 'NotificationsStatus.read_status', value: 1}
        ]
      }

      if(filter == 'unread'){
        this.filter = [
          {field: 'NotificationsStatus.users_id', value: this.auth.user.id},
          {field: 'NotificationsStatus.read_status', value: 0}
        ]
      }

      this.limit = limit
      this.loadNotifications()
  }

  load(){
    this.notificationTypesService.getNotificationsTypes([], '', '+NotificationsTypes.module;+NotificationsTypes.name', 0, 'NotificationsTypes.module', 999999, false, [{field: 'NotificationsTypes.module'}]).then((rs: any) => {
      rs.items.map(item => {
        this.notificationTypesService.getNotificationsTypes([{field: 'NotificationsTypes.module', value: item.data.module}], '', '+NotificationsTypes.name', 0, 'NotificationsTypes.id;NotificationsTypes.name').then((items: any) => {
          items.items.map(checked => {
            this.notificationsTypesUsersService.getNotificationsTypesUsers([{field: 'NotificationsTypesUsers.users_id', value: this.auth.user.id}, {field: 'NotificationsTypesUsers.notifications_types_id', value: checked.data.id}]).then(rs => {
              checked.data.nu = {
                system: null,
                email: null
              }
              if ( rs.items.length > 0 ) {
                for ( let i = 0; rs.items[i]; i++ ) {
                  if ( rs.items[i].data.type == 'system' ) {
                    checked.data.checked_system  = 1;
                  } else {
                    checked.data.checked_email  = 1;
                  }
                  checked.data.nu[rs.items[i].data.type] = rs.items[i].data;
                }
              } else {
                checked.data.checked_system  = 0;
                checked.data.checked_email = 0;
              }
            });
          });
          item.items  = items.items;
        });
      });
      this.modules  = rs.items;
    });
  }

  updateNotificationUser(module_index: number = 0, item_index: number, type: string = 'system', event) {
    if ( this.modules[module_index].items[item_index].data.nu != null && this.modules[module_index].items[item_index].data.nu[type] != null ) {
      //remove item
      this.notificationsTypesUsersService.deleteNotificationTypeUser(this.modules[module_index].items[item_index].data.nu[type].id);
      if ( event.target.checked == true ) {
        let saveItem  = {
          type: type,
          notifications_types_id: this.modules[module_index].items[item_index].data.id,
          users_id: this.auth.user.id
        };
        this.notificationsTypesUsersService.insertNotificationsTypesUsers(saveItem).then(rs => {
          let newItem = {
            id: rs.items.id,
            type: type,
            notifications_types_id: this.modules[module_index].items[item_index].data.id,
            users_id: this.auth.user.id
          }
          this.modules[module_index].items[item_index].data.nu[type]  = newItem;
        });
      }
    } else {
      if ( event.target.checked == true ) {
        let saveItem  = {
          type: type,
          notifications_types_id: this.modules[module_index].items[item_index].data.id,
          users_id: this.auth.user.id
        };
        this.notificationsTypesUsersService.insertNotificationsTypesUsers(saveItem).then(rs => {
          let newItem = {
            id: rs.items.id,
            type: type,
            notifications_types_id: this.modules[module_index].items[item_index].data.id,
            users_id: this.auth.user.id
          }
          this.modules[module_index].items[item_index].data.nu[type]  = newItem;
        });
      }
    }
  }

  async markReadUnread(notification){
    notification.data.read_status = !notification.data.read_status

    await this.notificationService.updateField(notification.data.id, 'read_status', notification.data.read_status)
    this.loadNotifications()
  }

  async markAllAsRead(){
    const filter = [
      {field: 'NotificationsStatus.users_id', value: this.auth.user.id},
      {field: 'NotificationsStatus.read_status', value: 0},
    ]

    this.notificationService.getNotificationStatus(filter, '', '', 0, '', 0).then(ret => {
      if(ret.summary.total > 0){
        ret.items.map(not => {
          this.notificationService.updateField(not.data.id, 'read_status', 1)
        });
        this.loadNotifications()
      }
    });
  }

}
