import { Component, Input, OnInit } from '@angular/core';
import { Globals } from 'src/globals';

@Component({
  selector: "avatar",
  templateUrl: "./avatar.component.html",
  styleUrls: ['./avatar.component.scss'],
  providers: [Globals]
})
export class AvatarComponent implements OnInit {
  @Input() name: string = "";
  @Input() image: string = "";
  @Input() icon: string = "";
  @Input() text: string = "";
  @Input() color: string = '#4767BC';
  @Input() textColor: string = '#45444B';
  @Input() size: string = "normal";
  @Input() deleteEnabled: boolean = false;
  @Input() tooltipEnabled: boolean = false; 

  constructor(public globals: Globals) {}

  ngOnInit() {
    this.name = getFirstLastName(this.name);
    if ( this.color == null || this.color == '')
      this.color = this.globals.avatarColors[ ~~(random(this.name)*this.globals.avatarColors.length) ];
  }
}

function random(userString: string) {
  let seed = hashCode(userString);
  let x = Math.sin(seed++)
  return x - Math.floor(x);
}

function hashCode(s: string) {
  let hash;
  for(let i = 0; (i < s.length); i++) {
    hash = Math.imul(31, hash) + s.charCodeAt(i) | 0;
  }
  return hash;
}

function getFirstLastName(name: string){
  let tempArray = name.split(" ");
  return tempArray[0] + " " + tempArray[tempArray.length - 1];
}
