<div class="crm-card pb-3" style="height: 472px;"#graphFunnel>
	<div class="crm-card-header">
		<h3 class="crm-card-title">Divisão do funil de vendas</h3>
		<div ngbDropdown #download = "ngbDropdown">
		    <button ngbDropdownToggle>
		        <span class="dot"></span>
				<span class="dot"></span>
				<span class="dot"></span>
		    </button>
		    <div ngbDropdownMenu class="dropdown-menu p-3">
		        <button class="mb-2" (click) = "download.close(); downloadPDF()">
		        	<i class="icon pdf-icon"></i>
		        	<span class="formo-p2">Baixar gráfico em .PDF</span>
		        </button>
		        <button>
		        	<i class="icon csv-icon"></i>
		        	<span class="formo-p2">Baixar gráfico em .CSV</span>
		        </button>
		    </div>
		</div>
	</div>
	<div class="crm-card-body" style="height: 371px;">
		<div class="funnel" style="height: 341px;" (clickOutside) = "createGraphOutsideOnInit()"></div>
	</div>
</div>
<div id="download">
  <img #canvas>
  <a #downloadLink></a>
</div>
