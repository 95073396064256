import { TranslateService } from '@ngx-translate/core';
import { Validation } from 'src/app/model/validation.model';
import { Component, OnInit, Output, EventEmitter, Injectable } from '@angular/core';
import { ToasterService, Toast } from 'angular2-toaster';
import { BodyOutputType } from 'angular2-toaster';
import { Subject } from 'rxjs';
 

// @Component({ })
@Injectable()
export class GeneralComponent implements OnInit {

    globalMessage: {
        error: [],
        success: [],
        warning: []
    } = {
            error: [],
            success: [],
            warning: []
        };
    errorMessage: any = [];
    successMessage: any = [];
    warningMessage: any = [];


    itemValue = new Subject();
    validation: Validation = new Validation();


    constructor(
        private translate: TranslateService,
        private toaster: ToasterService 
    ) { }

    ngOnInit() {
    }

    set theItem(value) {
        this.itemValue.next(value); // this will make sure to tell every subscriber about the change.
        localStorage.setItem('updateNotifications', value);
    }

    get theItem() {
        return localStorage.getItem('updateNotifications');
    }

    convertDateString(stringDate, separator: string = '-', reverse: boolean = false) {
        if (stringDate == null || stringDate == '') {
            return stringDate
        } else {
            let separatorTo = (separator == '-') ? '/' : '-';
            if (reverse) {
                const exploded = stringDate.split(separator);
                if (exploded.length == 1)
                    return stringDate;
                else
                    return exploded[2] + separatorTo + exploded[1] + separatorTo + exploded[0];
            } else {
                const exploded = stringDate.split(separator);
                if (exploded.length == 1)
                    return stringDate;
                else
                    return exploded[2] + separatorTo + exploded[1] + separatorTo + exploded[0];
            }
        }
    }

    monthDiff(dateFrom, dateTo) {
        return dateTo.getMonth() - dateFrom.getMonth() +
            (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
    }

    getMessage(type = 'error') {
        
        if (localStorage.getItem('globalMessage')) {
            this.globalMessage = JSON.parse(localStorage.getItem('globalMessage'));
            localStorage.removeItem('globalMessage');
        }
        if (type == 'error') {
            let check = '';
            this.translate.get('GENERAL.CHECK').subscribe(data => {
                check = data;
            });
            let finalMessage = '';
            this.globalMessage.error.map(msg => {
                if ( msg != '' ) {
                    this.translate.get(msg).subscribe(data => {
                        if (data instanceof Object) {
                            let values = Object.keys(data).map(key => data[key]);
                            values.map(item => {
                                finalMessage += item + '<br />';
                            });
                        } else {
                            finalMessage = data;
                        }
                    });
                }
            });
            let config = {
                animation: 'fade',
                type: 'error',
                title: check,
                body: finalMessage,
                bodyOutputType: BodyOutputType.TrustedHtml
            }
            this.toaster.pop(config);
        } else if (type == 'warning') {
            let finalMessage = '';
            this.globalMessage.warning.map(msg => {

                this.translate.get(msg).subscribe(data => {
                    if (data instanceof Object) {
                        let values = Object.keys(data).map(key => data[key]);
                        values.map(item => {
                            finalMessage += item + '<br />';
                        });
                    } else {
                        finalMessage = data;
                    }
                });
            });
            let config = {
                animation: 'fade',
                type: 'warning',
                title: '',
                body: finalMessage,
                bodyOutputType: BodyOutputType.TrustedHtml,
                timeout: 15000
            }
            this.toaster.pop(config);
        } else {
            let finalMessage = '';
            this.globalMessage.success.map(msg => {
                this.translate.get(msg).subscribe(data => {
                    if (data instanceof Object) {
                        let values = Object.keys(data).map(key => data[key]);
                        values.map(item => {
                            finalMessage += item + '\r\n';
                        });
                    } else {
                        finalMessage = data;
                    }
                });
            });
            let config = {
                animation: 'fade',
                type: 'success',
                title: '',
                body: finalMessage,
                bodyOutputType: BodyOutputType.TrustedHtml
            }
            this.toaster.pop(config);
        }
    }

    setMessage(message: any = '', type = 'error') {
        if (localStorage.getItem('globalMessage'))
            this.globalMessage = JSON.parse(localStorage.getItem('globalMessage'));

        this.errorMessage = [];
        this.successMessage = [];
        this.warningMessage = []
        if (type == 'error') {
            this.errorMessage.push(message);
        } else if(type == 'warning') {
            this.warningMessage.push(message);
        }else{
            this.successMessage.push(message);
        }
        this.globalMessage.success = this.successMessage;
        this.globalMessage.error = this.errorMessage;
        this.globalMessage.warning= this.warningMessage
        localStorage.setItem('globalMessage', JSON.stringify(this.globalMessage));
        return this.getMessage(type);
    }

    apiErrors(data) {
        this.setMessage('ERRORS.CODE.' + data);
    }

    ptDate(date) {
        var RegExPattern = /^((((0?[1-9]|[12]\d|3[01])[\.\-\/](0?[13578]|1[02])[\.\-\/]((1[6-9]|[2-9]\d)?\d{2}))|((0?[1-9]|[12]\d|30)[\.\-\/](0?[13456789]|1[012])[\.\-\/]((1[6-9]|[2-9]\d)?\d{2}))|((0?[1-9]|1\d|2[0-8])[\.\-\/]0?2[\.\-\/]((1[6-9]|[2-9]\d)?\d{2}))|(29[\.\-\/]0?2[\.\-\/]((1[6-9]|[2-9]\d)?(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00)|00)))|(((0[1-9]|[12]\d|3[01])(0[13578]|1[02])((1[6-9]|[2-9]\d)?\d{2}))|((0[1-9]|[12]\d|30)(0[13456789]|1[012])((1[6-9]|[2-9]\d)?\d{2}))|((0[1-9]|1\d|2[0-8])02((1[6-9]|[2-9]\d)?\d{2}))|(2902((1[6-9]|[2-9]\d)?(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00)|00))))$/;

        if (!((date.match(RegExPattern)) && (date != '')))
            return false
        else
            return true
            

    }

    show(value, type) {
        if ( type == 'conclusion_date' ) {
            let dateArr = value.split("-");
            let sem = '02';
            if ( dateArr[1] < 7 )
                sem = '01';
            return dateArr[0] + '.' + sem;
        } else if ( type = 'graduation_date' ) {
            let dateArr = value.split("-");
            return dateArr[1] + '/' + dateArr[0];
        }
    }


    checkYearFormat(item:any, field: string) {
        if(item[field]){
            const explodeDate = item[field].split('/');
            if(explodeDate.length == 3){
                if(explodeDate[2].trim().length == 2){
                    explodeDate[2] = '20' + explodeDate[2];
                    item[field] = explodeDate.join('/');
                }
            }
        }

        return item
    }
}