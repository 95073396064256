import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {

  @Input()
  filters: any[] = []
  @Input()
  sort: any[] = []
  @Input()
  by_page: number = 20
  @Input()
  sort_options: any[] = []
  @Output()
  newPage = new EventEmitter();
  @Input()
  filterIsOpen: boolean = false

  
  cardFilterOpen: boolean = false
  selectStatus: boolean = false
  filterSelectOpen: boolean = false
  openPesquise: boolean = false
  selectedFilter: any;
  filterSelected: any = [];
  selectFilterDate: boolean = false;

  search: string = '';

  orderIsOpen: boolean = false
  cardOpen: boolean = false
  selectOrder: boolean = false
  orderSelected: any = [];
  itemSelected: any = '';

  pageConfigIsOpen: boolean = false
  pagesOptions: any[] = [25, 50, 100, 200, 300]
  pageDefaultOption: number = 25;

  public maskDate = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  public maskNumber = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

  constructor(
    private _route: Router,
    private eRef: ElementRef
  ) {
  }

  ngOnInit(): void {
    this.load();
  }

  load(){
    if (localStorage.getItem('byPage.' + this._route.url))
      this.pageDefaultOption = parseInt(localStorage.getItem('byPage.' + this._route.url));
    if (localStorage.getItem('filter.' + this._route.url))
      this.filterSelected = JSON.parse(localStorage.getItem('filter.' + this._route.url));
    if (localStorage.getItem('order.' + this._route.url))
      this.orderSelected = JSON.parse(localStorage.getItem('order.' + this._route.url))[0];

    this.filters.map((item, index) => {
      this.filterSelected.map(selected => {
        if (item.id == selected.id) {
          if(selected.type == 'select'){
            const selectedOptions = selected.options.filter(item => item.data.selected == true)
            selected.selectedValue = selectedOptions.map(item => item.data.name).join(', ')
          }else if(selected.type == 'date'){
            let val = ''

            if(selected.date_from && selected.date_to){
              val = 'de ' + selected.date_from + ' - até ' + selected.date_to
            }
            if(selected.date_from && !selected.date_to){
              val = 'de ' + selected.date_from
            }
            if(!selected.date_from && selected.date_to){
              val = 'até ' + selected.date_to
            }

            selected.selectedValue = val
          }else{
            selected.selectedValue = selected.value;
          }

          this.filters[index] = selected;
        }
      })
    });
    this.filterSelected.map(selected => {
      if (selected.type == 'search') {
        this.search = selected.value;
      }
    })
  }

  selectFilter(opt) {
    this.cardFilterOpen = true;
    this.selectedFilter = opt;
  }

  setFilter() {
    this.selectedFilter.isSelected = true;
    let found = false;
    let i = 0;
    this.filterSelected.map((item, index) => {
      if (item.id == this.selectedFilter.id) {
        found = true;
        i = index;
      }
    });
    if (!found)
      this.filterSelected.push(this.selectedFilter);
    else
      this.filterSelected[i] = this.selectedFilter;

    this.cardFilterOpen = false;
    this.filterIsOpen = false;

    localStorage.setItem('filter.' + this._route.url, JSON.stringify(this.filterSelected));
    this.load()
    this.newPage.emit();
  }

  setSearch() {
    if (this.search != '' && this.search != null) {
      if (this.filterSelected.length > 0) {
        this.filterSelected.map(item => {
          item.value = this.search;
        })
      } else
        this.filterSelected.push({ id: "Generic", type: "search", value: this.search, isSelected: true });

      this.filterIsOpen = false;
      localStorage.setItem('filter.' + this._route.url, JSON.stringify(this.filterSelected));
      this.newPage.emit();
    }
  }

  removeFilter(opt) {
    opt.isSelected = false;
    let newFilter = [];
    this.filterSelected.map((item) => {
      if (item.id != opt.id) {
        newFilter.push(item);
      }
    });
    this.filterSelected = newFilter;
    localStorage.setItem('filter.' + this._route.url, JSON.stringify(this.filterSelected));
    this.newPage.emit();
  }

  openCard(item) {
    this.itemSelected = item;
    this.cardOpen = true;
  }

  setOrder(item, direction) {
    let order = [];
    order.push({ field: item, sort: direction });
    this.orderSelected = order[0];
    localStorage.setItem('order.' + this._route.url, JSON.stringify(order));
    this.cardOpen = false;
    this.selectOrder = false;
    this.orderIsOpen = false;
    this.newPage.emit();
  }

  changeByPage(qty = 25) {
    localStorage.setItem('byPage.' + this._route.url, qty.toString());
    this.pageDefaultOption = qty;
    this.newPage.emit(qty);
    this.pageConfigIsOpen = false;
  }

  setPeriod(opt, option) {
    var date1 = new Date();
    var date2 = new Date();
    if (option == 'YESTERDAY') {
      date1.setDate(date1.getDate() - 1);
      date2.setDate(date2.getDate() - 1);
    }
    if (option == 'LAST-WEEK') {
      let dayOfWeek = date1.getDay();
      date1.setDate(date1.getDate() - dayOfWeek - 7);
      date2.setDate(date2.getDate() - dayOfWeek - 1);
    }
    if (option == 'THIS-WEEK') {
      let dayOfWeek = date1.getDay();
      date1.setDate(date1.getDate() - dayOfWeek);
      date2.setDate(date2.getDate());
    }
    if (option == 'LAST-MONTH') {
      date1.setMonth(date1.getMonth() - 1);
      date1.setDate(1);
      date2.setDate(1);
      date2.setDate(date2.getDate() - 1);
    }
    if (option == 'THIS-MONTH') {
      date1.setDate(1);
    }
    if (option == 'LAST-YEAR') {
      date1.setFullYear(date1.getFullYear() - 1);
      date1.setDate(1);
      date1.setUTCMonth(0, 1);
      date2.setDate(31);
      date2.setUTCMonth(11, 31);
      date2.setFullYear(date1.getFullYear());
    }
    if (option == 'THIS-YEAR') {
      date1.setDate(1);
      date1.setUTCMonth(0, 1);
    }
    this.filterSelected.map((item) => {
      if (item.id == opt.id) {
        item.period = option;
        item.date_from = date1.getDate().toString().padStart(2, "0") + '/' + (date1.getMonth() + 1).toString().padStart(2, "0") + '/' + date1.getFullYear().toString().padStart(4, "0");
        item.date_to = date2.getDate().toString().padStart(2, "0") + '/' + (date2.getMonth() + 1).toString().padStart(2, "0") + '/' + date2.getFullYear().toString().padStart(4, "0");
      }
    });
    opt.period = option;
    opt.date_from = date1.getDate().toString().padStart(2, "0") + '/' + (date1.getMonth() + 1).toString().padStart(2, "0") + '/' + date1.getFullYear().toString().padStart(4, "0");
    opt.date_to = date2.getDate().toString().padStart(2, "0") + '/' + (date2.getMonth() + 1).toString().padStart(2, "0") + '/' + date2.getFullYear().toString().padStart(4, "0");
    this.selectFilterDate = false;
    localStorage.setItem('filter.' + this._route.url, JSON.stringify(this.filterSelected));
  }
}
