import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { RestService } from './rest.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsStatusService extends RestService {
  private _url = 'NotificationsStatus';
  private _markAllAsRead = 'NotificationsStatus/markAllAsRead';
  
  constructor(protected _http: HttpClient) {
    super(_http);
  }

  insertNotificationStatus(Notification: any): any {
    let data = JSON.stringify(Notification); 
    return this.postApi(this._url, data);
  }

  updateNotificationStatus(Notification: any): any {
    let data = JSON.stringify(Notification);
    return this.putApi(this._url + '/' + Notification.id, data);
  }

  markAllAsRead(info :any):any{
    let data = JSON.stringify(info);
    return this.postApi(this._markAllAsRead, data);
  }

  deleteNotificationStatus(id: number): any {
    return this.deleteApi(this._url + '/' + id);
  }

  updateField(id: number, field: any, value: any): any {
    let data = JSON.stringify({ id: id, field: field, value: value });
    return this.patchApi(this._url + '/' + id, data);
  }

  getNotificationStatus(filter_data: any, contain_string: string = '', order_string: string = '-NotificationsStatus.id', page: number = 0, fields: string = '', limit: number = 20): any {
    let qs: string = '';
    let paging: string = '';
    
    if (filter_data.id && filter_data.id != null) {
      qs += 'NotificationsStatus.id|' + filter_data.id + ';';
    }

    if (filter_data.read_status != null) {
      qs += 'NotificationsStatus.read_status|' + filter_data.read_status + ';';      
    }

    if (filter_data.users_id && filter_data.users_id != null) {
      qs += 'NotificationsStatus.users_id|' + filter_data.users_id + ';';
    }

    if (filter_data.notId && filter_data.notId != null) {
      qs += 'NotificationsStatus.id|!=|' + filter_data.notId + ';';
    }

    if(Array.isArray(filter_data)){
      filter_data.map(item => {
          if(item.operator == null || item.operator == ''){
          qs += item.field + '|' + item.value + ';'
          }else{
          qs += item.field + '|' + item.operator + '|' + item.value + ';'
          }
      })
  }

    let offset = (page * limit);
    paging = offset + ";" + limit;
    return this.getApi(this._url, { params: { query: qs, paging: paging, order: order_string, contains: contain_string, fields: fields } });
  }
  
  getNotification(id, contain_string: string = ''){
    return this.getApi(this._url + '/' + id, { params: { contains: contain_string }});
  }

}