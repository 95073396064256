import { Component, Input, OnInit } from '@angular/core';
import * as shape from 'd3-shape';
import { Globals } from 'src/globals';


@Component({
  selector: 'billing-summary-by-product',
  templateUrl: './billing-summary-by-product.component.html', 
  providers: [Globals]
})
export class BillingSummaryByProductComponent implements OnInit {
  colors: string[] = [];

  @Input() summary: any[];

  // options
  showLabels: boolean = true;
  animations: boolean = true;
  timeline: boolean = true;

  curve: any = shape.curveBasis; 

  yAxisTickFormatting = (value: number) =>
    `R$${(value / 1000).toString()}k`;

  yScaleMin = 0;

  yAxisTicks: any[] = [220000, 200000, 170000, 120000];

  colorScheme = {
    domain: this.colors
  };

  constructor(public globals: Globals) { }

  ngOnInit() {
    for (let value of this.summary) {
      for (let item of this.globals.productColor) {
        if (item.label === value.name) {
          this.colors.push(item.hex);
        }
      }
    }
  }
}
 