import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { RestService } from './rest.service';

@Injectable()
export class UsersGuestsService extends RestService {

    private _url = 'UsersGuests';
    
	constructor(protected _http: HttpClient) {
		super(_http);
    } 
    
    
    getUsersGuests(filter_data: any, contain_string: string = '', order_string: string = '-UsersGuests.id', page: number = 0, fields: string = '', limit: number = 20, groupData: any = []): any {
      let qs: string = '';
      let paging: string = '';
      let group: string = '';
  
      if(Array.isArray(filter_data)){
        filter_data.map(item => {
          if(item.operator == null || item.operator == ''){
            qs += item.field + '|' + item.value + ';'
          }else{
            qs += item.field + '|' + item.operator + '|' + item.value + ';'
          }
        })
      }
  
      if(groupData && groupData.length > 0){
        groupData.map(item => {        
             group += item.field + ';'        
        })
      }
      let offset = (page * limit);
      paging = offset + ";" + limit;
      return this.getApi(this._url, { params: { query: qs, paging: paging, order: order_string, contains: contain_string, fields: fields, group: group } });
    }

    insert(obj: any): any {
      let data = JSON.stringify(obj);
    
      return this.postApi(this._url, data);
    }

}