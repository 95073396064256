import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ClassService  extends RestService {

  private _url = 'CollegesClasses';
    
  constructor(protected _http: HttpClient) {
    super(_http);
  }
  
  insertClass(class_data: any): any {
    let data = JSON.stringify(class_data);
    return this.postApi(this._url, data);
  } 

  deleteClass(id: number): any {
    return this.deleteApi(this._url + '/' + id);
  }

  updateClass(class_data: any): any {
    let data = JSON.stringify(class_data);
    return this.putApi(this._url + '/' + class_data.id, data);
  }

  updateField(id: number, field: any, value: any): any {
    let data = JSON.stringify({ id: id, field: field, value: value });
    return this.patchApi(this._url + '/' + id, data);
  }
  getClasses(filter_data: any, contain_string: string = '', order_string: string = '-CollegesClasses.id', page: number = 0, fields: string = '', limit: number = 20, groupData: any = []): any {
    let qs: string = '';
    let paging: string = '';
    let group: string = '';

    if(Array.isArray(filter_data)){
      filter_data.map(item => {
        if(item.operator == null || item.operator == ''){
          qs += item.field + '|' + item.value + ';'
        }else{
          qs += item.field + '|' + item.operator + '|' + item.value + ';'
        }
      })
    }

    if(groupData && groupData.length > 0){
      groupData.map(item => {        
           group += item.field + ';'        
      })
    }
    let offset = (page * limit);
    paging = offset + ";" + limit;
    return this.getApi(this._url, { params: { query: qs, paging: paging, order: order_string, contains: contain_string, fields: fields, group: group } });
  }

  getClass(class_data_id, contain_string: string = '', fields: string = ''){
    return this.getApi(this._url + '/' + class_data_id, { params: {contains: contain_string, fields: fields } });
  }
  
  getCollegeClassBalance(college_class_id:number = null, college_class_name:string = null){
    const obj = {}
    if(college_class_id != null){
      obj['college_class_id'] = college_class_id
    }
    if(college_class_name != null){
      obj['college_class_name'] = college_class_name
    }

    const params = new URLSearchParams(obj);
    return this.getApi(`${this._url}/getClassBalance/` + '?' +  params.toString());
  }


}
