import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringDate'
})
export class StringDatePipe implements PipeTransform {

  transform(value: string) {
  
    if(!value){
      return value;
    }

    let dt = value

    const checkT = value.includes('T')
    if(checkT){
      dt = dt.split('T')[0]
    }
    
    const explited = dt.split('-').reverse()

    return explited.join('/')
  }
}