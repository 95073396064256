import { Component, OnInit } from '@angular/core';
import { Validation } from '../model/validation.model';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';
import { GeneralComponent } from '../_utils/general/general.component';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html', 
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

    public maskPhone = [ 
        '(', /\d/,/\d/, ')', ' ', /\d/, ' ', /\d/,/\d/,/\d/,/\d/, '-', /\d/,/\d/,/\d/,/\d/
    ];

    numberBg: string = '1';
    forgot : {
        id: number,
        email: string,
        cellphone: string,
        code: string, 
        password: string
    } = {
        id: 0,
        email : '',
        cellphone : '',
        code: '',
        password: ''
    }
    year: number;
    validation: Validation = new Validation();
    loading: boolean = false;
    code: boolean = false;
    new_password: boolean = false;

    constructor(
        private general: GeneralComponent,
        private userService: UserService,
        private router: Router,
    ) {
        let date    = new Date();
        this.year = date.getFullYear();
        this.numberBg = localStorage.getItem('bg-login');
    }

    ngOnInit() {    
    }

    recoveryCode(){
        this.loading = true;
        this.validate('primary'); 
        if ( this.validation.valid ) {      
            this.userService.getRecoveryCode(this.forgot.email, this.forgot.cellphone).then((ret:any) => {
                if(ret.summary.process == true){
                    this.code = true;
                    this.general.setMessage('GENERAL.FORGOT-CODE-SEND', 'success');
                    this.loading = false;
                }else{
                    this.loading = false;
                    //email/cellphone not found
                    this.general.setMessage('GENERAL.FORGOT-NOT-FOUND');
                }
            });
        } else {
            this.loading = false;
            this.general.setMessage('GENERAL.FORGOT-EMPTY-FIELDS');
        }
    }

    validate(type: string  = ''){    
        this.validation.fields      = [];

        if(type == 'code'){
            if(this.forgot.code == "") {
                this.validation.fields.push('forgot.code');
            } 
        }else if(type == 'primary'){
            if(this.forgot.email == "" && this.forgot.cellphone == "") {
                this.validation.fields.push('forgot.email');        
                this.validation.fields.push('forgot.cellphone');
            } 
        }else{
            if(this.forgot.password == "") {
                this.validation.fields.push('forgot.new_password');
            } 
        }

        if ( this.validation.fields.length > 0 )      
            this.validation.valid   = false;
        else
            this.validation.valid   = true;
        
    }

    checkCode() {
        this.loading  = true;
        this.validate('code')
        if ( this.validation.valid ) {
            this.userService.validateCode(this.forgot.email, this.forgot.cellphone, this.forgot.code).then(result => {
                if( (result.summary.process) && (result.summary.checked) ){
                    //new password field
                    this.code = false;
                    this.new_password = true;
                    this.forgot.id	= result.summary.id;
                    this.loading = false;
                } else {
                    this.general.setMessage('GENERAL.FORGOT-CODE-INVALID');
                    this.loading = false;
                }
            }, error => {
                this.general.setMessage(error);
                this.loading = false;
            });
        } else {
            this.loading  = false;
        }
    }

    changePassword() { 
        this.loading  = true;
        this.validate()
        if ( this.validation.valid ) {
            this.userService.changePassword(this.forgot.id, this.forgot.password).then(result => {
                if( (result.summary.process) ){
                    this.loading = false;
                    this.router.navigate(['/login']);
                } else {
                    this.general.setMessage(result.summary.errorMessage);
                    this.loading = false;
                }
            }, error => {
                this.general.setMessage(error);
                this.loading = false;
            });
        } else {
            this.loading  = false;
        }
    }
  
    back() {
        this.router.navigate(['/login']);
    }
  
}
