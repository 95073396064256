import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { RestService } from './rest.service';

@Injectable()
export class ContractService extends RestService {


    private _url = 'Contracts';
    
	constructor(protected _http: HttpClient) {
		super(_http);
    } 
    
    insertContract(data: any) : any {
        return this.postApi(this._url, data);
    }
    
    updateContract(contract: any) : any {
        let data    = JSON.stringify(contract);
        return this.putApi(this._url + '/' + contract.id, data);
    }
    
    getContracts(filter_data: any, contains: string = '', order_string: string = '+Contracts.id', page: number = 0, fields: string = '', limit: number = 20): any {
        let qs = '';
        let paging  = '';

        if ( filter_data.id && filter_data.id != null ) {
            qs   += 'Contracts.id|' + filter_data.id + ';';
        }
        if ( filter_data.briefings_id && filter_data.briefings_id != '' ) {
            qs   += 'Contracts.briefings_id|' + filter_data.briefings_id + ';';
        }
        if ( filter_data.name && filter_data.name != '' ) {
            qs   += 'CollegesClasses.name|LIKE|' + filter_data.name + ';';
        }
        if ( filter_data.status && filter_data.status != '' ) {
            qs   += 'Contracts.status|' + filter_data.status + ';';
        }


        if(Array.isArray(filter_data)){
            filter_data.map(item => {
                if(item.operator == null || item.operator == ''){
                qs += item.field + '|' + item.value + ';'
                }else{
                qs += item.field + '|' + item.operator + '|' + item.value + ';'
                }
            })
        }


        let offset  = (page * limit);
        paging      = offset + ";" + limit;
        return this.getApi(this._url, {params: {query: qs, paging: paging, order: order_string, contains: contains, fields: fields}});
    }
    
    getContract(id: number, contains: string = '', group: string = ''): any {
        return this.getApi(this._url + '/' + id, {params: {contains: contains, group: group}});
    }

    getCollegeClassByContract(id: number): any {
        return this.getApi(this._url + '/getCollegeClassByContract', { params: { contract_id: id } } );
    }
}