import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { RestService } from './rest.service';
import axios from 'axios';

@Injectable()
export class FileService extends RestService {
    private _url = 'Files';

    constructor(protected _http: HttpClient) {
		super(_http);
	}

    async insertFile(reference: string, reference_id: number, file: File, unique: string = '0', process: string = '0') : Promise<any> {
        const signedData = {
            name: file.name,
            contentType: file.type ? file.type : 'application/' + file.name.split('.').pop(),
            entity: reference,
            entity_id: reference_id,
            size: file.size,
        }
        const getPreSignedUrl: any = await this.postApi(this._url + '/signed-url', signedData, undefined, true);

        const { items } = getPreSignedUrl;
        const identifier = items.identifier;

        await axios.put(items.signedUrl, file,
            {
            headers: {
                'Content-Type': file.type,
            }
        })

        const sync: any = await this.patchApi(this._url + '/sync', {identifier}, undefined, true);

        return {
            status: sync.summary.process,
            identifier
        }
    }

    getFiles(filter_data: any, order_string: string = '-Files.id', page: number = 0, fields: string = '', limit: number = 20): any {
        let qs = '';
        let paging  = '';

        if ( filter_data.id && filter_data.id != null ) {
            qs   += 'Files.id|' + filter_data.id + ';';
        }
        if ( filter_data.src && filter_data.src != '' ) {
            qs   += 'Files.src|like|' + filter_data.src + ';';
        }
        if ( filter_data.status && filter_data.status != '' ) {
            qs   += 'Files.status|' + filter_data.status + ';';
        }
        if ( filter_data.entity && filter_data.entity != '' ) {
            qs   += 'Files.entity|' + filter_data.entity + ';';
        }
        if ( filter_data.entity_id && filter_data.entity_id != '' ) {
            qs   += 'Files.entity_id|' + filter_data.entity_id + ';';
        }
        
        let offset  = (page * limit);
        paging      = offset + ";" + limit;
        return this.getApi(this._url, {params: {query: qs, paging: paging, order: order_string, fields: fields}});
    }

    deleteFile(identifier: string) : any {
        return this.deleteApi(this._url + '/' + identifier, undefined, true);
    }

    async getFileUrl(identifier: string) : Promise<string> {
        const { items } = await this.getApi(this._url + '/' + identifier, undefined, false, true)
        return items.signedUrl
    }

  async exportAttachments(ids: number[], identifier): Promise<void> {
    const response: any = await this.postApi(
      'Files/downloadZip',
      { ids: ids, identifier: identifier },
      { responseType: 'blob' },
      true
    )

    const blob = new Blob([response], { type: 'application/zip' });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }
}
