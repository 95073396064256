import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-preview-flow',
  templateUrl: './confirm-preview-flow.component.html',
  styleUrls: ['./confirm-preview-flow.component.scss']
})
export class ConfirmPreviewFlowComponent {

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  @Input()
  status: string
  @Output()
  modalCallback: EventEmitter<boolean> = new EventEmitter();

  exec(){
    this.activeModal.close()
    this.modalCallback.emit(true)
  }
}
