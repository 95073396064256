import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

import { PermissionService } from './permission.service';
import { RestService } from './rest.service';
import { ImageService } from './image.service';
import { UserService } from './user.service';
import { Auth } from '../model/auth.model';
import { User } from '../model/user.model';


@Injectable()
export class AuthMainService extends RestService {

	private loggedIn = new BehaviorSubject<boolean>(false); // {1}
	auth: Auth;
	authClass : {
		class: any,
		isLogged: boolean
	} = {
		class: [],
		isLogged: false
	};
	private _loginUrl = 'Users/login';
	private _loginPUrl = 'Users/Plogin';
	private _preLoginUrl = 'Users/preLogin';
	private _loginProcessUrl = 'Classes/login';

	get isLoggedIn() {
		return this.loggedIn.asObservable(); // {2}
	}

	constructor(
		protected _http: HttpClient,
		private permissionService: PermissionService,
		private imageService: ImageService,
		private userService: UserService
		) {
		super(_http);
		if(this.isLogged()) {
		  this.auth = this.getUser();
		  this.loggedIn.next(true);
		}
		localStorage.setItem('api_url', this.serviceURL)
	}

	async setClass(classe){
		let auth 	= new Auth();
		let auth1	= <Auth>JSON.parse(localStorage.getItem('auth'));

		auth.user = classe;
		auth.isLogged = true;
		Object.assign(auth, auth1);

		localStorage.setItem('auth', JSON.stringify(auth));
		this.loggedIn.next(true);
		return true;
	}

	async setUser(user: User, permissions: any) {
		localStorage.removeItem('avatar');
		let auth 	= new Auth();
		let auth1	= <Auth>JSON.parse(localStorage.getItem('auth'));
		if ( user.tokenUser && user.tokenUser != '' ) {
			localStorage.setItem('tokenUser', user.tokenUser);
		}
		auth.user = user;
		auth.isLogged = true;
		Object.assign(auth, auth1);
		if ( permissions.length > 0 ) {
			permissions.push('');
			permissions.push('/');
			permissions.push('login');
			// localStorage.setItem('sessionHash', user.sessionHash);
			if ( auth.user.root == 'N' ) {
				//remove permissions with root access
				return await this.permissionService.getPermissions({root: 'IN|Y'})
					.then(response => {
						if ( response.summary.process ) {
							for ( let i = 0; response.items[i]; i++ ) {
								let indexRemove	= permissions.indexOf(response.items[i].data.action);
								delete permissions[indexRemove];
							}
							auth.permissions 	= permissions;
							auth.permissions    = auth.permissions.sort(function(a, b){
								if(a < b) { return -1; }
								if(a > b) { return 1; }
								return 0;
							})
							localStorage.setItem('auth', JSON.stringify(auth));
							this.getAvatar(user.id);
							this.loggedIn.next(true);
							return true;
						} else {
							//erro nas permissões obriga novo login
							this.logout();
							return false;
						}
					});
			} else {
				auth.permissions 	= permissions;
				auth.permissions    = auth.permissions.sort(function(a, b){
					if(a < b) { return -1; }
					if(a > b) { return 1; }
					return 0;
				})
				localStorage.setItem('auth', JSON.stringify(auth));
				this.getAvatar(user.id);
				this.loggedIn.next(true);
				return true;
			}
		} else {
			this.loggedIn.next(false);
			return false;
		}
	}

	public getAvatar(user_id, social = false, photo_url = ''): any {    
		return this.imageService.getImages({entity: 'user', entity_id: user_id})
			.then(response => {
				if ( response.summary.process == false || response.summary.message == 'error' ) {
					localStorage.removeItem('avatar');
					return true;
				} else {
					if ( response.items.length > 0 && response.items[response.items.length - 1].data.src != '' ) {
						return this.imageService.resizeImage(response.items[response.items.length - 1].data.src, 150, 150, 'user', true).then(result => {
							if ( result.summary.process == false || result.summary.message == 'error' ) {
								localStorage.removeItem('avatar');
								return true;
							} else {
								if(social){
									localStorage.setItem('avatar', photo_url);
								}else{
									localStorage.setItem('avatar', this.serviceURL + response.items[response.items.length - 1].data.src);
									// localStorage.setItem('avatar', result.summary.full_url);
								}
								return true;
							}
						});
					}
				}
			});
	}

	public getUser() : Auth{
		let auth : string|Auth = localStorage.getItem('auth');
		if(auth){
			auth = <Auth>JSON.parse(auth);
		}else{
			auth = new Auth();
		}
		return auth;
	}

	public isLogged() : boolean{
		return this.getUser().isLogged;
	}

	public unsetUser(){
		let auth1	= <Auth>JSON.parse(localStorage.getItem('auth'));
		if ( auth1 && auth1.user && auth1.user.activeSession != 0 )
			this.userService.killSession(auth1.user.activeSession);

		localStorage.clear();
		this.loggedIn.next(false);
	}

	public login(user: string, password: string) : any {
		let data		= JSON.stringify({login: user, password: password});
		let response	= this.postApi(this._loginUrl, data);
		return response;
	}

	public personateLogin(userId: string, email: string) : any {
		let data		= JSON.stringify({login: email, id: userId});
		let response	= this.postApi(this._loginPUrl, data);
		return response;
	}

	public preLogin(user: string, password: string) : any {
		let data		= JSON.stringify({login: user, password: password});
		let response	= this.postApi(this._preLoginUrl, data);
		return response;
	}

	public loginProcess(user: string, password: string) : any {
		let data		= JSON.stringify({login: user, password: password});
		let response	= this.postApi(this._loginProcessUrl, data);
		return response;
	}

	public logout(){
		this.unsetUser();
  }
  
  public getSocialLogin(hashedEmail) {    
		let response	= this.getApiAuth(hashedEmail);
		return response;
  }

}
